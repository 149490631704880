import React from 'react';
import { 
	Chart,
	CommonSeriesSettings,
	Label,
	Format,
	Series,
	ArgumentAxis,
   // SeriesTemplate,
	Legend
} from 'devextreme-react/chart';
import PropTypes from 'prop-types';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { Card, Box } from '@mui/material';



 const TimeChart = ({ timeChartResult, isStart, isEnd }) => {
    
    // console.log(timeChartResult)

	const formatDate = date => {
		return date.toLocaleDateString('en-US', {
			month: 'numeric',
			day: 'numeric',
			year: 'numeric'
		});
	};

	const generateDateRange = (startDate, endDate) => {
		const dates = [];
		let currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			dates.push(formatDate(currentDate));
			currentDate.setDate(currentDate.getDate() + 1);
		}
		return dates;
	};

	const dateRange = generateDateRange(isStart, isEnd);
	

    return (
        <Card sx={{bgcolor: '#ECF1F7'}}>
			<Box sx={{m:1,p:1}}>
            <Chart
			id="timecard"
			title="TimeCard Hours"
            dataSource={timeChartResult}
		>
			<CommonSeriesSettings
				argumentField={dateRange}
				type="bar"
			>
				<Label visible={true}>
					<Format type="fixedPoint" precision={2} />
				</Label>
			</CommonSeriesSettings>
			<Series
				argumentField="date"
				valueField="entryHours"
				name="entries"
			/>
			<Series
                argumentField="date"
				valueField="visitHours"
				name="visits"
			/>
			<ArgumentAxis categories={dateRange} title='Date' />
			<Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>

		</Chart>
		</Box>
        </Card>
    )
}

TimeChart.propTypes = {
    timeChartResult: PropTypes.any,
	isStart: PropTypes.any,
	isEnd: PropTypes.any
}

export default TimeChart;