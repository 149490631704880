import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import ChartNav from './ChartNav';
import { useDispatch } from 'react-redux';
import { setCurrentClient } from '../../../redux/slices/clientSlice';
import { ClientProvider } from '../ClientContext';
import { Grid, Typography, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import EditClient from '../EditClient';
import { useGetClientQuery, useDeleteClientMutation } from '../../../redux/apiSlice'
import ManageStatus from '../ManageStatus';
import './ClientChart.css'
import DeleteClient from '../DeleteClient';
import { useAuth } from '../../../utilities/useAuth';

const ClientChart = () => {
  const {id, admin, supAdmin} = useAuth();
  
    const { clientId } = useParams()
    const dispatch = useDispatch();

    const [statusData, setStatusData] = useState()

    const {
        data: client,
        isLoading,
        isSuccess,
        
      } = useGetClientQuery(clientId)
    
    if (isSuccess) {
        dispatch(setCurrentClient(client))
    }

    useEffect(() => {
      if (client) {
        let info = {
          fullName: client.fullName,
          enrollment: {
            enrolled: client.enrollment.enrolled,
            enrollDates: client.enrollment.enrollDates
          }
        }
        setStatusData(info)
      }
    }, [client])    

    const [deleteClient] = useDeleteClientMutation();

    const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    let content

    if (isLoading) {
        content = <CircularProgress />
    } else if (isSuccess) {
        content = (
        <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">{client.fullName}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ManageStatus info={statusData} clientId={clientId} />
        </Grid>
        <Grid item xs={12}>
          <ClientProvider value={{ client }}>
            <ChartNav isMdScreen={isMdScreen} />
          </ClientProvider>
        </Grid>
      </Grid>
        );
    }  else {
      content = <Typography variant="h6">Client data not available.</Typography>;
    }
     
    
    return (
      <Grid container spacing={2}>
      <Grid item xs={12}>
        {content}
      </Grid>
      <Grid item xs={12} sm={6}>
        <EditClient client={client} />
      </Grid>
      {(admin || supAdmin) && client && (
        <Grid item xs={12} sm={6}>
          <DeleteClient name={client?.fullName} clientId={clientId} hook={deleteClient} isUser={id} />
        </Grid>
      )}
    </Grid>
    )
}



export default ClientChart;