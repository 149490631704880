import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import HistoryLogs from '../logviews/HistoryLogs';
import HealthTabs from '../logviews/HealthTabs';
import LegalLogs from '../logviews/LegalLogs';
import { useGetAllClientLogsQuery } from '../../../redux/apiSlice';
import { useParams } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'} variant={'body2'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function LogTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [address, setAddress] = useState([])
  const [phone, setPhone] = useState([])
  const [legal, setLegal] = useState([])
  const [health, setHealth] = useState([])

const {clientId} = useParams();

    const {data, isSuccess} = useGetAllClientLogsQuery(clientId)

useEffect(() => {
    if(isSuccess) {
      console.log("in effect: ", data)
        setAddress(data.address)
        setPhone(data.phone)
        setLegal(data.legal)
        setHealth(data.health)
    }
}, [isSuccess, data])

const hasHealth = health ? health : []


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="History" {...a11yProps(0)} />
          <Tab label="Health" {...a11yProps(1)} />
          <Tab label="Legal" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Paper sx={{ height: '100%', width: '100%'}}>
          <HistoryLogs addressLogs={address} phoneLogs={phone}/>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper sx={{ height: '100%', width: '100%'}}>
          <HealthTabs list={hasHealth}/>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Paper sx={{ height: '100%', width: '100%'}}>
          <LegalLogs list={legal}/>
        </Paper>
      </TabPanel>
    </Box>
  );
}
