import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";


const SelectAsyncUser = ({handleChange}) => {
  const [selectedOption, setSelectedOption] = useState()

  const token = useSelector(state => state.auth.token)

  const fetchData = (inputText, callback) => {
    const len = inputText.length

    if (len > 2) {
    
    
    setTimeout(() => {
      

      fetch(
        `${process.env.REACT_APP_BASEURL}/userapi/users?firstName=${inputText}&lastName=${inputText}`,
        {
          method: 'GET',
          headers: {
            "authorization": `Bearer ${token}`
          }
        }
      )
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          console.log(data[0]?._id);
          // this.props.handleChange(data[0]?._id);
          
          const tempArray = [];
          if (data) {
            if (data.length) {
              data.forEach((element) => {
                tempArray.push({
                  label: `${element.firstName}` + " " + `${element.lastName}`,
                  value: element._id,
                });
              });
            } 
          }
          callback(tempArray);
        })
        .catch((error) => {
          console.log(error, "Search/Filter did not work");
        });
    }, 1000);
    }
  };

  

  const onSearchChange = (data) => {
    if (data) {
      setSelectedOption(data)
      handleChange(data.value)
    }
  };
 
  

    return (
      

        <div height='auto'>
          
          <AsyncSelect
            value={selectedOption}
            loadOptions={fetchData}
            onChange={(e) => {
              onSearchChange(e);
            }}
            defaultOptions={true}
            ignoreCase
            matchFromStart={true}
            styles={{
              menu: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: 'white',
                zIndex: 100
              })

            }}
          />
        </div>
      
    );
  
}

SelectAsyncUser.propTypes = {
  handleChange: PropTypes.any
}

export default SelectAsyncUser;