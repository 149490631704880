import React from 'react';
import PropTypes from 'prop-types';

const ReviewPersInfo = ({client}) => {
    
    
return (
    <>
    
            
                <div className = "row">
                    <label>Birth Date: {new Date(client?.personalInfo?.dateOfBirth).toLocaleDateString()} </label>
                </div>
                
                <div className = "row">
                    <label> Phone: { client?.personalInfo?.phone}  Email: {client?.personalInfo?.email}</label>
                        
                </div>
                <div className="row">
                    <label>Address: {client?.personalInfo?.address?.addressLineOne}</label>
                </div>
                <label>{client?.personalInfo?.address?.addressLineTwo}</label>
                <label>{client?.personalInfo?.address?.city}, {client?.personalInfo?.address?.state} {client?.personalInfo?.address?.zip} </label>
                <div className='row'>
                    <label>Living Situation: {client?.personalInfo?.livingSituation}</label>
                    <br />
                    <label>Emergency Contact: {client?.personalInfo?.emergencyContact}</label>
                </div><br />
                <div className='row'>
                    <label>Regional Center Info:</label><br />
                    <label>{client?.personalInfo?.regionalCenter} UCI#: {client?.personalInfo?.rcId}</label><br />
                    <label>Service Coordinator: {client?.personalInfo?.serviceCoordinator} Phone: {client?.personalInfo?.serviceCoordinatorPhone}</label>
                </div>
      
    </>  
);

};

ReviewPersInfo.propTypes = {
    client: PropTypes.any
}

export default ReviewPersInfo;