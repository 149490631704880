import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useResetPasswordMutation } from '../../redux/apiSlice';
import { Button, TextField, Paper, Box, FormControlLabel, Checkbox } from '@mui/material';
import { PropTypes } from 'prop-types';
import * as yup from 'yup';
import { useYupValidationResolver } from '../../utilities/useYupValidationResolver';
import { toast } from 'react-toastify';

const validationSchema = yup.object({
    password: yup
      .string('Enter Password')
      .required('Required'),
    confirmPassword: yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf(
          [yup.ref("password")],
          "Passwords do not match"
        )
      })    
  });

const ResetPassword = ({ match }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confError, setConfError] = useState("");
    const [showPass, setShowPass] = useState(false);

    const navigate = useNavigate();

    ResetPassword.propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                resetToken: PropTypes.string.isRequired
            })
        })
    }

    const resToken = match?.params.resetToken

    const [resetPass, {isSuccess, isError, error}] = useResetPasswordMutation()

    const resolver = useYupValidationResolver(validationSchema);

    const {watch, register, handleSubmit, formState: {errors, dirtyFields}} = useForm({
        resolver,
        defaultValues: {
            password: '',
            confirmPassword: ''
        }
    })

    useEffect(() => {
        if (isSuccess) {
            navigate("/login")
        } else if (isError) {
            toast.error(`Password Reset error: ${error}`, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    })

    const watchConf = watch('confirmPassword')
    const watchPass = watch('password')
    
    useEffect(() => {
        if(dirtyFields.password && dirtyFields.confirmPassword) {
            if (watchPass !== watchConf) {
                setConfError("Passwords do not match")
            }
        }
    }, [watchConf, watchPass, dirtyFields])

    const onSubmit = async (data) => {
        const password = data.password
        
        await resetPass({resToken, password})
        
    }


    return (
        <>
        <Box
            sx={{
                mx: 'auto',
                width: 500,
                pt: 10,
                mt: 10,
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
                
            }}
        >
        <Paper elevation={24}>
        <div>
            <form id= "resetPass" onSubmit={handleSubmit(onSubmit)}>
                <h3>ResetPassword</h3>
                {confError && <span>{confError}</span>}
                <div className="form-group">
                    <label htmlFor="password">New Password:</label>
                    <TextField
                        type={showPass ? "text":"password"}
                        {...register("password", {
                            required: true
                        })}
                        name="password"
                        placeholder='Enter new password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={!!errors.password}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={showPass} onChange={() => setShowPass(!showPass)} />}
                        label="Show Password"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmpassword">Confirm New Password:</label>
                    <TextField
                        type={showPass ? "text":"password"}
                        {...register("confrimPassword", {
                            required: true
                        })}
                        name="confirmpassword"
                        placeholder='Confirm new password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={!!errors.confirmPassword}
                    />
                </div>
                <Button type="submit" form='resetPass' color="primary" variant='contained'>
                    Reset Password
                </Button>
            </form>
        </div>
        </Paper>
        </Box>
        </>
    );
};

export default ResetPassword;