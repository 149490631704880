import React from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
//import { Link } from 'react-router-dom';
import {
  useGetAllIncomeQuery,
  useDeleteIncomeMutation,
} from "../../../redux/apiSlice";
import {
  CircularProgress,
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import AddIncome from "./AddIncome";
import EditIncome from "./EditIncome";
import DeleteAlert from "../../../DeleteAlert";

const Income = () => {
  const { clientId } = useParams();

  const { data, isLoading, isSuccess } = useGetAllIncomeQuery(clientId);

  const [deleteIncome] = useDeleteIncomeMutation();

  const columns = [
    {
      field: "source",
      headerName: "Source",
      width: 150,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "recurring",
      headerName: "Recurring",
      width: 80,
    },
    { field: "recurRate", headerName: "Recur Rate", width: 150 },
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        let incomeId = params.id;
        let source = params.row.source
        return (
          <>
            <EditIncome incomeId={incomeId} data={params.row} />
            {/* <IconButton onClick={() => deleteIncome(incomeId)}>
              <DeleteIcon color="error"/>
            </IconButton> */}
            <DeleteAlert name={`Client Income-${source}`} id={incomeId} hook={deleteIncome} softDelete={false} />
          </>
        );
      },
    },
  ];

  let content;

  if (isLoading) {
    content = <CircularProgress />;
  } else if (isSuccess) {
    content = (
        <Box sx={{ display: "flex" }}>
          {data && (
            <DataGrid
              autoHeight
              density="compact"
              getRowId={(row) => row._id}
              rows={data}
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              //autoPageSize
              sx={{ height: "100%", width: "100%" }}
            />
          )}
        </Box>
    );
  }

  return (
    
      <Box 
        component="span"
        sx={{ display: 'block', mx: '2px', my: '10px', height: '100%', width: '100%' }}
      >
        <Card>
          <CardContent>
            <Typography>Income</Typography>
            <AddIncome />

            <div>{content}</div>
          </CardContent>
        </Card>
      </Box>
    
  );
};

export default Income;
