import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { useStopUserClockMutation } from '../../../redux/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { resetClock, selectClockInfo } from '../../../redux/slices/userSlice';
import { toast } from 'react-toastify';

const TimeOut = ({isActive, handleClose}) => {
    const [start, setStart] = useState();
    const [stop, setStop] = useState();
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    // console.log("entry1: ", entryData)

    const [stopClock] = useStopUserClockMutation()
    const dispatch = useDispatch();

    const entryData = useSelector(selectClockInfo);
    // console.log("entryData1: ", entryData)

    useEffect(() => {
        // console.log("entry2: ", entryData)
        if(!isActive && entryData?.info?.timeOut?.length) {
          let begin = new Date(entryData?.info?.timeIn)
          let end = new Date(entryData?.info?.timeOut)
          const time = Math.abs( begin - end ) ;

          // console.log("begin and end: ", begin, end)
          
          setStart(begin)
          setStop(end)
          

          setHours(Math.floor((time /(1000 * 60 * 60)) % 24))
          setMinutes(Math.floor((time / 1000 / 60) % 60))
          setSeconds(Math.floor((time / 1000) % 60))
            
        }
      }, [isActive, entryData])

      // console.log("minutes: ", minutes, "seconds: ", seconds)

      const submitEntry = async (data) => {
        // console.log(data);

        const bracketId = data.bracketId
        const entryId = data.entryId
  
        // const payload = 
        await stopClock({ bracketId, entryId, data })
        // .unwrap();
        // console.log('fulfilled', payload)
  
        toast.success("Clocked Out", {
          position: toast.POSITION.TOP_RIGHT
        });
        dispatch(resetClock())
        handleClose();
      }

  return (
    <div>
        <Typography>Time info for this session:</Typography> <br />
        {start &&
        <>
            <Typography>Started at: {start.toLocaleTimeString()} | Ended at: {stop.toLocaleTimeString()}</Typography>
            <div className='timer'>
              <div className='col-4'>
                <div className='box'>
                  <p>{hours}</p>
                  <span className='text'>Hours</span>
                </div>
              </div>
              <div className='col-4'>
                <div className='box'>
                  <p>{minutes}</p>
                  <span className='text'>Minutes</span>
                </div>
              </div>
              <div className='col-4'>
                <div className='box'>
                  <p>{seconds}</p>
                  <span className='text'>Seconds</span>
                </div>
              </div>
            </div>
        </>
        }
        
        <br />
        <Typography>Click below to save this to your timecard</Typography> <br />
        <Button color='primary' variant='contained' onClick={() => submitEntry(entryData)}>Submit</Button>
    </div>
  )
}

TimeOut.propTypes = {
    isActive: PropTypes.any,
    entryData: PropTypes.any,
    handleClose: PropTypes.any,
};

export default TimeOut;