import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { ClientContext } from '../ClientContext';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import EditMedicalInfo from './EditMedicalInfo';


const MedicalInfo = () => {

  const { client } = useContext(ClientContext);
    
return (
    <>
     <Card sx={{display: 'flex-block', flexDirection: 'column', mx: '2px', my: '10px', px: '2px', py: '10px', height: '100%', width: '100%' }}>
        <CardHeader
            title="Insurance Information"
            action={<EditMedicalInfo client={client}/>}
        />
            <CardContent>
            <div className = "card-body">
                <div className = "row">
                    
                    <label>Primary: {client?.medical?.primaryIns} ID: {client?.medical?.primaryInsId}</label><br />
                    <label>Secondary: {client?.medical?.secondaryIns} ID: {client?.medical?.secondaryInsId}</label><br />

                </div>
                
                <div>
                    <label>Medi-Cal:</label> <br />
                    {client?.medical?.hasMediCal ? 
                        <Stack direction={'row'} spacing={2}>
                            <CheckBox /> <br />
                            <label>Medi-Cal Id: {client?.medical?.mediCalId}  </label>    
                            <label>Enrollment Date: {new Date(client?.medical?.mediCalEnroll).toLocaleDateString()}</label>
                        </Stack>
                        :
                        <Stack direction={'row'}>
                            <CheckBoxOutlineBlank />
                            <label>No Medi-Cal</label>
                        </Stack>
                    }
                    <br />
                    <label>Medicare:</label> <br />
                     {client?.medical?.hasMedicare ? 
                        <Stack direction={'row'} spacing={2}>
                            <CheckBox /> <br />
                            <label>Medicare Id: {client?.medical?.medicareId}  </label>
                            <label>Enrollment Date: {new Date(client?.medical?.medicareEnroll).toLocaleDateString()}</label>
                        </Stack>
                        :
                        <Stack direction={'row'}>
                            <CheckBoxOutlineBlank />
                            <label>No Medicare</label>
                        </Stack>
                    }
                    <label>IHSS:</label><br />
                    {client?.medical?.ihss?.provider || client?.medical?.ihss?.hours || client?.medical?.ihss?.services ?
                        <Stack direction={'row'} spacing={2}>
                            <label>Provider: {client?.medical?.ihss?.provider}</label> <br />
                            <label>Hours: {client?.medical?.ihss?.hours}</label> <br />
                            <label>Services: {client?.medical?.ihss?.services}</label>
                        </Stack>
                        :
                        <label>None at this time.</label>
                    }
                        
                </div>
                
            </div>

        </CardContent>
    </Card>
    
    </>  
);

};

export default MedicalInfo;