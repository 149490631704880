import React, {useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, CircularProgress, Box, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useGetAgencyConsentsQuery, useDeleteUploadMutation, usePushDocToClientsMutation } from '../../../redux/apiSlice';
import ViewDoc from './ViewDoc';
import { useSelector } from 'react-redux';
import DeleteAlert from '../../../DeleteAlert';
import MultiSelectClient from '../../../utilities/MultiSelectClient';
import {useForm, Controller} from 'react-hook-form';

const AgencyConsents = () => {
    
    const agencyId = useSelector(state => state?.settings?.id)
    const {data, isLoading, isSuccess} = useGetAgencyConsentsQuery(agencyId)

    const [pushDoc] = usePushDocToClientsMutation();
    const [deleteUpload] = useDeleteUploadMutation();


    const [anchorE1, setAnchorE1] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [idForSelect, setIdForSelect] = useState();

    const { control, handleSubmit } = useForm({
        defaultValues: {
            toAll: false,
            uploadId: idForSelect,
            clients: []
        }
    })

    const onSubmit = async (data) => {
        const clientData = data?.clients.map(c => {
            return c.value
        })

        const final = {
            toAll: false,
            uploadId: idForSelect,
            clients: clientData
        }

        data = final

        console.log(data)
        pushDoc(data)
        handleDialogClose();
    }

    const handleMenuClick = (event) => {
        setAnchorE1(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorE1(null);
    };

    const handleDialogOpen = (uploadId) => {
        setIdForSelect(uploadId);
        setDialogOpen(true);
        handleMenuClose();
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handlePushAll = (uploadId) => {
        const data = {
            toAll: true,
            uploadId: uploadId
        }
        console.log("data to all: ", data)
        pushDoc(data)

    };

    
    const open = Boolean(anchorE1);


    const columns = [
        { field: "title", headerName: "Title", width: 150 },
        { 
            field: "uploadDate", 
            headerName: "Upload Date", 
            width: 150,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                };
                return date.toLocaleString("en-US", options);
              }
        },
        { 
            field: "expires", 
            headerName: "Expires on", 
            width: 150,
            valueFormatter: (params) => {
                if(params.value === null) {
                    return ''
                } else {
                const date = new Date(params.value);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                };
                return date.toLocaleString("en-US", options);
            }
              }
        },
        {
            field: "actions",
            headerName: "",
            width: 150,
            renderCell: (params) => {
                // console.log(params)
                let uploadId = params.id;
                let docTitle = params.row.title;
                let docName = params.row.name
                return (
                    <>
                        <Box
                            sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                            }}
                        >
                        <ViewDoc doc={params.row.file} title={docTitle} name={docName} />
                        <IconButton 
                            onClick={handleMenuClick}
                            aria-label="Push to Clients"
                            aria-controls='long-menu'
                            aria-haspopup='true'
                        >
                            <AddIcon color='primary' />
                            
                        </IconButton>
                        <Menu
                                id="long-menu"
                                anchorE1={anchorE1}
                                open={open}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                            >
                                <MenuItem onClick={() => handlePushAll(uploadId)}>Push to All</MenuItem>
                                <MenuItem onClick={() => handleDialogOpen(uploadId)}>Push to Select</MenuItem>
                            </Menu>
                        
                        <Dialog open={dialogOpen} onClose={handleDialogClose}>
                            <DialogTitle>Select Clients</DialogTitle>
                            <DialogContent>
                                <form id="assignClients"
                                    onSubmit={(e) =>
                                        handleSubmit(onSubmit)(e).catch((e) => {
                                          console.log("e", e);
                                })}>
                                <Controller
                                    control={control}
                                    name="clients"
                                    render={({ field }) => (
                                
                                    <MultiSelectClient  
                                    // {...field}
                                    ref={field.ref}
                                        placeholder='Enter Client Name'
                                        handleChange = {((e)  => field.onChange(e))}
                                        onChange={(e)=>field.onChange(e)}
                                        value={field.value}
                                        theme={theme => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: 'blue',
                                            primary: 'black'

                                        }
                                    })}
                                />  
                                )}
                                />
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button color='warning' variant='contained' onClick={handleDialogClose}>
                                    Close
                                </Button>
                                <Button color='primary' variant='contained' form='assignClients' type='submit'>
                                    Submit
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <DeleteAlert name={`Client Doc-${docTitle}`} id={uploadId} hook={deleteUpload} softDelete={false} />
                        </Box>
                    </>
                )
            }
        }

    ]

    let content

    if (isLoading) {
        content = <CircularProgress />;
    } else if (isSuccess) {
        content = (
            <div style={{ display: "flex" }}>
                
                <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
                    {data && (
                        <DataGrid
                            autoHeight
                            autoWidth
                            getRowId={(row) => row._id}
                            rows={data}
                            columns={columns}
                            sx={{ height: "100%", width: "100%" }}
                        />
                    )}
                </Box>
            </div>
        )
    }

  return (
    <div>
        <div>{content}</div>
    </div>
  )
}

export default AgencyConsents;