import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {Button, Select, MenuItem, Table, TableHead, TableBody, TableRow, TableContainer, TableCell, Paper, FormControl, InputLabel, Box, Typography } from '@mui/material';
import { useGetGoalAttainDataMutation, useGetAllClientReportsQuery } from '../../../redux/apiSlice';
// import { getDaysInMonth, parseISO } from  'date-fns';

const ClientGoalReport = () => {

    const { clientId } = useParams();

    const [ planId, setPlanId ] = useState()
    const [ planList, setPlanList ] = useState([])
    const [ isPlan, setIsPlan ] = useState('')
    const [ isStart, setIsStart ] = useState()
    const [ isEnd, setIsEnd ] = useState()
    const [ header, setHeader ] = useState()
    const [tableData, setTableData] = useState()
    const [hasData, setHasData] = useState('initial')

    const { data: plans } = useGetAllClientReportsQuery(clientId)
    

    useEffect(() => {
        if (plans) {
            let list = plans.map((plan, idx) => (
                
                { 
                  value: {start: plan.startDate, end: plan.endDate, planId: plan._id},
                  label: `${idx+1} ${plan.type} Report Date: ${new Date(plan.reportDate).toLocaleDateString()}, Start: ${new Date(plan.startDate).toLocaleDateString()}, End: ${new Date(plan.endDate).toLocaleDateString()} `
                }
                 )).sort((a, b) => a.startDate - b.startDate)
            setPlanList(list);

            const def = list[list.length -1]
            setIsPlan(def)
        }
    }, [plans])

    const [pullData, { data, isSuccess }] = useGetGoalAttainDataMutation()

    

    const generatePlanOptions = () => {
        
        return planList.map((plan, idx) => {
    
            return (
              
               <MenuItem key={idx} value={plan.value}>
                {plan.label}
               </MenuItem>
            )
            })
      }

      const renderValue = (selected) => {
        if (!selected) return '';
        return selected.label;
      }

      const handlePlanChange = (event) => {
        const selectedPlan = event.target.value

        const match = planList.find(o => o.value.planId === selectedPlan.planId)
    
        setPlanId(selectedPlan.planId)
        setIsStart(selectedPlan.start)
        setIsEnd(selectedPlan.end)

        setIsPlan(match)
      }

      useEffect(() => {
        //set dates for header/ use dates var for matching to goals
        function dateRange(startDate, endDate, steps = 1) {
        
          const dateArray = [];
          let currentDate = new Date(startDate);
        
          while (currentDate <= new Date(endDate)) {
            dateArray.push(new Date(currentDate));
            // Use UTC date to prevent problems with time zones and DST
            currentDate.setUTCDate(currentDate.getUTCDate() + steps);
          }
          
          
          //set date range for Header
          const monthSet = dateArray.map(i => `${i.getFullYear()}-${i.getMonth()+1}`)
          const dates = [...new Set(monthSet)]

          setHeader(dates)
          return dates;  
      }
  
      const dates = dateRange(isStart, isEnd);
      // console.log(dates);

    //   function convertToISODate(dateString) {
    //     // Split the string into parts
    //     const parts = dateString.split('-');
        
    //     // If the month part has only one digit, pad it with a leading zero
    //     const month = parts[1].length === 1 ? '0' + parts[1] : parts[1];
        
    //     // Create the ISO date string in the format YYYY-MM-DD
    //     const isoDateString = parts[0] + '-' + month + '-01';
        
    //     // Create a new Date object using the ISO date string
    //     const isoDate = new Date(isoDateString);
        
    //     return isoDate;
    // }    
        
      
        if (isSuccess && data.length == 0) {
          setHasData('no value')
        } else if(isSuccess && data[0] != undefined) {
          
          
          const o = data[0]; 
          
          const lookup = o.setVisits.reduce((acc, {date, goals}) => {
            goals.forEach(({k, v}) => (acc[date] ??= {})[k] = ((acc[date][k] !== null && acc[date][k] !== undefined) ? acc[date][k] : 0) + v);
            return acc;
          }, {});

          const res = o.planDetails.goals.map(({title: goal, frequency}) => 
            ({goal, frequency, ...Object.fromEntries(dates.map((date, i) => [`elem${i+1}`, {date, count: lookup[date]?.[goal] ?? 0}]))}));

          const withPerc = res.map(i => {
            
            let total = i.elem1.count + i.elem2.count + i.elem3.count + i.elem4.count + i.elem5.count + i.elem6.count
            let percStep = `${((total/(i.frequency * 6)) * 100).toFixed(2)}%`
            return ({...i, perc: percStep})
        })
          setHasData('has data');
          setTableData(withPerc);
        }
      
        }, [data, isSuccess, isStart, isEnd])

     
      let displayReport 
      if (hasData === 'initial') {
        displayReport = <Typography variant='h6'>Select a Plan and click Run</Typography>
      } else if (hasData === 'has data') {
        displayReport =
            <>
            <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Goals</TableCell>
                      <TableCell>Frequency/ Month</TableCell>
                      {header && header.map((i, idx) => (
                        <TableCell key={idx}>{i}</TableCell>
                      ))}
                      <TableCell>Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData && tableData.map((i, idx) => (
                      <React.Fragment key={idx}>
                        <TableRow>
                          <TableCell>{i.goal}</TableCell>  
                          <TableCell>{i.frequency}</TableCell>
                          <TableCell>{i.elem1.count}</TableCell>
                          <TableCell>{i.elem2.count}</TableCell>
                          <TableCell>{i.elem3.count}</TableCell>
                          <TableCell>{i.elem4.count}</TableCell>
                          <TableCell>{i.elem5.count}</TableCell>
                          <TableCell>{i.elem6.count}</TableCell>
                          <TableCell>{i.perc}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                    
                  </TableBody>
                </Table>
                </TableContainer>
              </Box>
            </>
      } else if ( hasData === 'no value') {
        displayReport = <Typography variant='h6'>No goals marked on visits for this reporting period</Typography>
      }

  return (
    <>
    <div display='flex'>
        <Box
            sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            m: 1,
            borderRadius: 1,
            }}
        >
            <Typography variant='h5'>Search by Service Plan</Typography><br />
             <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="plan-select-label">Plan</InputLabel>
                    <Select
                        labelId="plan-select-label"
                        label="Plan"
                        name='plan'
                        value={isPlan}
                        onChange={handlePlanChange}
                        autoWidth
                        renderValue={renderValue}
                        native={false}
                        displayEmpty={true}
                    >
                        {generatePlanOptions()}
                    </Select>
            </FormControl><br />
            {/* <h1>Search by Date Range</h1> */}
           
            <Button color='primary' variant='contained' onClick={() => pullData({clientId, isStart, isEnd, planId})}>    Run    </Button>
        </Box>
    </div>
    
    {displayReport}
    </>
  )
}

export default ClientGoalReport