import React from 'react';
import MedicalInfo from './MedicalInfo';
import AllergiesList from './AllergiesList';
import MedsList from './MedsList'
import { Card } from '@mui/material';

const MedInfoTab = () => {
  return (
    <div>
       <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
          <MedicalInfo />
        </Card>
        <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
          <AllergiesList />
        </Card>
        <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
          <MedsList />
        </Card>
        {/*  logs? */}
    </div>
  )
}

export default MedInfoTab