import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Box, Button, Typography } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useProgNotesSearchMutation } from '../../redux/apiSlice';
import SelectAsyncUser from '../../utilities/SelectAsyncUser';
import ProgNotesTable from './ProgNotesTable';
import { useSelector } from 'react-redux';

const ProgNotesTab = () => {
    const { clientId } = useParams();

    // const [skip, setSkip] = useState(true)
	const [userId, setUserId] = useState()
	const [isStart, setIsStart] = useState();
	const [isEnd, setIsEnd] = useState();

    // const [value, setValue] = useState(!skip ? [] : [new Date().toJSON(), new Date().toJSON()]);
	const [value, setValue] = useState([new Date().toJSON(), new Date().toJSON()]);

	const clientName = useSelector(state => state.client.client.fullName)

	const printInfo = {
		name: clientName,
		start: isStart,
		end: isEnd
	}

	useEffect(() => {
		if (value[0]) {
			// console.log("value: ", value)
			let startDate = value[0];
			
			let start = new Date(new Date(startDate).setHours(0, 0, 0))
			
			// console.log("start: ", start)

			setIsStart(start)
		} else if (value[0] === null) {
			// console.log("value: ", value)
			setIsStart(null)
		}
	}, [value])

	useEffect(() => {
		if (value[1]) {
			// console.log("value: ", value)
			let endDate = value[1];

			let end = new Date(new Date(endDate).setHours(23, 59, 59))
			// console.log("end: ", end)

			setIsEnd(end)
		} else if (value[1] === null) {
			// console.log("value: ", value)
			setIsEnd(null)
		}
	}, [value])
	

    const [pullProgNotes, {data: notesResult, isSuccess, isLoading}] = useProgNotesSearchMutation()

	const handleUserChange = (_id) => {
		setUserId(_id)
	}

	let table = <div><Typography variant='h5'>Select Filter Options and Select Run</Typography><br />
	<Typography variant='h5'>To get all notes for this client, leave filters empty and click run</Typography></div>

	if (notesResult, isLoading, isSuccess) {
		table =
		<div>
			<ProgNotesTable data={notesResult} isSuccess={isSuccess} isLoading={isLoading} printInfo={printInfo} />
		</div>
	}	

  return (
    <div>
        <fieldset width='300px'>
		
		<LocalizationProvider dateAdapter={AdapterDateFns}>
		
			<legend>Filter By Date Range</legend>
		<DateRangePicker
			value={value}
			onChange={(newValue) => {
				new Date().toISOString(newValue);
				setValue(newValue);				
			}}
			renderInput={(startProps, endProps) => (
				<React.Fragment>
					<TextField {...startProps} />
					<Box sx={{ mx: 2}}> to </Box>
					<TextField {...endProps} />
				</React.Fragment>
			)}
		/>
		</LocalizationProvider>
			<legend>Filter By User</legend>
			<SelectAsyncUser 
                 
                placeholder='Enter User Name' 
                //   handleChange = {(_id  => field.onChange(_id))}
				handleChange = {(_id => handleUserChange(_id))}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                     primary25: 'blue',
                     primary: 'black'
                }
                 })}
             />  
		<Button onClick={() => pullProgNotes({clientId, userId, isStart, isEnd})} color='primary' variant='contained'>
			Run 
		</Button>
		</fieldset>
		<br /><br /><br />
		{table}
    </div>
  )
}

export default ProgNotesTab