import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSoftDeleteLogMutation } from '../../../redux/apiSlice';
import DeleteAlert from '../../../DeleteAlert';
import { useParams } from 'react-router-dom';

const PhoneTable = ({list}) => {
    const listCopy = list.slice()

    const [hasList, setHasList] = useState([])

    useEffect(() => {
        if(list) {
            const sorted = listCopy.sort((a, b) => new Date(b.changeDate) - new Date(a.changeDate));

            setHasList(sorted)
        }
    }, [list])

    const [delPhone] = useSoftDeleteLogMutation()
    const {clientId} = useParams();
    const logType = 'phone'

    

    const phoneList = hasList?.map((a, idx) => (  
        <TableRow key={idx}>
            <TableCell>{new Date(a?.changeDate).toLocaleDateString()}</TableCell>
            <TableCell>{a?.value}</TableCell> 
            <TableCell><DeleteAlert logType={logType} clientId={clientId} name={`Client Phone Log- ${a.value}`} id={a._id} hook={delPhone} softDelete={true} /></TableCell>
        </TableRow>
        ))

    let content
    
    if (phoneList) {
        content = phoneList
    } else {
        content = <TableRow>
            <TableCell>No data</TableCell>
            <TableCell>No data </TableCell>
            <TableCell></TableCell>
        </TableRow>
    }
    

    return (
        <div>
            <TableContainer component={Paper}>
                <Table size='small' aria-label='Address History'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Changed On</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {content}
                    </TableBody>
                </Table>
            </TableContainer>
            
        </div>
    )
}

PhoneTable.propTypes = {
    list: PropTypes.any
}

export default PhoneTable;