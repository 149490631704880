import React from 'react';
import UsersList from './userviews/UsersList';
import AddUser from './userviews/AddUser';



function UsersTab() {
return (
 <div>
       
   <h1>Users</h1>
 
 <AddUser />

<UsersList />

  </div>
)
}


export default UsersTab;

