import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Typography, MenuItem, TextField, Button, IconButton} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import {useForm, Controller} from 'react-hook-form';
// import { useAddStepperReportMutation } from '../../../redux/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
// import { setNewPlanId, setLastPlanId } from '../../../redux/slices/clientSlice';
import { setAllowStep, setPlanDetails, setPlanClient } from '../../../redux/slices/stepperSlice';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

const options = [
    { label: 'Report Type', value: '' },
    { label: 'Annual', value: 'Annual' },
    { label: 'Semi Annual', value: 'Semi Annual' },
  ]

const StepPlan = ({addStep, removeStep, allowStep}) => {  
   
    // const [addNewPlan] = useAddStepperReportMutation();

    const {clientId} = useParams();
    const dispatch = useDispatch();
    const titles = useSelector(state => state.stepper.stepGoals)
    // const currentPlan = useSelector(state => state.client.client.currentPlan)

    //prevent forward action until new plan is submitted
    // (() => { dispatch(setAllowStep(true))})();
    useEffect(() => {
      if(!allowStep){
        dispatch(setAllowStep(true))
      }
    }, [])
    

  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
    reportDate: '',
    startDate: '',
    endDate: '',
    type: ''
    }
  });
  
  const addNewPlan = (data) => {
    
    dispatch(setPlanClient(clientId));
    dispatch(setPlanDetails(data));

  }
 
 
 const onSubmit= async (data) => {

  console.log('data', data)
  addNewPlan(data);

    
 }

 const onError = () => {
   
   console.log('error');
 } 


  return (
    <div>
        <Typography variant="h3" sx={{ textAlign: 'center'}}>
            Service Plan
        </Typography><br />
        <form id="addServicePlan" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
                 <section>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <TextField 
                            select
                            error={!!errors.type}
                            label="Report Type"
                            sx={{ minWidth: 500}}
                            onChange={field.onChange}
                            value={field.value}
                            variant= 'outlined'
                            >
                            {generateSingleOptions()}
                            </TextField>
                        )}
                    
                        rules = {{ required: true }}
                    />
                <br></br>
                {errors.type && "Select Contract Type"}
                </section>
                <section>
      
                    <Controller
                        control={control}
                        name="reportDate"
                        
                
                        render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                            
                            label="Client Report Date"
                            error={!!errors.dateOfReport}
                            value={field.value}
                            onChange={(e)=>field.onChange(e)}
                            renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        )}
                    />
                <br></br>
                {errors.dateOfReport && "Select Report Date"}
                </section>
                <br></br>
          
                <section>
      
                        <Controller
                            control={control}
                            name="startDate"
                            
                    
                            render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                
                                label="Report Start Date"
                                error={!!errors.startDate}
                                value={field.value}
                                onChange={(e)=>field.onChange(e)}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            )}
                        />
                <br></br>
                {errors.startDate && "Select Start Date"}
                </section>
                <br></br>
                <section>
                
                        <Controller
                            control={control}
                            name="endDate"
                            render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                
                                label="Report End Date"
                                error={!!errors.endDate}
                                value={field.value}
                                onChange={(e)=>field.onChange(e)}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            )}
                        />
                <br></br>
                {errors.endDate && "Select End Date"}
                </section>
                <br></br>
                <Typography variant="h5" sx={{ textAlign: 'center'}}>
                    Below are the goals to be copied, you can add a new goal template or remove a goal on this page.
                </Typography><br />
                <Typography variant="h6" sx={{ textAlign: 'center'}}>
                  Add a goal:
                </Typography>
                <IconButton onClick={addStep}>
                  <AddCircleIcon />
                </IconButton><br />
                {console.log("step Plan titles", titles)}
                {titles?.map((i, idx) => (
                  
                  <React.Fragment key={idx}>
                    <label>{i.title}</label>
                    <IconButton onClick={() => removeStep(idx)}>
                      <DeleteIcon />
                    </IconButton>
                    <br />
                  </React.Fragment>
                ))}
                
        </form>
        <Button color="primary" variant="contained" form="addServicePlan" type="submit" onClick={() => dispatch(setAllowStep(false))}>
          Set Plan Details
        </Button>
    </div>
  )
}

StepPlan.propTypes = {
  addStep: PropTypes.func,
  removeStep: PropTypes.func,
  allowStep: PropTypes.any
  
}

export default StepPlan;