import React from 'react';
import { Paper, Box } from '@mui/material';
import PropTypes from 'prop-types';
import EditMiles from './EditMiles';
import { useDeleteMilesMutation } from '../../redux/apiSlice';
import './Timecard.css'
import DeleteAlert from '../../DeleteAlert';

const MilesBlock = ({ mile, bracket }) => {    
    const milesId = mile._id;

    const [deleteMiles] = useDeleteMilesMutation();

    return (
        <>
            <Paper>
                <Box>
                         <div >
                                <h3>Source: {mile?.source}</h3>
                                <h3>Destination: {mile?.destination}</h3>
                                <h3>Distance: {mile?.distance}</h3>
                                
                        </div>
                        <div className='icons'>
                                <EditMiles mile={mile} bracket={bracket} />
                                <DeleteAlert name={'Miles'} id={milesId} hook={deleteMiles} softDelete={false} />
                        </div>
                </Box>
            </Paper>
        </>
    )


}

MilesBlock.propTypes = {
    mile: PropTypes.any,
    bracket: PropTypes.any
}

export default MilesBlock;