import React, {useState} from 'react';
import { Button, Dialog, DialogActions, DialogContent, Divider, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useForm, Controller } from 'react-hook-form';
import { useAddNewLogMutation } from '../../../redux/apiSlice';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../utilities/useAuth';
import { toast } from 'react-toastify';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


const AddAddressEntry = () => {
    const {id} = useAuth()
    const isUser = id

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        reset();
        setOpen(false);
      };

      const {clientId} = useParams();

      const [addLog] = useAddNewLogMutation()

      const {register, control, reset, handleSubmit} = useForm({
        defaultValues: {
            moveIn: '',
            moveOut: '',
            lineOne: '',
            lineTwo: '',
            city: '',
            state: '',
            zip: ''
        }
      });

      const onSubmit = async (data) => {
        // console.log("data1: ", data)

        const setAddress = `${data.lineOne} ${data.lineTwo}, ${data.city}, ${data.state} ${data.zip}`
        const finalObj = {
            moveIn: data.moveIn,
            moveOut: data.moveOut,
            value: setAddress,
            changeDate: new Date().toISOString(),
            isDeleted: false
        }
        data = finalObj

        // console.log("data2: ", data)
        const logType = 'address'

        try {
            await addLog({logType, clientId, isUser, data})
        } catch (err) {
            console.log('Failed to add log', err)
            toast.error("Error adding log", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        toast.success("Log Entry Added", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();

      }

    return (
        <div>
            <Button onClick={handleClickOpen} startIcon={<AddIcon />}>Add Address</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <form id='addAddress'
                         onSubmit={(e) =>
                            handleSubmit(onSubmit)(e).catch((e) => {
                                console.log("e", e);
                        })}>
                            
                            <TextField label='Line One' variant='outlined' {...register('lineOne')} /> <br />
                            <TextField label='Line Two' variant='outlined' {...register('lineTwo')} /> <br />
                            <span>
                                <TextField label='City' variant='outlined' {...register('city')} />
                                <TextField label='State' variant='outlined' {...register('state')} />
                                <TextField label='Zip' variant='outlined' {...register('zip')} />
                            </span> <br />
                            <Divider />
                            <span>
                            <Controller
                                control={control}
                                name='moveIn'
                                render={({field}) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Move In Date"
                                            value={field.value}
                                            onChange={(e) => field.onChange(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                            <Controller
                                control={control}
                                name='moveOut'
                                render={({field}) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Move Out Date"
                                            value={field.value}
                                            onChange={(e) => field.onChange(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                            </span>    
                        </form>
                </DialogContent>
                <DialogActions>
                    <Button color='warning' onClick={handleClose}>Cancel</Button>
                    <Button form='addAddress' type='submit'>Submit</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default AddAddressEntry