import { useSelector } from "react-redux";
import { selectCurrentToken } from "../redux/slices/authSlice";
import jwt_decode from 'jwt-decode';

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    // const supervisor = false
    let admin = false
    let supAdmin = false
    let status = "Staff"

    if (token) {
        const decoded = jwt_decode(token)
        
        const { username, role, id, fullName} = decoded.UserInfo
        // console.log("useAuth: ", decoded.UserInfo)

        if (role === "Super Admin") {
            supAdmin = true;
            status = "Super Admin";
        } else if (role === "Admin") {
            admin = true;
            status = "Admin";
        } else if (role === "Supervisor") {
            status = "Supervisor"
        }

        return { username, fullName, role, id, status, admin, supAdmin }
    } else {
        return { username: '', role: ''}
    }
}

export {useAuth};