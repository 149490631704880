import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import React from "react";
import { differenceInMonths, format } from "date-fns";
import PropTypes from 'prop-types';

function ClientGoalTable({ client, startDate, endDate }) {
    const planHeaders = []
    client.plans.forEach((plan, index) => {
        planHeaders.push(
            <TableCell
                key={`plan_${index}`}
                colSpan={
                    differenceInMonths(new Date(plan.end), new Date(plan.start)) //+1
                }
            >
                {`${plan.type}, ${format(new Date(plan.start), "MM/yyyy")} - ${format(
                    new Date(plan.end), "MM/yyyy"
                )}`}
            </TableCell>
        )
    })

  const prepareTableData = (client, startDate, endDate) => {
    const { plans, visits } = client;

    // Extracting plan data within the date range
    const relevantPlans = plans.filter((plan) => {
      // Filter plans based on start and end dates
      return plan.start >= startDate; //&& plan.end <= endDate;
    });

    // Extracting month/year headers based on the date range
    const monthYearHeaders = [];
    const currentDate = new Date(startDate);
    while (currentDate <= new Date(endDate)) {
      monthYearHeaders.push(
        `${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`
      );
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    // Extracting goal titles from plans and visits
    const goalTitlesSet = new Set();
    relevantPlans.forEach((plan) => {
      plan.goals.forEach((goal) => goalTitlesSet.add(goal.title));
    });
    visits.forEach((visit) => {
      if (visit.title) goalTitlesSet.add(visit.title);
    });
    const goalTitles = Array.from(goalTitlesSet);

    // Initialize goal counts object
    const goalCounts = {};
    goalTitles.forEach((title) => {
      goalCounts[title] = {};
      monthYearHeaders.forEach((header) => {
        goalCounts[title][header] = 0;
      });
    });

    // Populate goal counts
    visits.forEach((visit) => {
      if (visit.title) {
        const monthYear = `${visit.month}/${visit.year}`;
        if (
          goalCounts[visit.title] &&
          goalCounts[visit.title][monthYear] !== undefined
        ) {
          goalCounts[visit.title][monthYear] += visit.count || 0;
        }
      }
    });

    // Calculate total counts for each goal title
    const totalCounts = {};
    goalTitles.forEach((title) => {
      totalCounts[title] = Object.values(goalCounts[title]).reduce(
        (total, count) => total + count,
        0
      );
    });

    const goalFrequencies = {};
    relevantPlans.forEach((plan) => {
      plan.goals.forEach((goal) => {
        if (!goalFrequencies[goal.title]) {
          goalFrequencies[goal.title] = {};
        }
        const start = new Date(plan.start);
        const end = new Date(plan.end);
        while (start <= end) {
          const monthYear = `${start.getMonth() + 1}/${start.getFullYear()}`;
          goalFrequencies[goal.title][monthYear] = goal.frequency;
          start.setMonth(start.getMonth() + 1);
        }
      });
    });

    // Fill in null values for months where frequency is not available
    goalTitles.forEach((title) => {
      monthYearHeaders.forEach((header) => {
        if (!goalFrequencies[title] || !goalFrequencies[title][header]) {
          if (!goalFrequencies[title]) goalFrequencies[title] = {};
          goalFrequencies[title][header] = null;
        }
      });
    });

    // Calculate total frequencies for each goal title
    const totalFrequencies = {};
    relevantPlans.forEach((plan) => {
      plan.goals.forEach((goal) => {
        totalFrequencies[goal.title] =
          (totalFrequencies[goal.title] || 0) + goal.frequency;
      });
    });

    // Calculate total counts and total frequencies for all months combined
    let totalTotalCount = 0;
    let totalTotalFrequency = 0;
    let totalPercent = 0
    goalTitles.forEach((title) => {
      totalTotalCount += totalCounts[title];
      totalTotalFrequency += totalFrequencies[title];
      totalPercent = totalTotalCount[title]/totalTotalFrequency[title];
    });



    return {
      monthYearHeaders,
      goalTitles,
      goalFrequencies,
      goalCounts,
      totalCounts,
      totalFrequencies,
      totalTotalCount,
      totalTotalFrequency,
      totalPercent
    };
  };

  const renderTable = (tableData) => {
    const {
      monthYearHeaders,
      goalTitles,
      goalCounts,
      goalFrequencies,
      totalCounts,
      totalFrequencies,
      totalTotalCount,
      totalTotalFrequency,
      totalPercent
    } = tableData;

    return (
        <>
            <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Plans</TableCell>
                                {planHeaders}
                            </TableRow>
                            <TableRow>
                                <TableCell>Goal</TableCell>
                                {monthYearHeaders.map((header) => (
                                    <TableCell key={header}>{header}</TableCell>
                                    ))}
                                <TableCell>Totals</TableCell>
                                <TableCell>Percentage</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {goalTitles.map((title) => (
                                <TableRow key={title}>
                                <TableCell>{title}</TableCell>
                                {monthYearHeaders.map((header) => (
                                    <TableCell key={`${title}-${header}`}>
                                    {goalCounts[title][header]} / {goalFrequencies[title][header]}
                                    </TableCell>
                                ))}
                                <TableCell>{totalCounts[title]}</TableCell>
                                <TableCell>{totalFrequencies[title]}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>Totals</TableCell>
                                    {monthYearHeaders.map((header) => (
                                    <TableCell key={`total-${header}`}>
                                        {Object.values(goalCounts).reduce(
                                        (total, counts) => total + counts[header],
                                        0
                                        )}
                                    </TableCell>
                                    ))}
                                    <TableCell>{totalTotalCount} / {totalTotalFrequency}</TableCell>
                                    <TableCell>{`${totalPercent}%`}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            </>
    );
  };

  const tableData = prepareTableData(client, startDate, endDate);

  // Render the table using the tableData
  return (
    <div>
      <Typography variant="h6">{client.name}</Typography>
      {renderTable(tableData)}
    </div>
  );
}

ClientGoalTable.propTypes = {
    client: PropTypes.any, 
    startDate: PropTypes.any, 
    endDate: PropTypes.any
}

export default ClientGoalTable;
