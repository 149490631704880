import React from 'react';
import { useParams } from 'react-router-dom';
import EditContract from './EditContract';
import { DataGrid } from '@mui/x-data-grid'
// import DeleteIcon from '@mui/icons-material/Delete';
// import {Box, IconButton} from "@mui/material";
import { useGetContractsQuery, useDeleteContractMutation } from '../../../redux/apiSlice';
import DeleteAlert from '../../../DeleteAlert';

const ClientContracts = () =>  {

  const {clientId} = useParams();
  
  const [deleteContract] = useDeleteContractMutation();

 
  const {
    data: contracts,
  } = useGetContractsQuery(clientId)
  
  
  // const [hoveredRow, setHoveredRow] = useState(null);

  // const onMouseEnterRow = (event) => {
  //   const _id = String(event.currentTarget.getAttribute("data-id"));
  //   setHoveredRow(_id)
  // }

  // // eslint-disable-next-line no-unused-vars
  // const onMouseLeaveRow = (event) => {
  //   setHoveredRow(null);
  // }

  // const contractId = hoveredRow

  

  // const singleContract = contracts?.filter(contract => contract?._id == contractId);
  
  
const columns = 
   [
  // {field: '_id', headerName: 'ID', width: 80},
  {
    field: 'contStartDate',
    headerName: 'Start Date',
    type: 'date',
    width: 150,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    }
    
  },
  {
    field: 'contEndDate', 
    headerName: 'End Date ', 
    type: 'date', 
    width: 150,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    }
  },
    
  
  {field: 'authNumber', headerName: 'Auth Number', width: 150},
  {field: 'contType', headerName: 'Type', width: 150},
  {field: 'contHours', headerName: 'Total Hours', width: 150},
  {
    field: "actions",
    headerName: "",
    width: 120,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      // if (hoveredRow === params.id) {
        let contractId = params.id
        let singleContract = params.row
        let auth = params.row.authNumber
        return (
          // <Box
          //   sx={{
          //     // backgroundColor: "whitesmoke",
          //     width: "100%",
          //     height: "100%",
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center"
          //   }}
          // >
            <>
            <EditContract contractId={contractId}  singleContract={singleContract}/>
            {/* <IconButton onClick={() => deleteContract({clientId, contractId})}>
              <DeleteIcon />
            </IconButton> */}
            <DeleteAlert name={`Client Contract-${auth}`} id={contractId} hook={deleteContract} softDelete={false} />
            </>
          // </Box>
        );
      // } else return null;
    }
  }
   ]
;



  return (
    <div>
      <h1>Contract List</h1>

        
        <div style={{height: 700, width: '100%'}}>
          <DataGrid 
            getRowId={(row) => row._id}
            rows={contracts ? contracts : []}
            columns={columns}
            rowsPerPageOptions={[20, 50, 100]}
            autoPageSize
            pageSize={12}
        
           />
        </div>
    </div>
  )
}

export default ClientContracts;