import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent,
       DialogContentText, Button, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import SelectAsyncUser from '../../utilities/SelectAsyncUser';
import SelectAsyncClient from '../../utilities/SelectAsyncClient';
import { useAddNewVisitMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import { useAuth } from '../../utilities/useAuth';
import { useDispatch, useSelector } from "react-redux";
import { setScheduleOpenState } from '../../redux/slices/visitSlice';
import PropTypes from 'prop-types';

export default function ScheduleVisit({hasDate, isOpen}) {
  const {id} = useAuth();
  const isUser = id;

  const [addNewVisit] = useAddNewVisitMutation();

  const [open, setOpen] = useState(false);

  const isClose = useSelector(state => state.visit.openScheduler)
  const hasTime = useSelector(state => state.visit.schedulerTime)

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setScheduleOpenState(false))
  };

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if(isClose === false) {
      handleClose()
    }
  }, [isClose])

  useEffect(() => {
    if (hasTime) {
        // reset()
      resetField("visitStart", { defaultValue: hasTime})
      resetField("visitEnd", { defaultValue: hasTime})
    } else {
        reset()
    }
  }, [hasTime])

  const { control, handleSubmit, formState: { errors }, resetField, reset } = useForm({
    defaultValues: {
    client: '',
    user: '',
    location: '',
    visitStart: hasDate,
    visitEnd: hasDate,
    status: 'Scheduled'
    }
  });
  
  console.log("in scheduler: ", hasTime, hasDate)   

  const onSubmit= async (data) => {
    console.log("Data: ", data)

    try {
      // const payload =  
      await addNewVisit({ data, isUser })
      // .unwrap();
      //   console.log('fulfilled', payload);
        toast.success("Visit Scheduled", {
          position: toast.POSITION.TOP_RIGHT
        });
      } catch (err) {
        console.error('Failed to add visit: ', err);
        toast.error("Error Scheduling Visit", {
          position: toast.POSITION.TOP_CENTER
        });
      }
      
      
      handleClose();
            
   }

   const onError = () => {
    alert("Please check the requirements for the form");
    console.log('error');
  } 
     

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Schedule Client Visit
      </Button>
      <Dialog open={open} onClose={handleClose}>
        
        <DialogContent>
          <DialogContentText>
            Complete the following sections to schedule visit.
          </DialogContentText>
          
          <form id="scheduleVisit" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
          
          <section>
            <label>Select User</label>
            <Controller
              control={control}
              name='user'
              errors={errors}
              rules={{ required: true }}
              render={({ field }) => (     
                <SelectAsyncUser 
                  {...field}
                  placeholder='Enter User Name' 
                  handleChange = {(_id  => field.onChange(_id))}
                  value={field.value}
               />  
               )}
           />   
           <br></br>
          {errors.user && "Please Select a User"}
          </section>

          <section>
            <label>Select Client</label>
          <Controller
            control={control}
            name="client"
            errors={errors}
            rules={{ required: true }}
            render={({ field }) => (     
              <SelectAsyncClient 
               {...field}
                placeholder='Enter Client Name' 
                handleChange = {(_id  => field.onChange(_id))}
                value={field.value}
          />  
      )}
          />   
     <br></br>
     {errors.client && "Please Select a Client"}
     </section>
     
     <br></br>
     <section>
       <Controller
            control={control}
            name="location"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                label="Location"
                error={!!errors.location}
                //helperText={error ? error.message : null}
                fullWidth
                onChange={field.onChange}
                value={field.value}
                variant="outlined"
              />
      )}
     />
     <br></br>
     {errors.location && "Please State Location"}
     </section>
     <br></br>

     <section>
      
      <Controller
        control={control}
        name="visitStart"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
         
          label="Visit Start Date/ Time"
          error={!!errors.visitStart}
          value={field.value}
          onChange={(e)=>field.onChange(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.visitStart && "Select Start Date/ Time"}
      </section>
      <br></br>
      <section>
      
      <Controller
        control={control}
        name="visitEnd"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
         
          label="Visit End Date/ Time"
          error={!!errors.visitEnd}
          value={field.value}
          onChange={(e)=>field.onChange(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.visitEnd && "Select End Date/ Time"}
      </section>
      
        </form>
        <br></br>        
                  
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button form="scheduleVisit" type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ScheduleVisit.propTypes = {
  hasDate: PropTypes.any, 
  isOpen: PropTypes.any
}