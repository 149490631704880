import React, {useState, useRef} from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography, Box } from "@mui/material";
import SignaturePad from 'react-signature-canvas';
import PropTypes from 'prop-types';

const ApproveTimecard = ({onSave}) => {

const sigPad = useRef({})

const [open, setOpen] = useState(false)

const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


let sig = ''

function clear(){
    sigPad.current.clear();
}

function save(){
    sig = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
    onSave(sig)
    handleClose();

}

return (
    <div>
        <Button color='success' variant='contained' onClick={handleClickOpen}>Approve</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogContent>
            
      <Typography>Sign Below to Approve:</Typography> <br />
                <Box sx={{border: 1}}>
                    <SignaturePad
                        ref={sigPad}
                        canvasProps={{
                            width: 500,
                            height: 200
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='warning' onClick={clear}>Clear</Button>
                <Button variant='contained' color='primary' onClick={save}>Save and Close</Button>
            </DialogActions>
        </Dialog>
    </div>
)


}

ApproveTimecard.propTypes = {
    onSave: PropTypes.any
}

export default ApproveTimecard