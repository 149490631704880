import React from 'react';
import { Grid, Paper } from '@mui/material';
import ScheduleVisit from '../visitviews/ScheduleVisit';
import ClientVisits from './ClientVisits';

const ClientVisitPanel = () => {
    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ScheduleVisit />
                </Grid>
                <Grid item xs={12}>
                    <ClientVisits />
                </Grid>
            </Grid>
        </Paper>
    );
}

export default ClientVisitPanel;
