import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PropTypes from "prop-types";
import Submit from "./Submit";
import Approve from "./Approve.js";
import Send from "./Send";
import { useAuth } from "../../../../utilities/useAuth";
import { useGetUserName } from "../../../../utilities/useGetUserName";
import Unsubmit from "./Unsubmit";
import Unapprove from "./Unapprove";
import Unsend from "./Unsend";

const UpdateStatus = ({ planId, data }) => {
  const { id } = useAuth();
  const userId = id;
  const user = useGetUserName(id)

  const subDate = new Date(data?.submittedDate).toLocaleDateString("en-US");
  const subUser = data?.submittedBy

  const appDate = new Date(data?.approvedDate).toLocaleDateString("en-US");
  const appUser = data?.approvedBy

  const sendDate = new Date(data?.sentDate).toLocaleDateString("en-US");
  const sendUser = data?.sentBy;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const today = new Date().toISOString();

  const start = new Date(data.startDate).toLocaleDateString();
  const end = new Date(data.endDate).toLocaleDateString();

  return (
    <div>
      <IconButton color="primary" variant="contained" onClick={handleClickOpen}>
        <Tooltip title="Update Report Status">
          <AddTaskIcon color="primary"/>
        </Tooltip>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Report Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Report Info:
            {data.type} <br />
            Start: {start} End: {end}
          </DialogContentText>

          <br />
          {data.submitted ? (
            <>
              <CheckBoxIcon />
              <label>{subUser}</label>
              <br />
              <label>{subDate}</label>
              <br />
              {data.submittedSig && <img src={data.submittedSig} />}
              <br />
              <Unsubmit plan={data} subUser={subUser} subDate={subDate} isUser={userId} />
            </>
          ) : (
            <>
              <CheckBoxOutlineBlankIcon />
              <Submit
                planId={planId}
                plan={data}
                user={user}
                today={today}
                isUser={userId}
              />
            </>
          )}

          <br />
          {data.approved ? (
            <>
              <CheckBoxIcon />
              <label>{appUser}</label>
              <br />
              <label>{appDate}</label>
              <br />
              {data.approvedSig && <img src={data.approvedSig} />}
              <br />
              <Unapprove plan={data} appUser={appUser} appDate={appDate} isUser={userId} />
            </>
          ) : (
            <>
              <CheckBoxOutlineBlankIcon />
              <Approve
                planId={planId}
                user={user}
                today={today}
                isUser={userId}
              />
            </>
          )}
          {/* <Approve planId={planId} plan={data} user={userId} today={today} /> */}
          <br />
          {data.sent ? (
            <>
              <CheckBoxIcon />
              <label>{sendUser}</label>
              <br />
              <label>{sendDate}</label>
              <br />
              {data.sentSig && <img src={data.sentSig} />}
              <br />
              <Unsend plan={data} sendUser={sendUser} sendDate={sendDate} isUser={userId} />
            </>
          ) : (
            <>
              <CheckBoxOutlineBlankIcon />
              <Send
                planId={planId}
                user={user}
                today={today}
                isUser={userId}
              />
            </>
          )}
          <br />
        </DialogContent>
        <DialogActions>
          <Button color="warning" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UpdateStatus.propTypes = {
  data: PropTypes.any,
  planId: PropTypes.any,
};

export default UpdateStatus;
