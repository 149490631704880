import React, { useEffect, useState } from 'react';
import { useGetUsersQuery } from '../redux/apiSlice';
import { SupervisorTab } from './userviews/SupervisorTab';



export default function Teams() {

	const [teams, setTeams] = useState()
	
	const { data, isSuccess } = useGetUsersQuery()

	// let content
	// if (isLoading) {
	// 	content = <h1>Loading...</h1>
	// } else if (isError) {
	// 	content = <h1>Error: {error}</h1>
	// } else if (isSuccess) {
		
	// 	content = <h1>Success</h1>

	// }
	useEffect(()=>{
		

		if(data) {
		const teamInfo = data.filter(e => e.role === "Supervisor")
		// console.log("teaminfo: ", teamInfo)
		setTeams(teamInfo)
		}
	}, [isSuccess])
	

	
	
	return(
	<div>
	<h1>Teams</h1>
	{/* {content} */}
	{teams && teams.map(t => <SupervisorTab supervisor={t} key={t._id} /> )}
	</div>
	)
}