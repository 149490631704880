import React, {useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Grid, TextField, Tooltip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useAuth } from '../../../utilities/useAuth';
import { useEditLogMutation } from '../../../redux/apiSlice';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';


const EditLegalEntry = ({log}) => {
    const {id} = useAuth()
    const isUser = id
    const [editLog] = useEditLogMutation()
    const {clientId} = useParams()
    const logId = log._id

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        reset();
        setOpen(false);
      };


    // console.log("log: ", log)
      const {register, control, handleSubmit, reset} = useForm({
        defaultValues: {
            charge: log.charge,
            lastHearing: log.lastHearing,
            nextHearing: log.nextHearing,
            occuranceDate: log.occuranceDate,
            outcome: log.outcome,
            hasProbation: false,
            closed: false,
            note: log.note,
            court: log.court
        }
      })

      const onSubmit = async (data) => {
        
        const logType = 'legal';        

        try {
            await editLog({logType, clientId, isUser, data, logId })
        } catch (err) {
            console.log('Failed to edit log', err)
            toast.error("Error updating log", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        toast.success("Log Entry Updated", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();

      }

    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <Tooltip title="Edit Log Entry">
                    <EditIcon color='success' />
                </Tooltip>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Legal Log Entry</DialogTitle>
                <DialogContent>
                <form id="editLegalEntry" 
                    onSubmit={(e) =>
                    handleSubmit(onSubmit)(e).catch((e) => {
                        console.log("e", e);
                })}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField name="charge" label='Charge/ Conviction' variant='outlined' {...register('charge')} />
                            <TextField name="court" label='Courthouse' variant='outlined' {...register('court')} />
                            <Controller
                                control={control}
                                name='occuranceDate'
                                defaultValue={log.occuranceDate}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker      
                                        label="Date of Occurance"
                                        // error={!!errors.startDate}
                                        value={field.value}
                                        onChange={(e)=>field.onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                            )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                control={control}
                                defaultValue={log.lastHearing}
                                name='lastHearing'
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker      
                                        label="Date of Last Hearing"
                                        // error={!!errors.startDate}
                                        value={field.value}
                                        onChange={(e)=>field.onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                            )}
                            />
                            <Controller
                                control={control}
                                name='nextHearing'
                                defaultValue={log.nextHearing}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker      
                                        label="Date of Next Hearing"
                                        // error={!!errors.startDate}
                                        value={field.value}
                                        onChange={(e)=>field.onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                            )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="hasProbation"
                                control={control}
                                defaultValue={log.hasProbation}
                                render={({ field }) => (
                                    <FormControlLabel
                                    control={<Checkbox {...field} />}
                                    label="Has Probation?"
                                    />
                                )}
                            />
                            <Controller
                                name="closed"
                                control={control}
                                defaultValue={log.closed}
                                render={({ field }) => (
                                    <FormControlLabel
                                    control={<Checkbox {...field} />}
                                    label="Case Closed?"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField name="note" label='Notes on this case' variant='outlined' {...register('note')} multiline />
                        <   TextField name="outcome" label='Current Outcome' variant='outlined' {...register('outcome')} />
                        </Grid>
                    </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color='warning' onClick={handleClose}>Cancel</Button>
                    <Button form="editLegalEntry" type="submit">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

EditLegalEntry.propTypes = {
    log: PropTypes.any
}

export default EditLegalEntry;