import React, {useState} from 'react';
import { Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';

const ViewLegalLog = ({log}) => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      let probate
      let caseClosed

      if (log.hasProbation) {
        probate = <CheckBox />
      } else if (!log.hasProbation){
        probate = <CheckBoxOutlineBlank />
      }

      if (log.closed) {
        caseClosed = <CheckBox />
      } else if (!log.closed) {
        caseClosed = <CheckBoxOutlineBlank />
      }

    return (
        <>
            <IconButton  onClick={handleClickOpen}>
                <Tooltip title="View Note">
                    <SearchIcon color='tertiary' />
                </Tooltip>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Typography>Charge: {log.charge}</Typography>
                    <Typography>Court: {log.court}</Typography>
                    <Typography>Date Offense Occured: {new Date(log.occuranceDate).toLocaleDateString()}</Typography>
                    <Typography>Last Hearing Date: {new Date(log.lastHearing).toLocaleDateString()}</Typography>
                    <Typography>next Hearing Date: {new Date(log.nextHearing).toLocaleDateString()}</Typography>
                    <Typography>Notes: {log.note}</Typography>
                    <Typography>Outcome: {log.outcome}</Typography>
                    <Typography>Probation? </Typography>{probate && probate}
                    <Typography>Case Closed? </Typography>{caseClosed && caseClosed}
                </DialogContent>
            </Dialog>
        </>

    )
}

ViewLegalLog.propTypes = {
    log: PropTypes.any
}

export default ViewLegalLog;
