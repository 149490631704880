import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSoftDeleteLogMutation } from '../../../redux/apiSlice';
import AddLegalEntry from './AddLegalEntry';
import EditLegalEntry from './EditLegalEntry';
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteAlert from '../../../DeleteAlert';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import ViewLegalLog from './ViewLegalLog';

const LegalLogs = ({list}) => {

    const [data, setData] = useState([])
    const {clientId} = useParams()
    const logType = 'legal'

    useEffect(() => {
        if (list){
            const sorted = [...list].sort((a, b) => new Date(b.moveOut) - new Date(a.moveOut));
            setData(sorted)
        }
    }, [list])
    

    
    const [deleteLog] = useSoftDeleteLogMutation()

    const columns = [
        {
          field: "charge",
          headerName: "Charge",
          width: 150,
        },
        {
          field: 'caseNumber',
          headerName: 'Case #',
          width: 150
        },
        {
            field: "court",
            headerName: "Courthouse",
            width: 150
        },
        {
          field: "occuranceDate",
          headerName: "Occured",
          width: 150,
          valueFormatter: (params) => {
            if(params.value !== null){
                const date = new Date(params.value);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                };
                return date.toLocaleDateString("en-US", options);
              } else if (params.value == null){
                return ''
              }
            }
        },
        {
          field: "lastHearing",
          headerName: "Last Hearing Date",
          width: 150,
          valueFormatter: (params) => {
            if(params.value !== null){
                const date = new Date(params.value);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                };
                return date.toLocaleDateString("en-US", options);
              } else if (params.value == null){
                return ''
              }
            }
        },
        {
          field: "nextHearing",
          headerName: "Next Hearing",
          width: 150,
          valueFormatter: (params) => {
            if(params.value !== null){
                const date = new Date(params.value);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                };
                return date.toLocaleDateString("en-US", options);
              } else if (params.value == null){
                return ''
              }
            }
        },
        {
          field: "outcome",
          headerName: "Current Outcome",
          width: 400
        },
        {
          field: "hasProbation",
          headerName: "Probation?",
          width: 85,
          renderCell: (params) => {
            // console.log(params)
            if(params.value === true) {
              return <CheckBox />
            } else {
                return <CheckBoxOutlineBlank />
            }
          }
        },
        {
          field: "closed",
          headerName: "Closed?",
          width: 80,
          renderCell: (params) => {
            console.log(params)
            if(params.value === true) {
                return <CheckBox />
            } else {
             return <CheckBoxOutlineBlank />
            }
          }
        },
        {
          field: "actions",
          headerName: "",
          width: 100,
          renderCell: (params) => {
            let logId = params.id;
            return (
              <>
                <ViewLegalLog log={params.row} />
                <EditLegalEntry log={params.row} />
                <DeleteAlert clientId={clientId} logType={logType} name={`Client Legal Log- ${params.row.charge}`} id={logId} hook={deleteLog} softDelete={true} />
              </>
            );
          },
        },
      ];
    

    return (
        <div>
            <AddLegalEntry />
           <Box sx={{ display: "flex" }}>
              {data && (
                <DataGrid
                  autoHeight
                  density="compact"
                  getRowId={(row) => row._id}
                  rows={data}
                  columns={columns}
                  rowsPerPageOptions={[20, 50, 100]}
                  sx={{ height: "100%", width: "100%", backgroundColor: 'white' }}
                />
              )}
            </Box>
        </div>
    )
}

LegalLogs.propTypes = {
    list: PropTypes.any
}

export default LegalLogs;