import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
// import { Document, Page, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import PropTypes from 'prop-types';

const ViewDoc = ({doc, title, name}) => {
    // console.log("title: ", title, "name: ", name)
    
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
      };
    

  return (
    <div>
        <IconButton onClick={handleClickOpen} color='success'>
            <SearchIcon /> 
        </IconButton>
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: "75%", height: "100%"}}}>
            <DialogTitle >
                <Box sx={{ display:'flex', justifyContent: 'space-between'}}>
                <span display="flex" style={{justifyContent:'start'}}>
                    {title} 
                </span>
                <span display="flex" style={{justifyContent:'end'}}>
                    {name}
                </span>
                </Box>
            </DialogTitle>
            <DialogContent style={{ display: "flex", flexGrow: 1, flexDirection: "column"}}>
                <Box sx={{ display: "flex", width: "100%", height: "100%"}}>
                    <embed height='auto' src={doc} type="application/pdf" width="100%"></embed>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color='warning' onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

ViewDoc.propTypes = {
    doc: PropTypes.any,
    title: PropTypes.any,
    name: PropTypes.any
}

export default ViewDoc