import React from 'react';
import AddCLient from './clientviews/AddClient';
import ClientListTabs from './clientviews/chartviews/ClientListTabs';



function Clients() {
return (
 <div>
 
 <AddCLient />
  
  <ClientListTabs />

  </div>
)
}


export default Clients;

