import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


const AddHealthEntry = ({log, addEntry}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        reset();
      };

      const { control, reset, handleSubmit} = useForm();

      const onSubmit = (data) => {
        console.log("data: ", data)
        addEntry(data);
        handleClose();
      };
    


    return (
        <div>
            <Button startIcon={<AddIcon />} onClick={handleClickOpen}>Add Entry</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Entry</DialogTitle>
                <DialogContent>
                <form id="addHealthEntry" onSubmit={handleSubmit(onSubmit)}>
                
              {log?.fields && log.fields.map((field, index) => (
                <Controller
                  key={index}
                  name={field.toLowerCase()}
                  control={control}
                  render={({ field: controllerField }) => (
                    field.toLowerCase().includes('date') ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        {...controllerField}
                        label={field}
                        defaultValue={new Date().toLocaleString()}
                        // inputFormat="MM/dd/yyyy"
                        renderInput={(params) => (
                          <TextField {...params} fullWidth margin="normal" required />
                        )}
                      />
                      </LocalizationProvider>
                    ) : (
                      <TextField
                        {...controllerField}
                        label={field}
                        fullWidth
                        margin="normal"
                      />
                    )
                  )}
                />
              ))}
            
            </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='warning'>Cancel</Button>
                    <Button type='submit' form="addHealthEntry">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

AddHealthEntry.propTypes = {
    log: PropTypes.any,
    addEntry: PropTypes.func
}

export default AddHealthEntry;