import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './redux/store';
import { BrowserRouter } from 'react-router-dom'; 
// import { ThemeProvider, CssBaseline } from '@mui/material';
// import {appTheme} from './MuiTheme'
// import { LicenseInfo } from '@mui/x-license-pro'

// LicenseInfo.setLicenseKey('2484003d6b4577670049491dbca6d66fTz00NTA0NyxFPTE2ODU4Mjc1NDM4NjcsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');


ReactDOM.render(
  <Provider store={store}>
    {/* <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme /> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </ThemeProvider> */}
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
