import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    entryHours: {},
    visitHours: {},
    isVisitsLoading: null,
    isVisitsSuccess: null,
}

export const timechartSlice = createSlice({
    initialState,
    name: 'timechart',
    reducers: {
        setEntryHours: (state, action) => {
            () => state.push(action.payload)
        },
        setVisitHours: (state, action) => {
            state.visitHours = action.payload.visitHours
        },
        setVisitData: (state, action) => {
            state.isVisitsLoading = action.payload.isVisitsLoading,
            state.isVisitsSuccess = action.payload.isVisitsSuccess
        },
    }
});

export default timechartSlice.reducer;

export const { setEntryHours, setVisitHours, setVisitData } = timechartSlice.actions;

export const selectEntryHours = (state) => state.entryHours

export const selectVisitHours = (state) => state.visitHours

export const selectVisitData = (state) => {state.isVisitsLoading, state.isVisitsSuccess}