import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEditLogMutation } from '../../../redux/apiSlice';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../utilities/useAuth';
import EditIcon from '@mui/icons-material/Edit';


const EditBldPrsrEntry = ({entry, logId}) => {
    const [open, setOpen] = useState(false);

    const [editEntry] = useEditLogMutation();
    const {clientId} = useParams();
    const {id,} = useAuth();
    const isUser = id;
    const entryId = entry._id;
    const logType = 'health';

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        reset();
      };

      const { control, reset, register, handleSubmit} = useForm({
        defaultValues: {
            date: entry.date,
            systolic: entry.data.systolic,
            diastolic: entry.data.diastolic,
            pulse: entry.data.pulse,
            note: entry.data.note
        }
      });

      const onSubmit = (data) => {
        // console.log("data: ", data)

        editEntry({logId, clientId, isUser, data, logType, entryId})
        handleClose();
      };
    


    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <Tooltip title='Edit Log Entry'>
                    <EditIcon color='success' />
                </Tooltip>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Entry</DialogTitle>
                <DialogContent>
                <form id="editBldPrsrEntry" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        {...field}
                                        label='Date'
                                        value={field.value}
                                        onChange={(e)=>field.onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                                    
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Typography variant="h6" align="center">Systolic</Typography>
                            <Controller
                                name="systolic"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant="h4" align="center">/</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Typography variant="h6" align="center">Diastolic</Typography>
                            <Controller
                                name="diastolic"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="pulse"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Pulse"
                                        variant="outlined"
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="note"
                                {...register('note')}
                            />
                        </Grid>
                    </Grid>
            </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='warning'>Cancel</Button>
                    <Button type='submit' form="editBldPrsrEntry">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

EditBldPrsrEntry.propTypes = {
    logId: PropTypes.any,
    entry: PropTypes.any
}

export default EditBldPrsrEntry;