import React from 'react';
import { Card, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import AddressTable from './AddressTable';
import PropTypes from 'prop-types';
import PhoneTable from './PhoneTable';
import AddAddressEntry from './AddAddressEntry';
import AddPhoneEntry from './AddPhoneEntry';


const HistoryLogs = ({addressLogs, phoneLogs}) => {

    return (
        <div>
            <Paper>
                <Grid conatiner spacing={2}>
                    <Grid item>
                        <Card>
                            <Stack sx={{justifyContent : 'space-between'}} direction={'row'}>
                                <Typography variant='h4'>Address History: </Typography>
                                <AddAddressEntry />
                            </Stack>
                             <br />
                            {addressLogs && 
                                <AddressTable list={addressLogs} />
                            }
                        </Card>
                    </Grid>
                    <br />
                    <Divider />
                    <br />
                    <Grid item>
                        <Card>
                            <Stack sx={{justifyContent : 'space-between'}} direction={'row'}>
                                <Typography variant='h4'>Phone History: </Typography>
                                <AddPhoneEntry />
                            </Stack>
                            <br />
                            {phoneLogs && 
                                <PhoneTable list={phoneLogs} />
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

HistoryLogs.propTypes = {
    addressLogs: PropTypes.any,
    phoneLogs: PropTypes.any
}

export default HistoryLogs