import React from 'react';
import { Paper, Box } from '@mui/material';
import PropTypes from 'prop-types';


const VisitHours = ({ visits }) => {

    
    
    const visitsHours = visits.map(i => i.totalHours).reduce((a,b)=>a+b, 0);
   

    return (
        <>
            <Paper>
                <Box>
                    
                    {visits &&
                        <div>
                            <h3>Visit Hours: {visitsHours}</h3>
                            
                        </div>
                    }

                </Box>
            </Paper>
        </>
    )


}

VisitHours.propTypes = {
    visits: PropTypes.any,
    chartCallback: PropTypes.func
}

export default VisitHours;