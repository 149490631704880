import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify'


const DeleteAlert = (props) => {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
    const {name, id, hook, softDelete, clientId, logType} = props
    let isHardDelete
    

    if (softDelete) {
        isHardDelete = <Typography>This item will be soft deleted and can be recovered from the audit log</Typography>
    } else if (!softDelete) {
        isHardDelete = <Typography>This item will be permanently deleted and cannot be recovered.</Typography>
    }

    // console.log(id)

    const handleDelete = async () => {

        // console.log('hook', hook, "id: ", id)
        if(clientId && logType){
            await hook({id, clientId, logType})
        } else {
            await hook(id);
        }
        

        toast.success(`${name} Deleted`, {
                position: toast.POSITION.TOP_RIGHT,
              });
        handleClose();
    }

  return (
    <div>
        <IconButton onClick={handleClickOpen}>
            <Tooltip title={`Delete ${name}`}>
                <DeleteIcon color='error'/>
            </Tooltip>
        </IconButton>
        <Dialog 
            open={open} 
            onClose={handleClose}
            PaperProps={{ sx: { border: 2, borderColor: 'error' }}}    
        >
            <DialogTitle sx={{ border: 1, borderColor: 'error'}}>Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete {name}?</DialogContentText> <br />
                {isHardDelete}
            </DialogContent>
            <DialogActions>
                <Button color='error' variant='contained' onClick={handleDelete}>Delete</Button>
                <Button color='warning' variant='contained' onClick={handleClose}>Safely Close</Button>
            </DialogActions>

        </Dialog>
    </div>
  )
}

DeleteAlert.propTypes = {
    props: PropTypes.object,
    name: PropTypes.any,
    id: PropTypes.any,
    hook: PropTypes.any,
    softDelete: PropTypes.any,
    clientId: PropTypes.any,
    logType: PropTypes.any
}

export default DeleteAlert