/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import { useAddClientToUserMutation } from '../../redux/apiSlice';
import PropTypes from 'prop-types';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import MultiSelectClient from '../../utilities/MultiSelectClient';

const AssignClients = ({userId}) => {

    // console.log("assign clients id: ", userId)
    const [setClients] = useAddClientToUserMutation();
    const [primary, setPrimary] = useState(false);
    

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };



    const { register, control, handleSubmit } = useForm({
        
      });

    
      const onSubmit= async (data) => {
        let final = {
          clients: [],
          primary: false
        }

        // console.log('formdata', data)
        if(primary) {
          let result = data.clients.map(i=> i.value)
          final.clients = result
          final.primary= true
        } else if (!primary){
          let result = data.clients.map(i=> i.value)
          final.clients = result
        }
        
        // console.log('postdata: ', final)
                       
        try {
          // const payload =  
          await setClients({ userId, final })
          // .unwrap();
            // console.log('fulfilled', payload)
          } catch (err) {
            console.error('Failed to assign client: ', err)
          }

          setPrimary(false);
          handleClose();
          
       }
      
      
  return (
    <div>
        <Button variant='contained' color='primary' onClick={handleClickOpen}>
            Assign Clients
        </Button>
        <Dialog open={open} onClose={handleClose} sx={{ height: 'auto' }}>
          {/* <Box sx={{ height: 3/4 }}> */}
            <DialogTitle>Add Clients to User's Caseload</DialogTitle>
            <DialogContent>
            <form id="assignClients" 
              onSubmit={(e) =>
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log("e", e);
              })}>
                  <label>Select Clients</label>
              <Controller
                control={control}
                name="clients"
                render={({ field }) => (
            
              <MultiSelectClient  
              // {...field}
              ref={field.ref}
                placeholder='Enter Client Name'
                handleChange = {((e)  => field.onChange(e))}
                onChange={(e)=>field.onChange(e)}
                value={field.value}
                theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'blue',
                    primary: 'black'

                }
            })}
          />  
          )}
          />
          <Checkbox
            {...register('primary')}
            value={primary}
            onChange={() => setPrimary(!primary)}
            /><label>Set User as Primary for these clients?</label>
              </form>
            </DialogContent>
            <DialogActions>
                <Button color='warning' variant='contained' onClick={handleClose}>
                    Close
                </Button>
                <Button color='primary' variant='contained' form='assignClients' type='submit'>
                    Submit
                </Button>
            </DialogActions>
            {/* </Box> */}
        </Dialog>
    </div>
  )
}

AssignClients.propTypes = {
    userId: PropTypes.any,
}

export default AssignClients;