import React from 'react';
import {useForm} from 'react-hook-form';
import { useYupValidationResolver } from '../../../utilities/useYupValidationResolver';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useAddGoalMutation } from '../../../redux/apiSlice'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useAuth } from '../../../utilities/useAuth';


const validationSchema = yup.object({
  title: yup
    .string("Title for Goal")
    .required('Required'),
  dataCollection: yup
    .string("How is data collected?"),
  staffRole: yup
    .string("The role of the staff"),
  frequency: yup
    .string("How many times per month will the goal be addressed??")
    .required('Required'),
  location: yup
    .string("What setting is this goal addressed in?"),
  projectedHours: yup
    .string("How many hours per month are anticipated for this goal?"),
  materials: yup
    .string("What materials are needed towards goal completion?"),
  currentStatus: yup
    .string("What is currently happening towards this goal? Progress? Barriers?")
    .required('Required'),
  quote: yup
    .string("Client's statement as to benefit of the goal"),
  measurement: yup
    .string("Statement of how the goal is measured and for how long"),
  plan: yup
    .string("Steps of staff towards goal completion")
  
});

const AddGoal = ({planId}) => {
  const {id} = useAuth();
  const isUser = id;
  
  const [addNewGoal] = useAddGoalMutation()


  const [open, setOpen] = React.useState(false);  


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const resolver = useYupValidationResolver(validationSchema)

  const { register, handleSubmit, reset, formState: {errors} } = useForm({
    resolver,
    defaultValues: {
      title: '',
      dataCollection: '',
      staffRole: '',
      frequency: '',
      location: '',
      projectedHours: '',
      materials: '',
      currentStatus: '',
      quote: '',
      measurement: '',
      plan: ''
    }
  })

  const onSubmit = async (data) => {
    try {
      await addNewGoal({ planId, data, isUser })
      // .unwrap()
      // .then((payload) => console.log('fulfilled', payload))
      // .catch((error) => console.error('rejected', error.status, error.data));
        
      } catch (err) {
        console.error('Failed to save goal: ', err)
        toast.error("Error Adding Goal", {
          position: toast.POSITION.TOP_CENTER
        });
      }

      toast.success("Goal Added", {
        position: toast.POSITION.TOP_RIGHT
      });
      handleClose();
  }
   
  return (
  <div>
    <Button variant="contained" onClick={handleClickOpen}>
      Add Goal
    </Button>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Goal</DialogTitle>
      <DialogContent>
  
 
      <form id="addGoal" 
              onSubmit={(e) =>
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log("e", e);
              })}>
        <TextField
          name="title"
          label="Title/ Name of Goal"
          placeholder=''
          {...register('title')}
        /> <br />
        {errors.title && "Goal needs a title"} <br />
        <TextField         
          name="dataCollection"
          label="Data Collection"
          placeholder=''
          {...register('dataCollection')}
        /> 
        <TextField
          name="staffRole"
          label="Staff Role"
          placeholder=""
          {...register('staffRole')}
        /> 
        <TextField         
          name="frequency"
          label="Times/Month"
          placeholder=""
          {...register('frequency')}
        /> <br />
        {errors.frequency && 'Frequency is required for the system to track progress'} <br />
        <TextField                  
          name="location"
          label="Location"
          placeholder=""
          {...register('location')}
        />
        <TextField         
          name="projectedHours"
          label="Projected Hours"
          placeholder=""
          {...register('projectedHours')}
        />
        <TextField         
          name="materials"
          label="Materials Used"
          placeholder=""
          {...register('materials')}
        />
        <TextField         
          name="currentStatus"
          label="Current Status"
          placeholder=""
          multiline
          rows={6}
          {...register('currentStatus')}
        /> <br />
        {errors.currentStatus && "Status is required"}
        <TextField         
          name="quote"
          label="Client Statement"
          placeholder=""
          {...register('quote')}
        />
        <TextField         
          name="measurement"
          label="Statement of Measurement"
          placeholder=""
          multiline
          rows={2}
          {...register('measurement')}
        />
        <TextField         
          name="plan"
          label="Plan"
          placeholder=""
          multiline
          rows={4}
          {...register('plan')}
        />
        <DialogActions>
          <Button color='warning' onClick={handleClose}>Cancel</Button>
          <Button form='addGoal' type="submit">
          Submit
        </Button>
      </DialogActions>
      </form>
      </DialogContent>
      
    </Dialog>
    </div>
  );
};

AddGoal.propTypes = {
    planId: PropTypes.any
}

export default AddGoal;
