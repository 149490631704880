import React from 'react';
import { useGetReportsToApproveQuery } from '../../redux/apiSlice';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import ApprovePlan from './ApprovePlan';

const SubmittedPlans = () => {
    const {data, isLoading, isSuccess, refetch} = useGetReportsToApproveQuery({},{ refetchOnMountOrArgChange: true });

    const columns = [
        {
            field: '_id',
            headerName: 'id',
            width: 80
        },
        {
            field: 'client',
            headerName: 'Client',
            width: 150,
            renderCell: (params) => {
                return (
                <Link to={`/ClientChart/${params?.value?._id}`}>{params?.value?.fullName}</Link>
            )}
        },
        {field: 'type', headerName: 'Type', width: 150},
        {
            field: 'submittedDate',
            headerName: 'Submit Date',
            width: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                };
                return date.toLocaleDateString("en-US", options);
              }
        },
        {
            field: 'submittedBy',
            headerName: 'Submit User',
            width: 150,
        },
        {
            field: 'actions',
            headerName: "",
            width: 80,
            renderCell: (params) => {
                return (
                    <ApprovePlan planId={params.row._id} clientId={params.row.client._id} subUser={params.row.submittedBy} refetch={refetch} />
                )
            }
        }
    ]

    let content

if (isLoading) {
  content = <CircularProgress />
} else if (isSuccess) {
  content = 
  <div style={{ display: 'flex', height: '100%', width: '100%'}}>
    <Box sx={{ flexGrow: 1 }}>
  { data &&
    <DataGrid 
      autoHeight
      autoWidth
      getRowId={(row) => row._id}
      rows={data}
      columns={columns}
      rowsPerPageOptions={[20, 50, 100]}
      components={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            All Reports Approved
          </Stack>
        )
      }}
      initialState={{ columns: { columnVisibilityModel: { _id: false } }}}
    
 />}
 </Box>
  </div>

}

  return (
    <div>
        <Typography>Reports Needing Approval</Typography> <br />
        {content}
    </div>
  )
}

export default SubmittedPlans