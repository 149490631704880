import React from 'react';
import { Paper, Box } from '@mui/material';
import PropTypes from 'prop-types';
import EditMiles from './EditMiles';
import { useDeleteMilesMutation } from '../../redux/apiSlice';
import './Timecard.css'
import DeleteAlert from '../../DeleteAlert';

const MilesBlockStdDdct = ({ mile, bracket, stdDdct }) => {
    const milesId = mile._id;
    const applyDdct = mile.useStdDdct
    const appliedStd = ` | - ${stdDdct}`

    const [deleteMiles] = useDeleteMilesMutation();
    
    return (
        <>
            <Paper>
                <Box>
                         <div >
                                <h3>Source: {mile?.source}</h3>
                                <h3>Destination: {mile?.destination}</h3>
                                <span className='mileStd' ><h3>Distance: {mile?.distance}</h3> {applyDdct && <h3>{appliedStd}</h3>}</span>
                                
                        </div>
                        <div className='icons'>
                                <EditMiles mile={mile} bracket={bracket} stdDdct={stdDdct} />
                                <DeleteAlert name={'Miles'} id={milesId} hook={deleteMiles} softDelete={false} />
                        </div>
                </Box>
            </Paper>
        </>
    )


}

MilesBlockStdDdct.propTypes = {
    mile: PropTypes.any,
    bracket: PropTypes.any,
    stdDdct: PropTypes.any
}

export default MilesBlockStdDdct;