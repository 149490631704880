import React from 'react';
import {Button, TextField, MenuItem, Dialog, DialogTitle,
        DialogActions, DialogContent, DialogContentText, IconButton, Tooltip } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useEditClientReportMutation } from '../../../redux/apiSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useAuth } from '../../../utilities/useAuth';



const options = [
  { label: 'Report Type', value: '.' },
  { label: 'Annual', value: 'Annual' },
  { label: 'Semi Annual', value: 'Semi Annual' }
]

const EditServicePlan = ({singleReport, planId}) => {
  const {id} = useAuth();
  const isUser = id;
  const [editServicePlan] = useEditClientReportMutation();

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
    reportDate: singleReport?.reportDate,
    startDate: singleReport?.startDate,
    endDate: singleReport?.endDate,
    type: singleReport?.type
    }
  });
  
 
 
 const onSubmit= async (data) => {

  console.log('data', data)
                 
  try {
    const payload =  await editServicePlan({ planId, data, isUser }).unwrap();
      console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to update service plan: ', err)
    }

    toast.success("Client Report Updated", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
 }

 const onError = () => {
   toast.error("Please check the requirements for the form", {
    position: toast.POSITION.TOP_CENTER
   });
   console.log('error');
 } 
    

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Tooltip title="Edit Plan Dates">
          <EditIcon color='secondary'/>
        </Tooltip>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Service Plan</DialogTitle>
        <DialogContent>

        <DialogContentText>
            Provide the following information to edit the Service Plan.
        </DialogContentText>
    
        
          <form id="editServicePlan" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
                 <section>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <TextField 
                            select
                            error={!!errors.type}
                            label="Report Type"
                            sx={{ minWidth: 500}}
                            onChange={field.onChange}
                            value={field.value}
                            variant= 'outlined'
                            >
                            {generateSingleOptions()}
                            </TextField>
                        )}
                    
                        rules = {{ required: true }}
                    />
                <br></br>
                {errors.type && "Select Contract Type"}
                </section>
                <section>
      
                    <Controller
                        control={control}
                        name="reportDate"
                        
                
                        render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                            
                            label="Client Report Date"
                            error={!!errors.dateOfReport}
                            value={field.value}
                            onChange={(e)=>field.onChange(e)}
                            renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        )}
                    />
                <br></br>
                {errors.dateOfReport && "Select Report Date"}
                </section>
                <br></br>
          
                <section>
      
                        <Controller
                            control={control}
                            name="startDate"
                            
                    
                            render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                
                                label="Report Start Date"
                                error={!!errors.startDate}
                                value={field.value}
                                onChange={(e)=>field.onChange(e)}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            )}
                        />
                <br></br>
                {errors.startDate && "Select Start Date"}
                </section>
                <br></br>
                <section>
                
                        <Controller
                            control={control}
                            name="endDate"
                            render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                
                                label="Report End Date"
                                error={!!errors.endDate}
                                value={field.value}
                                onChange={(e)=>field.onChange(e)}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            )}
                        />
                <br></br>
                {errors.endDate && "Select End Date"}
                </section>
                <br></br>
        </form>
        <br></br>    
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" form="editServicePlan" type="submit">
          Submit
           </Button>
        </DialogActions>
        </Dialog>
        
        </div>
    )}

EditServicePlan.propTypes = {
    singleReport: PropTypes.any,
    planId: PropTypes.any
}

export default EditServicePlan;
