import React from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  useGetAllAllergiesQuery,
  useDeleteAllergyMutation,
} from "../../../redux/apiSlice";
import {
  CircularProgress,
  Box,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import AddAllergy from "./AddAllergy"
import EditAllergy from "./EditAllergy";
import DeleteAlert from "../../../DeleteAlert";

const AllergiesList = () => {
  const { clientId } = useParams();

  const { data, isLoading, isSuccess } = useGetAllAllergiesQuery(clientId);

  const [deleteAllergy] = useDeleteAllergyMutation();

  const columns = [
    {
      field: "source",
      headerName: "Source",
      width: 150,
    },
    {
      field: "severity",
      headerName: "Severity",
      width: 150,
    },
    {
      field: "reaction",
      headerName: "Reaction",
      width: 450,
    },
    {
      field: "needTreatment",
      headerName: "Needs Treatment?",
      width: 80,
      renderCell: (params) => (
        params.value 
          ? <label>Yes</label>
          : <label>No</label>
      ),
    },
    {
      field: "treatment",
      headerName: "Treatment",
      width: 450
    },
    {
      field: "actions",
      headerName: "",
      width: 80,
      renderCell: (params) => {
        let allergyId = params.id;
        return (
          <>
            <EditAllergy allergyId={allergyId} data={params.row} />
            <DeleteAlert name={`Client Allergy- ${params.row.source}`} id={allergyId} hook={deleteAllergy} softDelete={false} />
          </>
        );
      },
    },
  ];

  let content;

  if (isLoading) {
    content = <CircularProgress />;
  } else if (isSuccess) {
    content = (
      <div>
        <Box sx={{ display: "flex" }}>
          {data && (
            <DataGrid
              autoHeight
              density="compact"
              getRowId={(row) => row._id}
              rows={data}
              columns={columns}
              rowsPerPageOptions={[20, 50, 100]}
              sx={{ height: "100%", width: "100%" }}
            />
          )}
        </Box>
      </div>
    );
  }

  return (
    <div>
      <Box 
        component="span"
        sx={{ display: 'block', mx: '2px', my: '10px', px: '2px', py: '10px', height: '100%', width: '100%' }}
      >
        <Card>
            <CardHeader
                title={'Allergies'}
                action={
                    <AddAllergy />
                }
            />
          <CardContent>
            <div>{content}</div>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default AllergiesList;
