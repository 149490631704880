import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from '../../utilities/useYupValidationResolver';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useEditMilesMutation } from '../../redux/apiSlice'
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, IconButton, TextField, Input, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';


const validationSchema = yup.object({
  source: yup
    .string("Left From")
    .required('Required'),
  destination: yup
    .string("Went to")
    .required('Required'),
  distance: yup
    .string('How Far')
    .required('Required'),
  
});

const EditMiles = ({ mile, bracket, stdDdct }) => {
  const bracketId = bracket._id
  const milesId = mile._id

  const hasStdDdct = useSelector(state => state.settings?.useStdDdct)
  
  const [editMiles] = useEditMilesMutation()

  const [open, setOpen] = useState(false);
  const [ useStd, setUseStd ] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {register, formState: { errors }, handleSubmit, watch, reset, setValue} = useForm({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: {
      source: mile.source,
      destination: mile.destination,
      distance: mile.distance,
      useStdDdct: mile.useStdDdct,
      adjusted: mile.adjusted 
    }
  })

  useEffect(() => {
    setUseStd(mile?.useStdDdct)
    setValue('useStdDdct', mile?.useStdDdct)
  }, [mile?.useStdDdct, setValue])

  const watchSource = watch('source')
  const watchDest = watch('destination')

  useEffect(() => {

    // console.log("watch: ", watchSource, watchDest)
    if (watchSource === 'home' || watchSource === 'Home' || watchSource === 'HOME' 
      || watchDest === 'home' || watchDest === 'Home' || watchDest === 'HOME'
    ){
      // console.log('in cond: ', watchSource, watchDest)
      setUseStd(true)
      setValue('useStdDdct', true)
    }
  }, [watchSource, watchDest]);

  const onSubmit = async (data) => {
    if (useStd) {
      let value = data.distance - stdDdct
      data.adjusted = Math.max(0, value)
    } else {
      data.adjusted = data.distance
    }

    // console.log(data);
    
    try {
    // const payload =  
    await editMiles({ bracketId, milesId, data })
    // .unwrap();
    //   console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save miles: ', err)
      toast.error("Error Adding Miles", {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    toast.success("Miles Added", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
    
  }

  const onError = (errors) => {
    toast.error("Please check the requirements for the form", {
     position: toast.POSITION.TOP_CENTER
    });
    console.log(`error: ${errors}`);
  };
   
  return (
  <div>
    <IconButton onClick={handleClickOpen}>
      <EditIcon color="success" />
      
    </IconButton>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Miles</DialogTitle>
      <DialogContent>

      <form
        id="editMiles"
        onSubmit= {(e) =>
          handleSubmit(
            onSubmit,
            onError
          )(e).catch((e) => {
            console.log("e", e);
          })
        }
        >
  
  <TextField
          fullWidth
          name="source"
          label="Source"
          placeholder='Home'
          {...register('source')}
          error={!!errors.source}
        /> <br />
        {errors.source && 'Starting Point'}
        <TextField
          fullWidth
          name="destination"
          label="Destination"
          placeholder='Client home'
          {...register('destination')}
          error={!!errors.destination}
        /> <br />
        {errors.destination && 'Ending Point'}
        <Input
          type='number'
          fullWidth
          name="distance"
          label="Distance"
          placeholder="5"
          {...register('distance')}
          error={!!errors.distance}
        /> <br />
        {errors.distance && 'Provide a distance'}
        {hasStdDdct &&
        <>
          <Checkbox
            value={useStd}
            checked={useStd}
            onClick={() => setUseStd(!useStd)}
            id="useStdDdct"
            name='useStdDdct'
            {...register('useStdDdct')}  
          />
          <label>To/From Home?</label>
        </>
        }
        </form>
         <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" fullWidth form='editMiles' type="submit">
          Submit
        </Button>
       </DialogActions> 
      </DialogContent>
     
    </Dialog>
    </div>
  );
};

EditMiles.propTypes = {
    bracket: PropTypes.any,
    mile: PropTypes.any,
    stdDdct: PropTypes.any
}


export default EditMiles;
