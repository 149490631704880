import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEditLogMutation } from '../../../redux/apiSlice';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../utilities/useAuth';
import EditIcon from '@mui/icons-material/Edit';

const EditHealthEntry = ({entry, logId}) => {
    const [open, setOpen] = useState(false);

    const [editEntry] = useEditLogMutation();
    const {clientId} = useParams();
    const {id} = useAuth();
    const isUser = id;
    const logType = 'health';

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const defaultValues = entry?.data ? Object.fromEntries(Object.keys(entry.data).map(key => [key.toLowerCase(), entry.data[key]])) : {};

    const { control, reset, handleSubmit } = useForm({
        defaultValues
    });

    const onSubmit = (data) => {
        editEntry({clientId, logId, isUser, logType, data, entryId: entry._id});
        handleClose();
    };

    useEffect(() => {
        if (entry?.data) {
            reset(defaultValues);
        }
    }, [entry, reset, defaultValues]);

    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <Tooltip title='Edit Log Entry'>
                    <EditIcon color='success' />
                </Tooltip>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Entry</DialogTitle>
                <DialogContent>
                    <form id="editHealthEntry" onSubmit={handleSubmit(onSubmit)}>
                        {entry?.data && Object.keys(entry.data).map((field, index) => (
                            <Controller
                                key={index}
                                name={field.toLowerCase()}
                                control={control}
                                render={({ field: controllerField }) => (
                                    field.toLowerCase().includes('date') ? (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                {...controllerField}
                                                label={field}
                                                inputFormat="MM/dd/yyyy"
                                                renderInput={(params) => (
                                                    <TextField {...params} fullWidth margin="normal" required />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    ) : (
                                        <TextField
                                            {...controllerField}
                                            label={field}
                                            fullWidth
                                            margin="normal"
                                        />
                                    )
                                )}
                            />
                        ))}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='warning'>Cancel</Button>
                    <Button type='submit' form="editHealthEntry">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

EditHealthEntry.propTypes = {
    entry: PropTypes.object.isRequired,
    logId: PropTypes.string.isRequired
}

export default EditHealthEntry;
