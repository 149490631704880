import React, { useEffect, useState } from 'react';
import { Table, TableCell, TableContainer, TableHead, TableBody, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import DeleteAlert from '../../../DeleteAlert';
import EditBldPrsrEntry from './EditBldPrsrEntry';
import { useSoftDeleteLogMutation } from '../../../redux/apiSlice';
import EditHealthEntry from './EditHealthEntry';
import { useAuth } from '../../../utilities/useAuth';
import { useParams } from 'react-router-dom';

const HealthLogsTable = ({ log }) => {
    const {admin, supAdmin} = useAuth();
    const [hasEntries, setHasEntries] = useState([]);
    const [logReady, setLogReady] = useState(false);
    const logType = 'health';
    const logId = log._id;
    const {clientId} = useParams();
    const [softDelete] = useSoftDeleteLogMutation();

    useEffect(() => {
        if (log.entries) {
            setHasEntries(log.entries);
        }
    }, [log.entries]);

    useEffect(() => {
        if (log.fields) {
            setLogReady(true);
        }
    }, [log.fields]);

    function hasDateField(fields) {
        if (!fields || fields.length === 0) {
            return false;
        } else {
            return fields.some(f => f.toLowerCase() === 'date');
        }
    }

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function transformFields(fields) {
        if (!fields || fields.length === 0) {
            return [];
        }

        const transformedFields = fields.map(field => capitalizeFirstLetter(field));

        const dateIndex = transformedFields.findIndex(field => field.toLowerCase() === 'date');

        if (dateIndex !== -1) {
            const dateField = transformedFields.splice(dateIndex, 1)[0];
            transformedFields.unshift(dateField);
        }

        return transformedFields;
    }

    let headers;

    if (logReady && log?.type === 'Blood Pressure') {
        headers = [
            <TableCell key='date'>Date:</TableCell>,
            <TableCell key='time'>Time:</TableCell>,
            <TableCell key='pressures'>Systolic/Diastolic:</TableCell>,
            <TableCell key='pulse'>Pulse:</TableCell>,
            <TableCell key='note'>Note:</TableCell>,
            <TableCell key='actions'>Actions:</TableCell>
        ];
    } else if (logReady && hasDateField(log.fields)) {
        const adjust = transformFields(log.fields);

        headers = [
            <TableCell key='date'>Date:</TableCell>,
            <TableCell key='time'>Time:</TableCell>,
            ...adjust
                .filter((x) => x.toLowerCase() !== 'date') // Ensure 'date' is not added again
                .map((x, idx) => (
                    <TableCell key={idx}>{`${x}:`}</TableCell>
                )),
            <TableCell key='actions'>Actions:</TableCell>
        ];
    } else if (logReady) {
        headers = [
            <TableCell key='date'>Date:</TableCell>,
            <TableCell key='time'>Time:</TableCell>,
            ...log.fields.map((x, idx) => (
                <TableCell key={idx}>{`${x}:`}</TableCell>
            )),
            <TableCell key='actions'>Actions:</TableCell>
        ];
    }

    const sortEntries = (entries) => {
        return entries.toSorted((a, b) => new Date(a.date) - new Date(b.date));
    };

    const groupEntriesByDate = (entries) => {
        return entries.reduce((acc, entry) => {
            const date = new Date(entry.date).toLocaleDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(entry);
            return acc;
        }, {});
    };

    let rows;

    if (logReady && hasEntries.length) {
        const sortedEntries = sortEntries(hasEntries);
        const groupedEntries = groupEntriesByDate(sortedEntries);

        rows = Object.keys(groupedEntries).map((date, dateIdx) => (
            <React.Fragment key={dateIdx}>
                {groupedEntries[date].map((entry, idx) => (
                    <TableRow key={idx}>
                        {idx === 0 ? <TableCell rowSpan={groupedEntries[date].length}>{date}</TableCell> : null}
                        <TableCell>{new Date(entry.date).toLocaleTimeString()}</TableCell>
                        {log.type === 'Blood Pressure' ? (
                            <>
                                <TableCell>{entry.data.systolic} / {entry.data.diastolic}</TableCell>
                                <TableCell>{entry.data.pulse}</TableCell>
                                <TableCell>{entry.data.note}</TableCell>
                                <TableCell>
                                    <EditBldPrsrEntry entry={entry} logId={log._id} />
                                    {admin || supAdmin && <DeleteAlert entryId={entry._id} logType={logType} clientId={clientId} name={`Client Health Entry- ${new Date(entry.date).toLocaleString()}`} id={entry._id} hook={softDelete} softDelete={true} />}
                                </TableCell>
                            </>
                        ) : (
                            <>
                                {headers.slice(2, -1).map((header, idx) => {
                                    const headerText = header.props.children.toLowerCase().replace(':', '');
                                    const cellValue = entry.data[headerText];
                                    return (
                                        <TableCell key={idx}>
                                            {cellValue}
                                        </TableCell>
                                    );
                                })}
                                <TableCell>
                                    <EditHealthEntry logId={logId} entry={entry} />
                                    {admin || supAdmin && <DeleteAlert entryId={entry._id} logType={logType} clientId={clientId} name={`Client Health Entry- ${new Date(entry.date).toLocaleString()}`} id={logId} hook={softDelete} softDelete={true} />}
                                </TableCell>
                            </>
                        )}
                    </TableRow>
                ))}
            </React.Fragment>
        ));
    } else {
        rows = (
            <TableRow>
                <TableCell>No Entries yet</TableCell>
            </TableRow>
        );
    }

    return (
        <div>
            <TableContainer>
                <Table size='small' aria-label={`${log.type} logs`}>
                    <TableHead>
                        <TableRow>
                            {headers && headers}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

HealthLogsTable.propTypes = {
    log: PropTypes.any,
};

export default HealthLogsTable;
