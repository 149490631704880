import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom';


const DeleteUser = (props) => {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
    const {name, userId, hook, softDelete, isUser} = props
    let isHardDelete
    

    if (softDelete) {
        isHardDelete = <Typography>This item will be soft deleted and can be recovered from the audit log</Typography>
    } else if (!softDelete) {
        isHardDelete = <Typography>This item will be permanently deleted and cannot be recovered.</Typography>
    }

    // console.log(id)
    const navigate = useNavigate();

    const handleDelete = () => {

        // console.log('hook', hook, "id: ", id)
        hook({userId, isUser});

        toast.success(`${name} Deleted`, {
                position: toast.POSITION.TOP_RIGHT,
              });
        navigate('/UsersTab')              
        
    }

  return (
    <div>
        <Button startIcon={<DeleteIcon />} color='error' variant='outlined' onClick={handleClickOpen}>
          Delete User 
        </Button>
        <Dialog 
            open={open} 
            onClose={handleClose}
            PaperProps={{ sx: { border: 2, borderColor: 'error' }}}    
        >
            <DialogTitle sx={{ border: 1, borderColor: 'error'}}>Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete {name}?</DialogContentText> <br />
                {isHardDelete}
            </DialogContent>
            <DialogActions>
                <Button color='error' variant='contained' onClick={handleDelete}>Delete</Button>
                <Button color='warning' variant='contained' onClick={handleClose}>Safely Close</Button>
            </DialogActions>

        </Dialog>
    </div>
  )
}

DeleteUser.propTypes = {
    props: PropTypes.object,
    name: PropTypes.any,
    userId: PropTypes.any,
    hook: PropTypes.any,
    softDelete: PropTypes.any,
    isUser: PropTypes.any
}

export default DeleteUser