import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useAddNewLogMutation } from '../../../redux/apiSlice';


const AddEntryFromVisit = ({log, clientId, hasDate, addLogSet, isUser}) => {
    const [openEntry, setOpenEntry] = useState(false);
    const [addVisitEntry] = useAddNewLogMutation();

    const logType = 'health';
    const logId = log._id;

    const handleOpenEntry = () => {
        setOpenEntry(true);
      };
    
      const handleEntryClose = () => {
        setOpenEntry(false);
        reset();
      };

      const [selectedTime, setSelectedTime] = useState(null);

        const onTimeChange = (time) => {
            setSelectedTime(time);
        };

        const combineDateAndTime = (selectedTime) => {
            const date = new Date(hasDate);
            date.setHours(selectedTime.getHours());
            date.setMinutes(selectedTime.getMinutes());
            date.setSeconds(selectedTime.getSeconds());
            return date.toISOString();
        };

      const { control, reset, handleSubmit} = useForm();

      const onEntrySubmit = (data) => {
        const isoDate = combineDateAndTime(selectedTime);
        const logData = { ...data, date: isoDate };
        console.log("data: ", data)
        addVisitEntry({data: logData, clientId, logId, logType, isUser });

        const logVisit = {
            fields: data,
            type: log.type,
            time: isoDate,
        }
        console.log("logVisit: ", logVisit)
        addLogSet(logVisit)
        handleEntryClose();
      };
    


    return (
        <div>
            <Button startIcon={<AddIcon />} onClick={handleOpenEntry}>Add {log.type} Entry</Button>
            <Dialog open={openEntry} onClose={handleEntryClose}>
                <DialogTitle>Add New Entry</DialogTitle>
                <DialogContent>
                <form id="addHealthEntry" onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return handleSubmit(onEntrySubmit)(e)
                }}>
                
              {log?.fields && log.fields.map((field, index) => (
                <Controller
                  key={index}
                  name={field.toLowerCase()}
                  control={control}
                  render={({ field: controllerField }) => (
                    field.toLowerCase().includes('date') ? (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        {...controllerField}
                        label="Select Time"
                        value={selectedTime}
                        onChange={onTimeChange}
                        renderInput={(params) => <TextField {...params} margin="normal" />}
                        />
                      </LocalizationProvider>
                    ) : (
                      <TextField
                        {...controllerField}
                        label={field}
                        fullWidth
                        margin="normal"
                      />
                    )
                  )}
                />
              ))}
            
            </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEntryClose} color='warning'>Cancel</Button>
                    <Button type='submit' form="addHealthEntry">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

AddEntryFromVisit.propTypes = {
    log: PropTypes.any,
    clientId: PropTypes.any, 
    hasDate: PropTypes.any,
    isUser: PropTypes.any,
    addLogSet: PropTypes.func
}

export default AddEntryFromVisit;