import React, {useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useAuth } from '../../../utilities/useAuth';
import { useAddNewLogMutation } from '../../../redux/apiSlice';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


const AddLegalEntry = () => {
    const {id} = useAuth()
    const isUser = id
    const [addLog] = useAddNewLogMutation()
    const {clientId} = useParams()

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        reset();
        setOpen(false);
      };

      const {register, control, handleSubmit, reset} = useForm({
        defaultValues: {
            charge: '',
            lastHearing: '',
            nextHearing: '',
            occuranceDate: '',
            outcome: '',
            hasProbation: false,
            closed: false,
            note: '',
            caseNumber: '',
        }
      })

      const onSubmit = async (data) => {
        
        const logType = 'legal';        

        try {
            await addLog({logType, clientId, isUser, data })
        } catch (err) {
            console.log('Failed to add log', err)
            toast.error("Error adding log", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        toast.success("Log Entry Added", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();

      }

    return (
        <div>
            <Button onClick={handleClickOpen} startIcon={<AddIcon />}>Add New Entry</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Legal Log Entry</DialogTitle>
                <DialogContent>
                <form id="addLegalEntry" 
                    onSubmit={(e) =>
                    handleSubmit(onSubmit)(e).catch((e) => {
                        console.log("e", e);
                })}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField label='Charge/ Conviction' variant='outlined' {...register('charge')} />
                            <TextField label='Case #' variant='outlined' {...register('caseNumber')} />
                            <TextField label='Courthouse' variant='outlined' {...register('court')} />
                            <Controller
                                control={control}
                                name='occuranceDate'
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker      
                                        label="Date of Occurance"
                                        // error={!!errors.startDate}
                                        value={field.value}
                                        onChange={(e)=>field.onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                            )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                control={control}
                                name='lastHearing'
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker      
                                        label="Date of Last Hearing"
                                        // error={!!errors.startDate}
                                        value={field.value}
                                        onChange={(e)=>field.onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                            )}
                            />
                            <Controller
                                control={control}
                                name='nextHearing'
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker      
                                        label="Date of Next Hearing"
                                        // error={!!errors.startDate}
                                        value={field.value}
                                        onChange={(e)=>field.onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                            )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="hasProbation"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <FormControlLabel
                                    control={<Checkbox {...field} />}
                                    label="Has Probation?"
                                    />
                                )}
                            />
                            <Controller
                                name="closed"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <FormControlLabel
                                    control={<Checkbox {...field} />}
                                    label="Case Closed?"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField label='Notes on this case' variant='outlined' {...register('note')} multiline />
                        <   TextField label='Current Outcome' variant='outlined' {...register('outcome')} />
                        </Grid>
                    </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color='warning' onClick={handleClose}>Cancel</Button>
                    <Button form="addLegalEntry" type="submit">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

export default AddLegalEntry;