import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


const AddBldPrsrEntry = ({addEntry}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        reset();
      };

      const { control, reset, register, handleSubmit} = useForm();

      const onSubmit = (data) => {
        console.log("data: ", data)
        addEntry(data);
        handleClose();
      };
    
    

    return (
        <div>
            <Button startIcon={<AddIcon />} onClick={handleClickOpen}>Add Entry</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Entry</DialogTitle>
                <DialogContent>
                <form id="addBldPrsrEntry" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Controller
                                name="date"
                                defaultValue={new Date().toISOString()}
                                control={control}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        {...field}
                                        label='Date'
                                        value={field.value}
                                        onChange={(e)=>field.onChange(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                                    
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Typography variant="h6" align="center">Systolic</Typography>
                            <Controller
                                name="systolic"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant="h4" align="center">/</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Typography variant="h6" align="center">Diastolic</Typography>
                            <Controller
                                name="diastolic"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="pulse"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Pulse"
                                        variant="outlined"
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="note"
                                {...register('note')}
                            />
                        </Grid>
                    </Grid>
            </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='warning'>Cancel</Button>
                    <Button type='submit' form="addBldPrsrEntry">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

AddBldPrsrEntry.propTypes = {
    log: PropTypes.any,
    addEntry: PropTypes.func
}

export default AddBldPrsrEntry;