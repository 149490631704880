import React, { useEffect, useState } from 'react';
import { useGetTeamClientsQuery } from '../../redux/apiSlice';
import Grid from '@mui/material/Unstable_Grid2';
import {Box, Card, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const Row = ({data}) => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell align='left' style={{width:'50%'}}>
          <IconButton>
            <Link to={`/ClientChart/${data._id}`}><AccountCircleIcon /></Link>
          </IconButton>
          {data.fullName}</TableCell>
        <TableCell style={{width:'35%'}}>{data.phone}</TableCell>
        <TableCell align='right' style={{width:'15%'}}></TableCell>
      </TableRow>
    </React.Fragment>
  )
}
Row.propTypes = {
  data: PropTypes.obj
}

const Display = ({data}) => {
// console.log("Display data: ", data)

  return (
    <div>
    <Grid xs={12} item>
    <Grid container spacing={2} direction='row'>
      
      <Card sx={{ display: 'inline-flex', flexGrow: 1, overflow: 'auto' }}>
            <Grid item xs={4} >
            <Box sx={{ overflow: 'auto'}}>
            <Typography>
              <IconButton>
                <Link to={`/UserProfile/${data?._id}`}><AccountCircleIcon /></Link>
              </IconButton>
              {data?.fullName}</Typography> <br />
              <Typography>{data?.phone}</Typography> <br />
              <Typography>{data?.email}</Typography> <br />
              <Typography>{data?.role}</Typography>
            </Box>
            </Grid>
            <Grid item xs={8} sx={{ flexGrow: 1 }}>
            
              <Table style={{ width: "auto", tableLayout: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{width:'50%'}}>Name</TableCell>
                  <TableCell style={{width:'35%'}}>Phone</TableCell>
                  <TableCell style={{width:'15%'}}>Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.Clients.map((i, idx) => (
                  <Row data={i} key={idx} />
                ))}
              </TableBody>
          </Table>
            
            </Grid>           
      </Card>
    </Grid>
    </Grid>
    </div>
  )
}

Display.propTypes = {
  data: PropTypes.any
}

const SupCaseloadList = ({superId}) => {
  // console.log("supId: ", superId)
    const {data} = useGetTeamClientsQuery(superId)
    const [info, setInfo] = useState()

    useEffect(() => {
      if (data) {
        setInfo(data[0])
      }
    }, [data])


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Paper sx={{backgroundColor: '#ECF1F7'}}>
          <Grid item>
            <Display data={info} />
          </Grid>
            
          

          {info && info.supTeam.map((i, idx) => {
            return (
              <Display data={i} key={idx} />
            )
          })}
        </Paper>
      </Grid>
    </Box>
  )
}

SupCaseloadList.propTypes = {
  superId: PropTypes.any
}

export default SupCaseloadList