import React, { useContext } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import PersInfoTabs from './PersInfoTabs';
import ContractsTab from '../contracts/ContractsTab';
import { ClientContext } from '../ClientContext';
import ClientAttendance from './ClientAttendance';
import ClientReportsTab from '../ispviews/ClientReportsTab';
import ServicePlan from '../ispviews/ServicePlan';
import ProgNotesTab from '../ProgNotesTab';
import ClientGoalReport from './ClientGoalReport';
import ExternalDocs from '../documentviews/ExternalDocs';
import InternalDocs from '../documentviews/InternalDocs';
import ConsentDocs from '../documentviews/ConsentDocs';
import { Card, Grid, Paper, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import LogTabs from './LogTabs';
import PropTypes from 'prop-types';
import ClientVisitPanel from '../ClientVisitPanel';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function ChartNav({ isMdScreen }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const { client } = useContext(ClientContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleSelectChange = (event) => {
    setValue(event.target.value);
  };

  const tabs = [
    { label: "Personal Info", component: <PersInfoTabs /> },
    { label: "Contracts", component: <ContractsTab client={client} /> },
    { label: "Consent Forms", component: <ConsentDocs /> },
    { label: "SDRC Docs", component: <ExternalDocs /> },
    { label: "Client Docs", component: <InternalDocs /> },
    { label: "Logs", component: <LogTabs /> },
    {
      label: "Overview",
      component: (
        <div display='flex'>
          <Paper sx={{ backgroundColor: '#ECF1F7', height: '100%', width: '100%' }}>
            <Grid container direction='column' spacing={3}>
              <Grid item>
                <Card sx={{ m: 2, p: 2 }}>
                  <Typography variant='h3'>Attendance Report</Typography>
                  <ClientAttendance client={client} />
                </Card>
              </Grid>
              <Grid item>
                <Card sx={{ m: 2, p: 2 }}>
                  <Typography variant='h3'>Goal Attainment Report</Typography>
                  <ClientGoalReport />
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </div>
      ),
    },
    { label: "Reports", component: <ClientReportsTab /> },
    { label: "Service Plan", component: <ServicePlan /> },
    { label: "Visits", component: <ClientVisitPanel />},
    { label: "Progress Notes", component: <ProgNotesTab /> },
  ];

  return (
    <TabContext value={value}>
      <Box sx={{ bgcolor: 'background.paper', width: 1500 }}>
        {isMdScreen ? (
          <FormControl>
            <InputLabel id="select-tab-label">Select Tab</InputLabel>
            <Select
              labelId="select-tab-label"
              value={value}
              onChange={handleSelectChange}
            >
              {tabs.map((tab, index) => {
                console.log("tab: ", tab.label );
                return (
                <MenuItem key={index} value={index}>
                  {tab.label}
                </MenuItem>
                )
            })}
            </Select>
          </FormControl>
        ) : (
          <AppBar position="static">
            <TabList
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              aria-label="full width tabs example"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} {...a11yProps(index)} />
              ))}
            </TabList>
          </AppBar>
        )}
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {tabs.map((tab, index) => (
            <TabPanel key={index} value={value} index={index} dir={theme.direction}>
              {tab.component}
            </TabPanel>
          ))}
        </SwipeableViews>
      </Box>
    </TabContext>
  );
}

ChartNav.propTypes = {
  isMdScreen: PropTypes.any
}