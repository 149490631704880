import { useEffect, useState } from 'react';
import { useGetUserQuery } from '../redux/apiSlice';

const useGetUserName = (userId) => {

    const [name, setName] = useState()
    const {data, isSuccess} = useGetUserQuery(userId)

    useEffect(() => {
        if(isSuccess) {
            const fullName = data.fullName
            setName(fullName)
        }

    }, [isSuccess])
    

    return( name )

}

export {useGetUserName}