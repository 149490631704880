import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    timecardSearch: {
        options: {
            dateRange: [],
            byRange: false,
            setBySubmit: false,
            userId: [],
        },
        trigger: false,
        searchAll: false,
    },
    auditLogSearch:  {
        options: {
            dateRange: [],
            userId: [],
        },
        trigger: false,
        searchAll: false,
    }

    
    
}

export const adminSlice = createSlice({
    initialState,
    name: 'admin',
    reducers: {
        setTimecardSearch: (state, action) => {
            state.timecardSearch.options.dateRange = action.payload.dateRange,
            state.timecardSearch.options.bySubmit = action.payload.bySubmit,
            state.timecardSearch.options.byRange = action.payload.byRange,
            state.timecardSearch.options.userId = action.payload.userId,
            state.timecardSearch.trigger = true,
            state.timecardSearch.searchAll = false
        },
        setGetAllTimecards: (state) => {
            state.timecardSearch.searchAll = true,
            state.timecardSearch.trigger = false
        },
        resetSearch: (state) => {
            state.timecardSearch.options.dateRange = [],
            state.timecardSearch.options.bySubmit = false,
            state.timecardSearch.options.byRange = false,
            state.timecardSearch.options.userId = [],
            state.timecardSearch.searchAll = false,
            state.timecardSearch.trigger = false
        },
        setAuditSearch: (state, action) => {
            state.auditLogSearch.options.dateRange = action.payload.dateRange,
            state.auditLogSearch.options.userId = action.payload.userId,
            state.auditLogSearch.trigger = true,
            state.auditLogSearch.searchAll = false
        },
        setGetAllAudits: (state) => {
            state.auditLogSearch.searchAll = true,
            state.auditLogSearch.trigger = false
        },
        resetAuditSearch: (state) => {
            state.auditLogSearch.options.dateRange = [],
            state.auditLogSearch.options.userId = [],
            state.auditLogSearch.searchAll = false,
            state.auditLogSearch.trigger = false
        }

        
    }
});

export default adminSlice.reducer;

export const { setTimecardSearch, setGetAllTimecards, resetSearch, setAuditSearch, setGetAllAudits, resetAuditSearch } = adminSlice.actions;

// export const selectClockInfo = (state) => state?.user?.clock

