import React, { useEffect } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useYupValidationResolver } from "../../../utilities/useYupValidationResolver";
import { TextField, Button, Grid, Tooltip } from '@mui/material';
import { setPlanGoals, setAllowStep } from '../../../redux/slices/stepperSlice';
import { useDispatch } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';

const validationSchema = yup.object({
    title: yup
      .string("Title for Goal")
      .required('Required'),
    dataCollection: yup
      .string("How is data collected?"),
    staffRole: yup
      .string("The role of the staff"),
    frequency: yup
      .string("How many times per month will the goal be addressed?")
      .required('Required'),
    location: yup
      .string("What setting is this goal addressed in?"),
    projectedHours: yup
      .string("How many hours per month are anticipated for this goal?"),
    materials: yup
      .string("What materials are needed towards goal completion?"),
    currentStatus: yup
      .string("What is currently happening towards this goal? Progress? Barriers?")
      .required('Required'),
    quote: yup
      .string("Client's statement as to benefit of the goal"),
    measurement: yup
      .string("Statement of how the goal is measured and for how long"),
    plan: yup
      .string("Steps of staff towards goal completion")
    
  });

const StepGoal = ({goal, allowStep}) => {
    // const planId= useSelector(state => state.client.client.newPlanId)

    // const [addGoal] = useAddGoalMutation()

    const dispatch = useDispatch()

    const resolver = useYupValidationResolver(validationSchema)

    useEffect(() => {
      if(!allowStep){
        dispatch(setAllowStep(true))
      }
    }, [])

    console.log("StepGoal goalinfo", goal)

    const {
        register,
        handleSubmit,
    } = useForm({
        resolver,
        defaultValues: {
            title: goal?.title,
            dataCollection: goal?.dataCollection,
            staffRole: goal?.staffRole,
            frequency: goal?.frequency,
            location: goal?.location,
            projectedHours: goal?.projectedHours,
            materials: goal?.materials,
            currentStatus: '',
            quote: '',
            measurement: goal?.measurement,
            plan: goal?.plan
          },
      });
    

    const setGoals = (data) => {
      dispatch(setAllowStep(false))
      dispatch(setPlanGoals(data))
    }
      const onSubmit = async (data) => {
        console.log("payload", data)

        setGoals(data)
      };
    
 

  return (
    <div>
        <br />

        <form
            id="addGoal"
            onSubmit={(e) =>
              handleSubmit(
                onSubmit,
              )(e).catch((e) => {
                console.log("e", e);
              })
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={2}>
                  <TextField
                    id="title"
                    name="title"
                    label="Title/ Name of Goal"
                    {...register('title', {required: true})}
                >
                    {goal.title}
                </TextField>
              
                <TextField         
                  id="dataCollection"
                  name="dataCollection"
                  label="Data Collection"
                  {...register('dataCollection', {required: true})}
                />
              
                <TextField
                  id="staffRole"
                  name="staffRole"
                  label="Staff Role"
                  {...register('staffRole', {required: true})}
                />
              
                  <TextField         
                    id="location"
                    name="location"
                    label="Location"
                    {...register('location', {required: true})}
                  />
              
              <TextField         
          id="materials"
          name="materials"
          label="Materials Used"
          {...register('materials', {required: true})}
          />
              
              <TextField         
          id="frequency"
          name="frequency"
          label="Times/Month"
          {...register('frequency', {required: true})}
          />
          
          <TextField         
          id="projectedHours"
          name="projectedHours"
          label="Projected Hours"
          {...register('projectedHours', {required: true})}
          />
              </Grid>

              <Grid item xs={5}>
              <TextField         
          id="currentStatus"
          name="currentStatus"
          label="Current Status"
          multiline
          fullWidth
          {...register('currentStatus', {required: true})}
          />
              </Grid>
              <Grid item xs={3}>
              <TextField         
          id="quote"
          name="quote"
          label="Client Statement"
          {...register('quote', {required: true})}
          />
             
              <TextField         
          id="measurement"
          name="measurement"
          label="Statement of Measurement"
          {...register('measurement', {required: true})}
          />
              
              <Tooltip title="If you set the plan as a numbered list (1...., 2..., 3...) the system can display it best">
                <InfoIcon />
              </Tooltip>
              <TextField         
          id="plan"
          name="plan"
          label="Plan"
          multiline
          {...register('plan', {required: true})}
          />
              </Grid>
            </Grid>
          
          
          
        <Button color="primary" variant="contained" form="addGoal" fullWidth type="submit" onClick={() => dispatch(setAllowStep(false))}>
          Update Goal
        </Button>
          </form>
    </div>
  )
}

StepGoal.propTypes = {
    goal: PropTypes.any,
    planId: PropTypes.any,
    allowStep: PropTypes.any
}

export default StepGoal