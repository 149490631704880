import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const appTheme = (width) => {
  let theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#086EE8',
      },
      secondary: {
        main: '#4ab379',
      },
      tertiary: {
        main: '#7E57C2'
      },
      error: {
        main: '#e82525',
      },
      info: {
        main: '#83c9ef',
      },
      white: {
        main: '#FFFAFA'
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          a: {
            color: 'blue',
            textDecoration: 'none',
            '&:visited': {
              color: 'blue',
            },
          },
        },
      },
  
      MuiGrid: {
        styleOverrides: {
          container: {
            width: `${width}px`
          }
        }
      },
     
      MuiButton: {
          defaultProps: {
              variant: 'contained',
              color: 'primary'
          }
      },
  }
  });
  
  theme = responsiveFontSizes(theme);

  return theme;
}


export default appTheme;