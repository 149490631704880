import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Input,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useUpdateReportStatusMutation } from "../../../../redux/apiSlice";
import { toast } from "react-toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SignaturePad from "react-signature-canvas";

const Send = ({ planId, user, today, isUser }) => {
  const sigPad = useRef();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function clear() {
    sigPad.current.clear();
  }

  function formatToPng() {
    if (sigPad.current) {
      // console.log(sigPad.current)
      const dataUrl = sigPad.current.getTrimmedCanvas().toDataURL();
      return dataUrl;
    }
  }

  const [updateStatus] = useUpdateReportStatusMutation();

  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      sent: false,
      sentBy: "",
      sentDate: "",
      sentSig: { data: "", contentType: "image/png" },
    },
  });

  const onSubmit = async (data) => {
    // console.log("onSubmit: ", data);

    try {
      // const payload = 
      await updateStatus({ planId, data, isUser }).unwrap();
      // console.log("fulfilled", payload);
    } catch (err) {
      console.error("Failed to update report status: ", err);
    }

    toast.success("Report Status Updated", {
      position: toast.POSITION.TOP_RIGHT,
    });

    handleClose();
  };

  const handleSendValues = () => {
    setValue("sent", true);
    setValue("sentBy", user);
    setValue("sentDate", today);
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleClickOpen}>
        Send
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>Complete below to mark as Sent</DialogContentText>
          <form
            id="submitForm"
            onSubmit={(e) => [
              handleSendValues(),
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log("e", e);
              }),
            ]}
          >
            <CheckBoxOutlineBlankIcon
              {...register("sent")}
              {...register("sentBy")}
              {...register("sentDate")}
            />
            <Typography>Report send to:</Typography><br />
            <Input 
                {...register("sentTo")}
            /><br />
            <br />
            <Typography>Email/ Mail address sent to:</Typography>
            <Input 
                {...register("sentAddr")}
            />
            <br />
            <br />
            <Typography>Sign below to mark as sent</Typography>
            <Box
              sx={{
                width: 500,
                height: 200,
                border: 1,
              }}
            >
              <Controller
                name="sentSig.data"
                control={control}
                render={({ field }) => (
                  <SignaturePad
                    ref={sigPad}
                    canvasProps={{
                      width: 500,
                      height: 200,
                    }}
                    onEnd={() => field.onChange(formatToPng())}
                  />
                )}
              />
            </Box>
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={clear}
            >
              Clear
            </Button>
          </form>

          <Button
            variant="contained"
            color="primary"
            form="submitForm"
            type="submit"
          >
            Save and Mark Sent
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

Send.propTypes = {
  planId: PropTypes.any,
  plan: PropTypes.any,
  user: PropTypes.any,
  today: PropTypes.any,
  isUser: PropTypes.any
};

export default Send;
