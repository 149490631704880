import React, { useContext } from 'react';
// import { PDFViewer } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { ClientContext } from '../ClientContext';
import ViewPlan from './ViewPlan';

const StepReview = () => {
    const {client} = useContext(ClientContext)
    const plan = useSelector(state => state.stepper.plan)

    let content
    if(!plan) {
      content = <h1>Loading...</h1>
    } else if (plan) {
      content =  <ViewPlan client={client} plan={plan} />
    }
  return (
    <>
        
            {content}
        
    </>
  )
}

export default StepReview