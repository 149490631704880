import React from 'react';

const ClientContext = React.createContext();

function ClientProvider (props) {

    const [client, setClient ]= React.useState()
    
    const value = [client, setClient]
    return <ClientContext.Provider value={value} {...props} />

}

export { ClientContext, ClientProvider }