import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSoftDeleteLogMutation } from '../../../redux/apiSlice';
import DeleteAlert from '../../../DeleteAlert';
import { useParams } from 'react-router-dom';

const AddressTable = ({list}) => {

    console.log(list)

    const [hasList, setHasList] = useState([])

    useEffect(() => {
        if(list.length > 0){
            const sorted = [...list].sort((a, b) => new Date(b.moveOut) - new Date(a.moveOut));

            setHasList(sorted)
        }
    }, [list])

    const [delAddress] = useSoftDeleteLogMutation()
    const {clientId} = useParams()
    const logType = 'address'
    

    const addressList = hasList?.map((a, idx) => (  
        <TableRow key={idx}>
            <TableCell>{new Date(a?.moveIn).toLocaleDateString()}</TableCell>
            <TableCell>{new Date(a?.moveOut).toLocaleDateString()}</TableCell>
            <TableCell>{a?.value}</TableCell> 
            <TableCell><DeleteAlert logType={logType} clientId={clientId} name={`Client Address Log- ${a.value}`} id={a._id} hook={delAddress} softDelete={true} /> </TableCell>
        </TableRow>
        ))

    let content

    if(addressList.length > 0) {
        content = addressList
    } else {
        content = <TableRow>
            <TableCell>No data</TableCell>
            <TableCell>No data </TableCell>
            <TableCell>No data</TableCell>
            <TableCell></TableCell>
        </TableRow>
    }
    

    return (
        <div>
            <TableContainer component={Paper}>
                <Table size='small' aria-label='Address History'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Move In</TableCell>
                            <TableCell>Move Out</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {content}
                    </TableBody>
                </Table>
            </TableContainer>
            
        </div>
    )
}

AddressTable.propTypes = {
    list: PropTypes.any
}

export default AddressTable;