import React, {useState, useContext, useRef} from 'react';
import {IconButton, Dialog, DialogTitle, DialogContent, Tooltip, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ViewPlan from './ViewPlan';
import { ClientContext } from '../ClientContext';
import PropTypes from 'prop-types';
import { useGetClientReportQuery } from '../../../redux/apiSlice';
import ReactToPrint from 'react-to-print';

const ReviewPlan = ({planId}) => {
    // console.log("review plan id: ", planId)
    const [open, setOpen] = useState(false);

    const printRef = useRef();

    const {client} = useContext(ClientContext)

    const {data: plan, isSuccess, isLoading } = useGetClientReportQuery(planId)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let content
  if(isLoading) {
    content = <h1>Loading...</h1>
  } else if (isSuccess) {
    content =  <ViewPlan client={client} plan={plan} />
  }
 
  const printButton = <><span style={{float: 'right'}}><ReactToPrint trigger={() => <Button variant='contained' color='primary'>Print</Button>} content={()=> printRef.current} /></span></>

  return (
    <div>
        <IconButton onClick={handleClickOpen}>
          <Tooltip title="View Plan">
            <SearchIcon color='tertiary' />
          </Tooltip>
        </IconButton>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Review Service Plan {printButton}</DialogTitle>
            <DialogContent>
              <div id='print' ref={printRef}>
                {content}
              </div>
        </DialogContent>
        </Dialog>
    </div>
  )
}

ReviewPlan.propTypes = {
    planId: PropTypes.any
}

export default ReviewPlan