import React, {useRef, useState} from 'react'
import { useAuth } from '../../utilities/useAuth'
import { Dialog, DialogContent, DialogTitle, Divider, Button, IconButton, Tooltip, Typography } from '@mui/material';
import AddTaskIcon from "@mui/icons-material/AddTask";
import CloseIcon from '@mui/icons-material/Close';
import Approve from '../clientviews/ispviews/statusUpdate/Approve';
import Send from '../clientviews/ispviews/statusUpdate/Send';
import ViewPlan from '../clientviews/ispviews/ViewPlan';
import { useGetClientQuery, useGetClientReportQuery } from '../../redux/apiSlice';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import { useGetUserName } from '../../utilities/useGetUserName';

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.any,
    onClose: PropTypes.any
  }

const ApprovePlan = ({planId, subUser, clientId, refetch}) => {
    const {id} = useAuth();
    const isUser = id;
    const user = useGetUserName(id);

    const printRef = useRef();

    const [approveOpen, setApproveOpen] = useState(false);
    const [approved, setApproved] = useState(false);

    const {data: plan} = useGetClientReportQuery(planId)

    // console.log(clientId)
    const {data: client} = useGetClientQuery(clientId)

    const today = new Date().toISOString();

    const handleClickOpen = () => {
        setApproveOpen(true);
      };
    
      const handleClose = () => {
        refetch();
        setApproveOpen(false);
      };

  return (
    <div>
        <IconButton color='success' onClick={handleClickOpen}>
            <Tooltip title='Approve Report'>
                <AddTaskIcon />
            </Tooltip>
        </IconButton>
        <Dialog open={approveOpen} onClose={handleClose}>
            <BootstrapDialogTitle onClose={handleClose}>Review/ Approve Client Report</BootstrapDialogTitle>
            <DialogContent>
                    <Grid container direction='row' spacing={2} sx={{ flexGrow: 1, justifyContent: 'space-evenly' }}>
                        <Grid item>
                            <Typography>Client:</Typography> <br />
                            <Typography>{client?.fullName}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Submit User:</Typography> <br />
                            <Typography>{subUser}</Typography>    
                        </Grid>
                        <Grid item>
                            <Typography>Submitted Date:</Typography> <br />
                            <Typography>{new Date(plan?.submittedDate).toLocaleDateString()}</Typography>
                        </Grid>
                        <Grid item>
                            <Divider orientation='vertical' />
                        </Grid>
                        <Grid item>
                            {!approved  ? (
                            <Approve isUser={isUser} user={user} planId={planId} today={today} setApproved={setApproved} />
                            ): (
                                <>
                                    <Send isUser={isUser} user={user} planId={planId} today={today} />
                                    <ReactToPrint trigger={() => <Button variant='contained' color='secondary'>Print</Button>} content={()=> printRef.current} />
                                </>
                            )}
                        </Grid>
                    </Grid>
                <Divider />
                <div id='print' ref={printRef}>
                   {client && <ViewPlan client={client} plan={plan} /> }
                </div>
                
            </DialogContent>
        </Dialog>

    </div>
  )
}

ApprovePlan.propTypes = {
    planId: PropTypes.string,
    subUser: PropTypes.string,
    refetch: PropTypes.func,
    clientId: PropTypes.any
}

export default ApprovePlan