import React, {useEffect, useState} from 'react';
import { Paper, Tooltip, Typography, Grid, Card, Box, Divider, Stack } from '@mui/material';
import { useGetAgencySettingsQuery, useGetAgencyStatsQuery } from '../redux/apiSlice';
import AddSettings from './AddSettings';
import EditSettings from './EditSettings';
import AgencyConsents from './clientviews/documentviews/AgencyConsents';
import AddConsentForm from './clientviews/documentviews/AddConsentForm';
import { useDispatch, useSelector } from 'react-redux';
import { setAgencyDetails } from '../redux/slices/settingsSlice';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InfoIcon from '@mui/icons-material/Info';
import { useAuth } from '../utilities/useAuth';


export default function Settings() {

	const {role} = useAuth();

	const {data, isSuccess} = useGetAgencySettingsQuery();
	const {data: stats, isSuccess: statsSuccess} = useGetAgencyStatsQuery();
	// console.log("settings loaded, data?: ", data)
	const dispatch = useDispatch()
	const [skip, setSkip] = useState(true)
	const [contractsType, setContractsType] = useState()
	const [hideAdd, setHideAdd] = useState(false)

	const hasSettings = useSelector(state => state.settings)
	
	useEffect(() => {
		if (hasSettings?.name?.length) {
			setHideAdd(true)
		}
	}, [hasSettings])
	


	useEffect(() => {
		if (isSuccess) {
			dispatch(setAgencyDetails(data))
			setSkip(false)
		}
	}, [isSuccess])

	useEffect(() => {
		if (data?.yearType === 'calendarYear') {
			setContractsType('Calendar Year')
		} else if (data?.yearType === 'fiscalYear') {
			setContractsType('Fiscal Year')
		} else {
			setContractsType('Calendar Year')
		}
	}, [data])

	const consentIds = data?.consentForms
	
	return(
	<div>
	<Typography variant='h4' style={{fontWeight: 600}}>Agency Settings</Typography><br />

	<Paper sx={{backgroundColor: '#ECF1F7'}}>
		{hideAdd ? <EditSettings data={data} /> : <AddSettings /> }
		<Grid container direction='column' spacing={3}>
			<Grid item>
				<Card sx={{m: 2, p: 2}}>
					<Typography>Agency Name: {data?.name}</Typography> <br />
					<Stack direction={'row'}>
						<Tooltip title="This will auto fill the regional center section for Service Plans">
							<InfoIcon />
						</Tooltip>
						<Typography>Defualt Regional Center: {data?.regionalCenter}</Typography> <br />
					</Stack>
					<Divider sx={{m: 1, p: 1}}/>
					<Stack direction={'row'} sx={{p:1}}>
						<Tooltip title="How the quarters for contract measuring is set up. Calendar year is Jan 1 - Dec 31, Fiscal Year is Jul 1 - Jun 30">
							<InfoIcon />
						</Tooltip> 
						<Typography>Calendar Type for contract measurement: {contractsType}</Typography>
					</Stack> 
					<Divider sx={{m: 1, p: 1}} />
					{data?.useStdDdct? (
						<Stack direction={'row'}>
							<Tooltip title='Standard Deduction subtracts the users distance of home to an office, in lieu of communiting distance, from the first and last trips of a day. This is for when employees work at multiple job sites.  The deduction for each individual is set under their user profile. '>
								<InfoIcon />
							</Tooltip>
							<CheckBoxIcon /><Typography>Using Standard Deduction</Typography>
						</Stack>
					) : (
						<Stack direction={'row'}>
							<Tooltip title='Standard Deduction subtracts the users distance from home to an office, in lieu of communiting distance, from the first and last trips of a day. This is for when employees work at multiple job sites. The deduction for each individual is set under their user profile. '>
								<InfoIcon />
							</Tooltip>
							<CheckBoxOutlineBlankIcon /><Typography>Not using Standard Deduction</Typography>
						</Stack>	
					)}
					{data?.useFedRate ? (
						<Stack direction={'row'}>
							<Tooltip title='The federal rate is adjusted each year and is multipled to an employees mileage to determine reimbursement. You can update the rate from the Edit Settings button.'>
								<InfoIcon />
							</Tooltip>
							<CheckBoxIcon /><Typography>Using Federal Rate for Milage / </Typography>
							<br />
							<Typography> Current Federal Rate: {data?.fedRate}</Typography>
						</Stack>
					): (
						<Stack direction={'row'}>
							<Tooltip title='The federal rate is adjusted each year and is multipled to an employees mileage to determine reimbursement'>
								<InfoIcon />
							</Tooltip>
							<CheckBoxOutlineBlankIcon /><Typography>Not using Federal Rate for Milage</Typography>
						</Stack>

					)}	
				</Card>
			</Grid>
			<Grid item>
				<Divider />
			</Grid>
			<Grid item>
				<Card sx={{m: 2, p: 2}}>
					<Typography variant='h5'>Consent Forms:</Typography> <AddConsentForm /><br />
					{!skip && <AgencyConsents consentIds={consentIds} /> }
				</Card>
			</Grid>
			<Grid item>
				<Divider />
			</Grid>
			{role === 'Super Admin' && statsSuccess &&
			<Grid item>
				<Card sx={{m: 2, p: 2}}>
					<Typography variant='h5'>Agency Database Storage Stats:</Typography>
					<Divider />
					<Stack direction={'row'}>
						<Typography variant='h6'>Total: {stats.data}    </Typography>
						<Typography variant='h6'>Storage: {stats.storage}</Typography> <br />
					</Stack>
					<Box sx={{border: 1}}>
							<Typography variant='h6'>Clients: # of: {stats.clients.count}, data: {stats.clients.size}</Typography>
							<Typography variant='h6'>Users: # of: {stats.users.count}, data: {stats.users.size}</Typography>
							<Typography variant='h6'>Documents: # of: {stats.docs.count}, data: {stats.docs.size}</Typography>
							<Typography variant='h6'>Visits: # of: {stats.visits.count}, data: {stats.visits.size}</Typography>
						</Box>
					

				</Card>
			</Grid>
			}
		</Grid>
	</Paper>
	</div>
	)
}