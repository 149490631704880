import React, { useEffect, useState } from 'react';
import {Button, TextField, Dialog, DialogTitle,
        DialogActions, DialogContent, DialogContentText, IconButton} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useEditVisitMutation, useGetCurrentPlanQuery, useLazyGetClientReportGoalsQuery, useGetVisitQuery } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import GoalInput from '../visitviews/GoalInput';
import EditIcon from '@material-ui/icons/Edit';
// import useDeepCompareEffect from 'use-deep-compare-effect';

const ViewAuditVisit = ( visitId ) => { 
    // console.log('visitId: ', visitId)
    const { data: visit } = useGetVisitQuery(visitId)

  const [open, setOpen] = useState(false);
  const [skip, setSkip] = useState(true)
  const [clientId, setClientId] = useState();
  const [visitGoals, setVisitGoals] = useState([])
  

  const [editVisit] = useEditVisitMutation();

  useEffect(() => {
 
    if(open && visit) {
        const getId = visit?.client;
        setClientId(getId)
        setSkip(false)
    
      }

  }, [open, visit])

  const {data: planId, isSuccess: planSuccess} = useGetCurrentPlanQuery(clientId, {skip})

  const [trigger, {data: goals}] = useLazyGetClientReportGoalsQuery(planId)

  useEffect(() => {
    if(planSuccess) {
        trigger(planId);
      }
  }, [planSuccess])

  useEffect(() => {
    const planGoals = goals?.map(goal => (
      {
        title: goal.title,
        marked: false,
        note: ''
      }
    ))

    setVisitGoals(planGoals)
  }, [goals])
  


  useEffect(() => {
    if (visit?.goals?.length) {
      const result = visitGoals.map(i => {
        const new_info = goals.find(g => g.title === i.title);
        return {
          ...i,
          ...new_info
        };
      })
      
      
      setVisitGoals(result)
    }
    
  }, [visit, visitGoals])


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({
    defaultValues: {
    visitStart: visit?.visitStart,
    visitEnd: visit?.visitEnd,
    location: visit?.location,
    goals: [{
      title: visit?.goals?.title,
      marked: visit?.goals?.marked,
      note: visit?.goals?.note
    }]    
    },
    shouldUnregister: true
  });
  
 
 const onSubmit= async (data) => {
  
  // console.log("visit data: ", data)
  const _data = data.goals.filter(goal => goal.marked != false)
  data.goals = _data
  // console.log("post data: ", data)
                 
  try {
    // const payload =  
    await editVisit({ visitId, data })
    // .unwrap();
    //   console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save visit: ', err)
    }

    toast.success("Visit Updated", {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
 }

 const onError = (errors) => {
  toast.error("Error Updating Visit", {
    position: toast.POSITION.TOP_CENTER
  });
   console.log('error in visit form: ', errors);
 }


  return (
    <div>
      <IconButton color='primary' variant='contained' onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Visit</DialogTitle>
        <DialogContent>

        <DialogContentText>
            Visit for {visit?.client?.fullName}, User: {visit?.user?.fullName}
        </DialogContentText>
    
        
          <form id="editVisit" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
        
     <br></br>
     <section>
      <Controller
        control={control}
        name="visitStart"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
         
          label="Visit Start"
          error={!!errors.visitStart}
          value={field.value}
          onChange={(e)=>field.onChange(e)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.visitStart && "Select Time In"}
      </section>
      <br></br>
      <section>
      <Controller
        control={control}
        name="visitEnd"
        
  
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
             
              label="Visit End"
              error={!!errors.visitEnd}
              value={field.value}
              onChange={(e)=>field.onChange(e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          )}
          />
      <br></br>
      {errors.visitEnd && "Select Time Out"}
      </section>
      <br></br>
      <section>
        <TextField
          {...register('location', { required: true})}
          error={!!errors.location}
          label="Location"
          name='location'
          id='location'
          required
        />
      <br></br>
        {errors.location && "List a location for the visit"}
      </section>
      {visitGoals && visitGoals.map((goal, index) => (
          <GoalInput 
            key={goal._id}
            goal={goal} 
            index={index} 
            control={control}
            errors={errors}
            setValue={setValue}
            
          />
          
        
      ))}
     
     <br></br>

        </form>
        <br></br>    
        
        </DialogContent>

        <DialogActions>
          <Button color='warning' onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" form="editVisit" type="submit">
          Submit
           </Button>
        </DialogActions>
        </Dialog>
        
        </div>
    )}

ViewAuditVisit.propTypes = {
  visitId: PropTypes.any, 
  visit: PropTypes.any
}

export default ViewAuditVisit;
