import React, { useState, useEffect } from 'react';
import AuditSearch from './AuditSearch';
import AuditSearchList from './AuditSearchList';
import AllAuditsList from './AllAuditsList';
import { useSelector } from 'react-redux';


export default function AuditLog() {

	const [getAll, setGetAll] = useState(false)
    const [getSearch, setGetSearch] = useState(false)
    
    const options = useSelector(state => state.admin?.auditLogSearch?.options)
    const trigger = useSelector(state => state.admin?.auditLogSearch?.trigger)
    const searchAll = useSelector(state => state.admin?.auditLogSearch?.searchAll)

	useEffect(() => {
        if (searchAll) {
            setGetAll(true)
            setGetSearch(false)
        }
    }, [searchAll])

    useEffect(() => {
        if (trigger) {
            setGetSearch(true)
            setGetAll(false)
        }
    }, [trigger])
	console.log("getSearch: ", getSearch, options)

	let searchContent 

	if (getAll) {
        searchContent = <AllAuditsList />
    }
    
    if (getSearch) {
        const searchOptions = {
            startDate: options.dateRange[0],
            endDate: options.dateRange[1],
            userId: options.userIds
        }

        searchContent = <AuditSearchList searchOptions={searchOptions} />
    }

	return(
	<div>
	<h1>Audit Log</h1>
	<AuditSearch />
	{searchContent}

	</div>
	)
}