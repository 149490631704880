import React, {useEffect, useState} from 'react';
import {Button, Select, MenuItem, LinearProgress, FormControl, InputLabel, Box, Card, Typography } from '@mui/material';
import {  useGetUserGoalReportMutation, useGetUsersQuery } from '../../redux/apiSlice';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ClientGoalTable from './ClientGoalTable';

const monthOptions = [
    { label: 'Select Month', value: '' },
    { label: 'January', value: '0' },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
  ]

export default function GoalAttainReport () {

    // const [initialDate, setInitialDate] = useState([])

    // useEffect(() => {
    //     let today = new Date()
    //     let month = today.getMonth()
    //     let year = today.getFullYear()

    //     const firstHalf = [0,1,2,3,4,5]
    //     const secHalf = [6,7,8,9,10,11]

    //     let starting
    //     let ending

    //     if (firstHalf.find(x => x == month)) {
    //         let lastYear = year - 1

    //         starting = new Date().setFullYear(lastYear, 6, 1)
    //         ending = new Date().setFullYear(year, 5, 30)
    //         setInitialDate([starting, ending])
    //     } else if (secHalf.find(x => x == month)) {
    //         let nextYear = year + 1

    //         starting = new Date().setFullYear(year, 6, 1).toJSON()
    //         ending = new Date().setFullYear(nextYear, 6, 30).toJSON()

    //         setInitialDate([starting, ending])
    //     }
    // }, [])

    // console.log(initialDate)

    const [yearList, setYearList] = useState([])
    const [userId, setUserId] = useState('')
    const [startMonth, setStartMonth] = useState('')
    const [startYear, setStartYear] = useState('')
    const [endMonth, setEndMonth] = useState('')
    const [endYear, setEndYear] = useState('')
    // const [skip, setSkip] = useState(true)
    // const [value, setValue] = useState(initialDate ? initialDate : [new Date().toJSON(), new Date().toJSON()])
    const [runReport, setRunReport] = useState(false)
    const [isStart, setIsStart] = useState()
    const [isEnd, setIsEnd] = useState()
    const [trigger, setTrigger] = useState(false)
    

    const {data: users} = useGetUsersQuery();

    useEffect(() => {
        // Generate an array of years going back to 2017 cause this is the earliest Data in the system
        const currentYear = new Date().getFullYear();
  
        const yearsArray = [];
        for (let year = currentYear; year >= 2017; year--) {
          yearsArray.push({ label: year.toString(), value: year });
        }
  
        setYearList(yearsArray);
      }, [])

    useEffect(() => {
        if (runReport) {
            if (startMonth, startYear, endMonth, endYear) {
                // const addOne = endMonth + 1;
                const lastDay = new Date(endYear, endMonth + 1, 0).getDate()
                console.log("lastDay: ", lastDay, endMonth)

                setIsStart(new Date (new Date(new Date(startYear, startMonth).setDate(1)).setHours(0, 0, 0)).toISOString())
                setIsEnd(new Date (new Date(new Date(endYear, endMonth).setDate(lastDay)).setHours(23, 59, 59)).toISOString())

                setTrigger(true)
            }
        }
    }, [runReport, startMonth, startYear, endMonth, endYear])

    useEffect(() => {
        if (trigger) {
            pullData({userId, isStart, isEnd});

            setTrigger(false)
        }
    }, [trigger])
    

    const [pullData, { data, isLoading, isSuccess, reset }] = useGetUserGoalReportMutation({userId, isStart, isEnd})



  const generateUserOptions = () => {
    if (users) {
    
    let usersList = users.map(user => (
        {
            userId: user._id,
            fullName: user.fullName
         }))
         
    return usersList.map((user) => {

        return (
           <MenuItem key={user.userId} value={user.userId}>
              {user.fullName}
           </MenuItem>
        )
        })
    }
  }
    
  
   

  const generateMonthOptions = () => {
    return monthOptions.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const generateYearOptions = () => {
    return yearList.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  let displayReport 
        
    if (isLoading) {
        displayReport = <LinearProgress />
    } else if (isSuccess) {
        console.log("parent: ", data)
        displayReport = data.map((client, idx) => {return <ClientGoalTable key={idx} client={client} startDate={isStart} endDate={isEnd} />})
    }


const handleUserChange = (event) => {
    setUserId(event.target.value);
}

const handleStartMonthChange = (event) => {
    setStartMonth(event.target.value);
}

const handleStartYearChange = (event) => {
    setStartYear(event.target.value);
}

const handleEndMonthChange = (event) => {
    setEndMonth(event.target.value);
}

const handleEndYearChange = (event) => {
    setEndYear(event.target.value);
}


  return (
    <>
    <div display='flex'>
        <Card
            sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            m: 1,
            borderRadius: 1,
            }}
        >
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="user-select-label">User</InputLabel>
                    <Select
                        labelId="user-select-label"
                        label="User"
                        name='user'
                        value={userId}
                        onChange={handleUserChange}
                    >
                        {generateUserOptions()}
                    </Select>
            </FormControl>
            <Box 
                sx={{
                display: 'flex',
                flexDirection: 'row',
                p: 1,
                m: 1,
                borderRadius: 1,
                }}
            >
                <Typography variant='h6'>Start Dates</Typography> <br />
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="month-select-label">Month</InputLabel>
                    <Select
                        labelId="month-select-label"
                        label="Month"
                        name='month'
                        value={startMonth}
                        onChange={handleStartMonthChange}
                    >
                        {generateMonthOptions()}
                    </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="year-select-label">Year</InputLabel>
                    <Select
                        labelId="year-select-label"
                        label="Year"
                        name='year'
                        value={startYear}
                        onChange={handleStartYearChange}
                    >
                        {generateYearOptions()}
                    </Select>
            </FormControl>
            </Box>
            <Box 
                sx={{
                display: 'flex',
                flexDirection: 'row',
                p: 1,
                m: 1,
                borderRadius: 1,
                }}
            >
                <Typography variant='h6'>End Dates</Typography> <br />
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="month-select-label">Month</InputLabel>
                    <Select
                        labelId="month-select-label"
                        label="Month"
                        name='month'
                        value={endMonth}
                        onChange={handleEndMonthChange}
                    >
                        {generateMonthOptions()}
                    </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="year-select-label">Year</InputLabel>
                    <Select
                        labelId="year-select-label"
                        label="Year"
                        name='year'
                        value={endYear}
                        onChange={handleEndYearChange}
                    >
                        {generateYearOptions()}
                    </Select>
            </FormControl>
            </Box>
            
            <Button color='primary' variant='contained' onClick={() => {reset, setRunReport(true)} }>    Run    </Button>
        </Card>
    </div>
    {displayReport}
    </>
  )

}