import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useGetUserScheduleVisitsQuery } from '../../redux/apiSlice';
import ScheduleVisit from '../visitviews/InScheduleVisit';
import ViewVisit from '../visitviews/ViewVisit';
import { useAuth } from '../../utilities/useAuth';
import { setOpenState, setScheduleOpenState } from '../../redux/slices/visitSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Grid, useMediaQuery, useTheme } from '@mui/material';


export default function Schedule () {

    const { id } = useAuth()
	const userId = id

    const dispatch = useDispatch()

    const isOpen = useSelector(state => state.visit.openModal)
    const schedulerOpen = useSelector(state => state.visit.openScheduler)

    const [visitId, setVisitId] = useState()
    const [eventDetails, setEventDetails] = useState()
    const [hasDate, setHasDate] = useState()

    

    const { data: visits} = useGetUserScheduleVisitsQuery(userId)

    const handleDateClick = (arg) => {
        // console.log("1: ", arg.dateStr)

        // eslint-disable-next-line
        let dateNoTime = new Date(arg.dateStr.replace(/-/g, '\/'))
        // console.log("2: ", dateNoTime)

        let dateTime = new Date();
        dateNoTime.setHours(dateTime.getHours());
        dateNoTime.setMinutes(dateTime.getMinutes());
        dateNoTime.setSeconds(0);

        // console.log("3: ", dateNoTime)
        let adjust = new Date(dateNoTime).toISOString()
        // console.log("4: ", adjust)
        setHasDate(adjust)
        let payloadObj = {
            open: true,
            time: adjust
        }
        dispatch(setScheduleOpenState(payloadObj))
        
    }

    const handleEventClick = (arg) => {
    
        const visitId = arg.event.extendedProps.visitId
        // console.log("visitId: ", visitId, "event: ", arg.event.extendedProps)
        setVisitId(visitId)
        setEventDetails(arg.event.extendedProps) 
        dispatch(setOpenState(true))
    }
    // console.log("hasDate: ", hasDate)
        
    const singleEvent = 
            <div>
                <ViewVisit 
                    event={eventDetails} 
                    isOpen={isOpen}
                    visitId={visitId}
                />
            </div>

    const dateSchedule = 
            <div>
                <ScheduleVisit
                    isOpen={schedulerOpen}
                    hasDate={hasDate}
                />
            </div>
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    console.log("mobile: ", isMobile, "tablet: ", isTablet)

    const [initial, setInitial] = useState('dayGridMonth');

    useEffect(() => {
        if (isMobile){
            setInitial("timeGridDay")
        } else if (isTablet) {
            setInitial("timeGridWeek")
        } else {
            setInitial("dayGridMonth")
        }
    }, [isMobile, isTablet])

    return (
        <>
        <Grid container spacing={2} sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Card>
                    <ScheduleVisit />
                </Card>
            </Grid>
            <Grid item xs={12} >
                <Box sx={{ overflowX: 'auto', width: '100%', height: '100%'}}>
                    <FullCalendar
                        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
                        initialView={initial}
                        headerToolbar= {{
                            left: 'prev,next',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        aspectRatio={.25}
                        // height={isMobile ? '100%': 'auto'}
                        editable={true}
                        events={visits}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                    />
                    {singleEvent}
                    {dateSchedule}
                </Box>
            </Grid>            
        </Grid>
        </>
    )
}