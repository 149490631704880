import React, { useEffect, useState } from 'react';
import { Button, DialogContent, DialogActions, TextField, IconButton, Box, Typography, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useForm, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

const AddHealthType = ({ onAdd, handleClose }) => {
  
  const [doesTarget, setDoesTarget] = useState(false);
  const [hasUpper, setHasUpper] = useState();
  const [hasLower, setHasLower] = useState();

  const { register, control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      logType: '',
      fields: [{ name: '' }],
      target: doesTarget,
      upperAlert: hasUpper,
      lowerAlert: hasLower,
      goal: ''
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fields'
  }); 

  const onSubmit = (data) => {
    onAdd(data);
    handleClose();
    reset();
  };

  const handleTargetChange = (event) => {
    setDoesTarget(event.target.checked)
    setValue('target', true)
  }

  const watchUpper = watch('upperAlert')
  const watchLower = watch('lowerAlert')

  useEffect(() => {
    if(watchUpper) {
        const int = parseFloat(watchUpper)
        setHasUpper(int)
    }
  }, [watchUpper])

  useEffect(() => {
    if(watchLower) {
        const int = parseFloat(watchLower)
        setHasLower(int)
    }
  }, [watchLower])

  return (
    <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography>Set the Log Type to the name of what you are tracking, this will also be the tab label.</Typography>
            <TextField
              label="Log Type"
              fullWidth
              margin="normal"
              {...register('logType', { required: true })}
            />
            <Typography variant="h6">Fields</Typography>
            <Typography>Set the Fields you want to track, common are Date (time will be included with date) and Value. For example, to track blood sugars, you would select Date and Value. Another useful field is Note, in case theres additional information to state regarding the reading on that date</Typography>
            {fields.map((field, index) => (
              <Box key={field.id} display="flex" alignItems="center">
                <TextField
                  label={`Field ${index + 1}`}
                  fullWidth
                  margin="normal"
                  {...register(`fields.${index}.name`, { required: true })}
                />
                <IconButton onClick={() => remove(index)} disabled={fields.length === 1}>
                  <RemoveIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              type="button"
              onClick={() => append({ name: '' })}
              startIcon={<AddIcon />}
            >
              Add Field
            </Button>
            <br />
            <Typography>Set the target value goal:</Typography>
            <TextField
              {...register('goal')}
              type='tel'
              name='goal'
            /> <br />
            <Typography>Click the box to set a target for alerting if the value is outside of the range: </Typography>
            <Checkbox
                checked={doesTarget}
                onChange={handleTargetChange}
                label='Set Alert Range?'
            />
            {doesTarget &&
                <span>
                    <TextField
                        {...register('upperAlert')}
                        name='upperAlert'
                        type='tel'
                        value={hasUpper}
                        label="Upper Alert"
                    />
                     <TextField
                        {...register('lowerAlert')}
                        name='lowerAlert'
                        type='tel'
                        value={hasUpper}
                        label="Lower Alert"
                    />
                </span>
                
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
    </div>
  );
};

AddHealthType.propTypes = {
    onAdd: PropTypes.func,
    handleClose: PropTypes.func
  }

export default AddHealthType;
