import React, {useState, useEffect} from 'react';
// import { selectTimeIn } from '../../redux/slices/userSlice';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import './Clock.css'

const TimeIn = (isActive) => {
    const [currentTime, setCurrentTime] = useState()

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0)

    // const started = selectTimeIn()
    const started = useSelector((state) => state.user?.clock?.info?.timeIn)
    
    useEffect(() => {
        const intervalId = setInterval(() => {
          setCurrentTime(new Date().toISOString());
        }, 1000)
      
        return () => clearInterval(intervalId);
      }, [])

    useEffect(() => {
        if (isActive && started?.length) {
          const startDate = new Date(started)
          const current = new Date(currentTime)
          const time = Math.abs( current - startDate ) 

          setHours(Math.floor((time /(1000 * 60 * 60)) % 24))
          setMinutes(Math.floor((time / 1000 / 60) % 60))
          setSeconds(Math.floor((time / 1000) % 60))
        }
      }, [isActive, started, currentTime])

  return (
    <div>
        <Typography>Current duration:</Typography>
        <div className='timer'>
          <div className='col-4'>
            <div className='box'>
              <p>{hours}</p>
              <span className='text'>Hours</span>
            </div>
          </div>
          <div className='col-4'>
            <div className='box'>
              <p>{minutes}</p>
              <span className='text'>Minutes</span>
            </div>
          </div>
          <div className='col-4'>
            <div className='box'>
              <p>{seconds}</p>
              <span className='text'>Seconds</span>
            </div>
          </div>
        </div>
    </div>
  )
}

export default TimeIn

  


