import React from 'react';
import AddContract from './AddContract';
import ClientContracts from './ClientContracts';

const ContractsTab = () => {
   

    return(
        <>
        
          <AddContract />
          <br></br>
          <ClientContracts />
        </>
    )

}

export default ContractsTab;