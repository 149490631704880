import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, Typography } from '@mui/material';
import { setAllowStep, setPlanNotes } from '../../../redux/slices/stepperSlice';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

const StepNotes = ({allowStep}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if(!allowStep){
          dispatch(setAllowStep(true))
        }
      }, [])

      const {
        register,
        handleSubmit,
    } = useForm({})

    const setNotes = (data) => {
        dispatch(setAllowStep(false))
        dispatch(setPlanNotes(data))
    }

    const onSubmit = async (data) => {
        console.log("payload", data)

        setNotes(data)
    };



  return (
    <div>
      <br />

        <form
            id="addNotes"
            onSubmit={(e) =>
              handleSubmit(
                onSubmit,
              )(e).catch((e) => {
                console.log("e", e);
              })
            }
          >
            <Typography>Payee Information</Typography>
            <TextField {...register('payee')} />
            <Typography>Vocational/Education Information</Typography>
            <TextField {...register('vocation')} />
            <Typography>Family Input</Typography>
            <TextField {...register('family')} />
            <Typography>Current Medical Status</Typography>
            <TextField {...register('medical')} />
            <Typography>Long Term Goal</Typography>
            <TextField {...register('longGoal')} />
            <Button color="primary" variant="contained" form="addNotes" fullWidth type="submit" onClick={() => dispatch(setAllowStep(false))}>
          Update Plan Notes
        </Button>



          </form>
    </div>
  )
}

StepNotes.propTypes = {
    allowStep: PropTypes.any
}

export default StepNotes