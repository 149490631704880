import React from 'react';



export default function Messages() {
	return(
	<div>
	<h1>Messages</h1>
	</div>
	)
}