import React from 'react';
import { Paper, Box } from '@mui/material';
import PropTypes from 'prop-types';



const VisitsBlock = ({ visit }) => {

    const timeStart = new Date(visit.visitStart).toLocaleTimeString("en-US");
        
    const timeEnd = new Date(visit.visitEnd).toLocaleTimeString("en-US");


    return (
        <>
            <Paper>
                <Box>
                    
                    {visit &&
                        <div>
                            <h3>Client: {visit.client.fullName}</h3>
                            <h3>Time In: {timeStart}</h3>
                            <h3>Time Out: {timeEnd}</h3>
                            <h3>Type: {visit?.location}</h3>
                            <h3>Hours: {visit?.totalHours}</h3>
                        </div>
                    }

                </Box>
            </Paper>
        </>
    )


}

VisitsBlock.propTypes = {
    visit: PropTypes.any
}

export default VisitsBlock;