import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useEditClientMutation } from '../../redux/apiSlice'
import { DialogTitle, Dialog, DialogContent, Button, TextField, DialogActions } from '@mui/material';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from '../../utilities/useAuth';

const EditClient = ({ client }) => {

  const {clientId} = useParams();
  
  const {id} = useAuth()
  const isUser = id;

  const [editClient] = useEditClientMutation()

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {register, handleSubmit,} = useForm({
    defaultValues: {
      hasName: 'hasName',
      firstName: client?.firstName,
      lastName: client?.lastName
    }
  }) 

  const onSubmit = async (data) => {

    // console.log(data);
    
    try {
    // const payload =  
    await editClient({ clientId, data, isUser }).unwrap();
      // console.log('fulfilled', payload)
    } catch (err) {
      console.error('Failed to save client: ', err)
      toast.error("Error Updating Client", {
          position: toast.POSITION.TOP_CENTER
        });
    }

    toast.success("Client Updated", {
        position: toast.POSITION.TOP_RIGHT
    });
    handleClose();      
    
  }

   
  return (
  <div>
    <Button color='secondary' startIcon={<EditIcon />} onClick={handleClickOpen}>
      Edit Client
    </Button>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Client Details</DialogTitle>
      <DialogContent>
   

      <form id='editClient' onSubmit={(e) =>
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log("e", e);
              })}>
        <TextField
          fullWidth
          id="firstName"
          name="firstName"
          label="First Name"
          placeholder='Danny'
          {...register('firstName')}
          
        />
        <TextField
          fullWidth
          id="lastName"
          name="lastName"
          label="Last Name"
          placeholder='Rojas'
          {...register('lastName')}
        />
        <DialogActions>
          <Button color='warning' onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" fullWidth form='editClient' type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
      </DialogContent>
    </Dialog>
    </div>
  );
};

EditClient.propTypes = {
    client: PropTypes.any
}

export default EditClient;
