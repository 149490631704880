import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Typography, Dialog } from '@mui/material';
import BldPrsrTemplate from './templates/BldPrsrTemplate';
import BldSgrTemplate from './templates/BldSgrTemplate';
import OxygenTemplate from './templates/OxygenTemplate';
import WeightTemplate from './templates/WeightTemplate';
import AddHealthType from './AddHealthType';
import PropTypes from 'prop-types';

const templateComponents = {
    'Blood Pressure': BldPrsrTemplate,
    'Blood Sugar': BldSgrTemplate,
    'Oxygen': OxygenTemplate,
    'Weight': WeightTemplate,
    'Custom': AddHealthType,
  };
  

export default function HealthLogMenu({onAdd, exists}) {

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  
//   const avaialble = templateTypes.filter(x => !exists.includes(x))
const available = Object.keys(templateComponents).filter(
    (templateType) => !exists.includes(templateType)
  );

  const handleTemplateSelect = (templateType) => {
    setSelectedTemplate(templateType)
    // const TemplateComponent = templateComponents[templateType];
    // onAdd(templateType, TemplateComponent);
    handleClose();
  };

  const handleDialogClose = () => {
    setSelectedTemplate(null);
  };


  const SelectedTemplateComponent = selectedTemplate ? templateComponents[selectedTemplate] : null;


  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Add Health Log Type
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Typography>Templates:</Typography>
        {available.map((templateType) => (
          <MenuItem
            key={templateType}
            onClick={() => handleTemplateSelect(templateType)}
          >
            {templateType}
          </MenuItem>
        ))}
      </Menu>
      {SelectedTemplateComponent && (
        <Dialog open={Boolean(selectedTemplate)} onClose={handleDialogClose} fullWidth>
            <SelectedTemplateComponent onAdd={onAdd} handleClose={handleDialogClose} />
        </Dialog>
      )}
    </div>
  );
}

HealthLogMenu.propTypes = {
    exists: PropTypes.any,
    onAdd: PropTypes.any
  }