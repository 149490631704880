import React from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import './TimeSummary.css'

function Row({data}) {

    const sortedEntryData = [...(data?.entryData || [])].sort((a, b) => new Date(a.timeIn) - new Date(b.timeIn));

    
    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' }

    return (
        <React.Fragment key={data?.date}>
            <TableRow>
                <TableCell>{new Date(data?.date).toLocaleDateString("en-US", options)}</TableCell>
                <TableCell align="center">{data?.visitHours}</TableCell>
                <TableCell align="center">{data?.entryHours}</TableCell>
                <TableCell align="center">{data?.miles}</TableCell>
                <TableCell></TableCell>
            </TableRow>
            {sortedEntryData && sortedEntryData.map((i, idx) => (
                <React.Fragment key={idx} >
                <TableRow>
                <TableCell></TableCell>
                <TableCell>{new Date(i?.timeIn).toLocaleTimeString()}</TableCell>
                <TableCell>{new Date(i?.timeOut).toLocaleTimeString()}</TableCell>
                <TableCell>{i?.hours}</TableCell>
                <TableCell>{i?.type}</TableCell>
            </TableRow>
                </React.Fragment>
            ))}
            
        </React.Fragment>
    )
}

Row.propTypes = {
    data: PropTypes.any
}


const TimeSummary = ({dateRange, user, data, totals, hasSig, sigDate, setNotes}) => {
    const settings = useSelector(state => state.settings)
    

    let mileFormula
    if (settings.useFedRate) {
        
        const mileMath = +(Math.round(Math.abs(totals?.mileTotals * settings?.fedRate) + "e+2") + "e-2") 
        mileFormula = `Total Miles: ${totals?.mileTotals} x Federal Rate: ${settings.fedRate} = $${mileMath}`

    }

    const start = new Date(dateRange[0])
    const end = new Date(dateRange[1])

    var getDaysArray = function(start, end) {
        for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
            arr.push({date: new Date(dt).toLocaleDateString()});
        }
        return arr;
    };
    
    const dateSet = getDaysArray(start, end)
    // console.log(dateSet)
    
    const mergedData = dateSet.map(dates=>({
    ...dates,
    ...data.find(newData=>newData.date == dates.date)
    }))
    
    // console.log(mergedData)

    let summaryTable
    if (data) {

        summaryTable =(
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="center">Billable</TableCell>
                        <TableCell align="center">Entries</TableCell>
                        <TableCell align="center">Miles</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mergedData && mergedData.map((i, idx) => (
                        <Row key={idx} data={i} />
                    ))}
                </TableBody>
            </Table>
        </>
        )
    }


  return (
    <div>
        <Typography className='topHead'>Timecard Summary</Typography>
        <div className='container'>
            <Box className='summaryDetails'>
            <Typography variant='h5'>User: </Typography><Typography variant='h6'>{user}</Typography><br />
            <Typography variant='h5'> Dates: </Typography><Typography variant='h6'>{new Date(dateRange[0]).toLocaleDateString()} - {new Date(dateRange[1]).toLocaleDateString()}</Typography> <br />
            <Typography variant='h5'> Total Entry Hours: </Typography><Typography variant='h6'>{totals?.entryTotals} </Typography>
            <Typography variant='h5'> Total Billable Hours: </Typography><Typography variant='h6'>{totals?.visitTotals} </Typography>
            <Typography variant='h5'> Total Miles: </Typography><Typography variant='h6'>{totals?.mileTotals} </Typography> <br />
            <Typography variant='h6'>{mileFormula}</Typography>
            <Typography variant='h5'>Notes for submission:</Typography><br />
            <Typography variant='h6'>{setNotes}</Typography>
            <br />
                    {hasSig && (
                        <div>
                            <Typography variant='h5'>Submitted:</Typography>
                            <img src={hasSig} alt="User Signature" style={{ width: '200px', height: 'auto' }} />
                            <br />
                            
                                <Typography variant='h6'>{user} </Typography> 
                                <Typography variant='h6'> {sigDate}</Typography>
                            
                            
                        </div>
                    )}
            
            </Box>
            <Box className='table'>
                {summaryTable}
            </Box>
        </div>
    </div>
  )
}

TimeSummary.propTypes = {
    data: PropTypes.any,
    user: PropTypes.any,
    totals: PropTypes.any,
    dateRange: PropTypes.any,
    hasSig: PropTypes.any,
    sigDate: PropTypes.any,
    setNotes: PropTypes.any
}

export default TimeSummary