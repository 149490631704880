import React from 'react';
import { useGetUsersByRoleQuery } from '../../redux/apiSlice';
import SupCaseloadList from './SupCaseloadList';

const AdminCaseloadList = () => {
    const role = 'Supervisor'
    const {data: sups} = useGetUsersByRoleQuery(role)
    let ids = sups?.map(i => i._id)
    
  return (
    <div>
        {ids && ids.map((i, idx) => {
            return (
                <><SupCaseloadList superId={i} key={idx} /> <br /></>
            )
        })}
    </div>
  )
}

export default AdminCaseloadList