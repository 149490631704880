import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DialogContent, DialogActions, TextField, IconButton, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

const OxygenTemplate = ({ onAdd, handleClose }) => {

  const [doesTarget, setDoesTarget] = useState(true);
  const [hasUpper, setHasUpper] = useState();
  const [hasLower, setHasLower] = useState();
  
  const { register, control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      logType: 'Oxygen',
      fields: [{name: 'Date'}, {name: 'Value'}, {name: 'Note'}],
      target: doesTarget,
      upperAlert: hasUpper,
      lowerGoal: hasLower,
      goal: 0
    }
  });
  
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fields'
  });

  useEffect(() => {
    setValue('logType', 'Oxygen');
  }, [setValue]);


  const onSubmit = (data) => {
    const finalFields = data.fields.map(field => field.name);
    onAdd({ ...data, fields: finalFields });
    handleClose();
    reset();
  };

  const handleTargetChange = (event) => {
    setDoesTarget(event.target.checked)
    setValue('target', true)
  }

  const watchUpper = watch('upperAlert')
  const watchLower = watch('lowerGoal')

  useEffect(() => {
    if(watchUpper) {
        const int = parseFloat(watchUpper)
        setHasUpper(int)
    }
  }, [watchUpper])

  useEffect(() => {
    if(watchLower) {
        const int = parseFloat(watchLower)
        setHasLower(int)
    }
  }, [watchLower])

  return (
    <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography>Log Type:</Typography>
            <Controller
              name="logType"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Log Type"
                  fullWidth
                  margin="normal"
                  disabled
                />
              )}
            />
            <Typography variant="h6">Fields:</Typography>
           {fields.map((field, index) => (
              <Box key={field.id} display="flex" alignItems="center">
                <TextField
                  label={`Field ${index + 1}`}
                  fullWidth
                  margin="normal"
                  {...register(`fields.${index}.name`, { required: true })}
                />
                <IconButton onClick={() => remove(index)} disabled={fields.length === 1}>
                  <RemoveIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              type="button"
              onClick={() => append({ name: '' })}
              startIcon={<AddIcon />}
            >
              Add Field
            </Button>
            <br />
            <Typography>Set the target value goal (eg. 99%):</Typography>
            <TextField
              {...register('goal')}
              type='tel'
              name='goal'
            />
            <Typography>Click the box to set a target for alerting if the value is outside of the range: </Typography>
            <Checkbox
                checked={doesTarget}
                onChange={handleTargetChange}
                label='Set Alert Range?'
            />
            {doesTarget &&
                <span>
                    <TextField
                        {...register('upperAlert')}
                        name='upperAlert'
                        type='tel'
                        value={hasUpper}
                        label="Upper Alert"
                    />
                     <TextField
                        {...register('lowerGoal')}
                        name='lowerGoal'
                        type='tel'
                        value={hasLower}
                        label="Lower Alert"
                    />
                </span>
                
            }
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="warning">
              Cancel
            </Button>
            <Button type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
    </div>
  );
};

OxygenTemplate.propTypes = {
  onAdd: PropTypes.func,
  handleClose: PropTypes.func
}

export default OxygenTemplate;
