import { createSlice } from '@reduxjs/toolkit'

export const stepperSlice = createSlice({
    name: 'stepper',
    initialState: {
        open: false,
        plan: {
            reportDate: '',
            startDate: '',
            endDate: '',
            type: '',
            client: '',
            goals: [],
            notes: {
                payee: '',
                vocation: '',
                family: '',
                medical: '',
                longGoal: ''
            },
            submitted: '',
            submittedBy: '',
            submittedSig: {
                data: '',
                contentType: 'image/png'
            },
            submittedDate: ''
        },
        allowStep: false,
        stepGoals: []
    },
    reducers: {
        reset(state) {state.plan = {
            reportDate: '',
            startDate: '',
            endDate: '',
            type: '',
            client: '',
            goals: [],
            notes: {
                payee: '',
                vocation: '',
                family: '',
                medical: '',
                longGoal: ''
            },
            submitted: '',
            submittedBy: '',
            submittedSig: {
                data: '',
                contentType: 'image/png'
            },
            submittedDate: ''
        }},
        setPlanClient(state, action) {
            state.plan.client = action.payload
        },
        setOpenState(state, action) {
            state.open = action.payload
        },
        setPlanDetails(state, action) {
            state.plan.reportDate = action.payload.reportDate
            state.plan.startDate = action.payload.startDate
            state.plan.endDate = action.payload.endDate
            state.plan.type = action.payload.type
        },
        setPlanGoals(state, action) {
            state.plan.goals.push(action.payload)
        },
        setPlanNotes(state, action) {
            state.plan.notes.payee = action.payload.payee
            state.plan.notes.vocation = action.payload.vocation
            state.plan.notes.family = action.payload.family
            state.plan.notes.medical = action.payload.medical
            state.plan.notes.longGoal = action.payload.longGoal
        },
        setStepperGoals(state, action) {
            state.stepGoals= [...action.payload]
        },
       setAllowStep(state, action) {
        state.allowStep = action.payload
       },
       setSubmit(state, action) {
        state.plan.submittedBy = action.payload.user,
        state.plan.submitted = true,
        state.plan.submittedDate = new Date().toISOString(),
        state.plan.submittedSig.data = action.payload.sig
       }
    }
    
})

export const { reset, setPlanClient, setOpenState, setPlanDetails, setPlanGoals, setPlanNotes, setStepperGoals, setAllowStep, setSubmit  } = stepperSlice.actions

export default stepperSlice.reducer

