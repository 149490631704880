import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { useGetClientsQuery } from '../../redux/apiSlice';
import { useAuth } from '../../utilities/useAuth';



const DataTable = () => {
  const {role, id} = useAuth();
  const userId = id;


const {
  data,
  isLoading,
  isSuccess,
  
} = useGetClientsQuery({role, userId})

const columns = 

   [
  {
    field: '_id', 
    headerName: 'Client Chart', 
    width: 80,
    renderCell: (params) => (
      <Link to={`/ClientChart/${params.value}`}><AccountCircleIcon /></Link>
    )
  },
  {field: 'lastName', headerName: 'Last Name', width: 150},
    
  
  {field: 'firstName', headerName: 'First Name', width: 150},
  {field: 'phone', headerName: 'Phone', width: 150},
  
   ];


let content

if (isLoading) {
  content = <CircularProgress />
} else if (isSuccess) {
  content = 
  <div style={{ display: 'flex' }}>
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
  { data &&
    <DataGrid 
      autoHeight
      autoWidth
      getRowId={(row) => row._id}
      rows={data}
      columns={columns}
      //autoPageSize
      sx={{ height: '100%', width: '100%' }}
      rowsPerPageOptions={[20, 50, 100]}
  
 />}
 </Box>
  </div>

}


  return (
    <div>
      <h1>All Current Clients</h1>
      
      <div>
        {content}
      </div>
      
      
    </div>
  )
}

export default DataTable;