import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';


const AddTimecardNotes = ({handleSave}) => {

    const [open, setOpen] = useState(false);
    const [note, setNote] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setNote('');
        setOpen(false);
      };

      const handleChange = (event) => {
        setNote(event.target.value);
    };

    const handleSaveClick = () => {
        handleSave(note);
        handleClose(); 
    };


    return (
        <div>
            <Button variant='contained' color='primary' onClick={handleClickOpen}>Add Notes</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Typography variant='h5'>Add notes to submission:</Typography>
                    <TextField multiline onChange={handleChange} value={note} />
                </DialogContent>
                <DialogActions>
                    <Button color='warning' variant='contained' onClick={handleClose}>Cancel</Button>
                    <Button color='primary' variant='contained' onClick={handleSaveClick}>Save Note</Button>
                </DialogActions>
            </Dialog>
            
        </div>

    )
}

AddTimecardNotes.propTypes = {
    handleSave: PropTypes.any
}

export default AddTimecardNotes