import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: null,
    id: null,
    useStdDdct: false,
    useFedRate: false,
    fedRate: null,
    regionalCenter: ''
}

export const settingsSlice = createSlice({
    initialState,
    name: 'user',
    reducers: {
        setAgencyDetails: (state, action) => {
            state.name = action.payload.name;
            state.id = action.payload._id,
            state.useStdDdct = action.payload.useStdDdct,
            state.useFedRate = action.payload.useFedRate,
            state.fedRate = action.payload.fedRate,
            state.regionalCenter = action.payload.regionalCenter
        },
        changeAgencyName: (state, action) => {
            state.name = action.payload.name;
        },
        
    }
});

export default settingsSlice.reducer;

export const { setAgencyDetails, changeAgencyName } = settingsSlice.actions;

export const selectAgencyName = (state) => state.name

export const selectAgencyId = (state) => state.id

export const selectStdDdct = (state) => state.useStdDdct