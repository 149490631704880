import React, {useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, TextField, Paper, Box } from '@mui/material';
import "yup-phone";
import axios from 'axios';

//Since theres already AddUser for an admin to implement, this would be 
//linked from an email. So it could just be a standalone component
//Maybe redirect to the login screen and add a success tag.
//Do I need to pull out the onsubmit and then pass as a prop?
//Still need to add confirm password to AddUser

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object({
  firstName: yup
    .string("Client's First Name")
    .required('Required'),
  lastName: yup
    .string("Client's Last Name")
    .required('Required'),

  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  phone: yup
    .string('Main Contact Number')
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Required'),
  username: yup
    .string('Enter Username')
    .required('Required'),
  password: yup
    .string('Enter Password')
    .required('Required'),
  confirmPassword: yup.string().when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf(
        [yup.ref("password")],
        "Both password need to be the same"
      )
    })
  
});

const RegisterUser = () => {

  let navigate = useNavigate();

    useEffect(() => {
      if(localStorage.getItem("authToken")) {
        navigate("/dashboard")
      }
    }, []);
   
      
       
      return (
        <>
        <Box
            sx={{
                mx: 'auto',
                width: 500,
                pt: 10,
                mt: 10,
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
                
            }}
        >
        <Paper elevation={24}>
      
      <div>
        <h1>Add New User</h1>
      
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          username: '',
          password: '',
          confirmPassword: ''
        }}
        validationSchema={validationSchema}
      
        onSubmit={async (values) => {
    
          // console.log(values);
                     
          await axios.post('http://localhost:5000/userapi/users', values)      
          .then(response => {
            
            console.log(response.data);
            //return response.data;
          })
          
          .catch(function (error){
            console.log(error);
          });
          
          history.push('/login');
          
        }}>
      
     
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              placeholder='Danny'
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              placeholder='Rojas'
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
            <TextField
              fullWidth
              id="phone"
              name="phone"
              label="Phone"
              placeholder="(619)423-5071"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              placeholder="d.rojas@octochart.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              id="username"
              name="username"
              label="Username"
              placeholder="DRojas"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              placeholder="#########"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              placeholder="#########"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            />
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
            <br></br>
            <span>Already have an account? <Link to="/login">Login</Link></span>
          </form>
        )}
          </Formik>
        </div>
        </Paper>
        </Box>
        </>
      );
    };

    
   
export default RegisterUser;