import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useAddMedicationMutation } from "../../../redux/apiSlice";
import {
  Checkbox,
  Button,
  TextField,
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  FormControlLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useAuth } from '../../../utilities/useAuth';

const AddMedication = () => {
  const {id} = useAuth();
  const isUser = id;
  const { clientId } = useParams();

  const [addMed] = useAddMedicationMutation();

  const [open, setOpen] = useState(false);

  const [isActive, setIsActive] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      dosage: "",
      frequency: "",
      active: true,
      reason: "",
      startDate: "",
      endDate: ""
    },
  });

  const onSubmit = async (data) => {
    // console.log(data);

    try {
      // const payload = 
      await addMed({ clientId, data, isUser })
      // .unwrap();
      // console.log("fulfilled", payload);
    } catch (err) {
      console.error("Failed to add medication: ", err);
    }

    toast.success("Medication Added", {
      position: toast.POSITION.TOP_RIGHT,
    });
    handleClose();
  };

  const onError = (errors) => {
    toast.error("Error adding medication", {
      position: toast.POSITION.TOP_CENTER,
    });
    console.log("error in medication form: ", errors);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <AddBoxIcon color="primary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Medication</DialogTitle>
        <DialogContent>
          <form
            id="addMed"
            onSubmit={(e) =>
              handleSubmit(
                onSubmit,
                onError
              )(e).catch((e) => {
                console.log("e", e);
              })
            }
          >
            <section>
              <TextField
                id="name"
                name="name"
                label="Name"
                placeholder="Amlodipine"
                {...register("name", { required: true })}
                error={!!errors.name}
              />

              <TextField
                id="dosage"
                name="dosage"
                label="Dosage"
                placeholder="10 mg."
                {...register("dosage", { required: true })}
                error={!!errors.dosage}
              />
              <TextField
                id="frequency"
                name="frequency"
                label="Frequency"
                placeholder="Once Daily"
                {...register("frequency", { required: true })}
              />
            </section>
            <section>
              <FormControlLabel
                control={
                  <Checkbox
                    id="isActive"
                    name="isActive"
                    {...register("isActive")}
                    checked={isActive}
                    value={isActive}
                    onChange={() => {
                      setIsActive(!isActive);
                      setValue("active", isActive);
                    }}
                  />
                }
                label="Current?"
              />
            </section>
            <section>
              <TextField
                id="reason"
                name="reason"
                label="Taken For"
                placeholder="Hypertension/ High Blood Pressure"
                {...register("reason", { required: true })}
              />
            </section>
            <section>
              <Controller
                control={control}
                name="startDate"
                rules={{ required: false }}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...field}
                      label="Start Date"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      renderInput={(params) => <TextField {...params} error={!!errors.startDate} />}
                    />
                  </LocalizationProvider>
                )}
              />

              <br />
              {!isActive && 
              <>
              <Controller
                control={control}
                name="endDate"
                rules={{ required: false }}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      renderInput={(params) => <TextField {...params} error={!!errors.endDate} />}
                    />
                  </LocalizationProvider>
                )}
              />
              <br></br>
              </>
                }
            </section>

            <DialogActions>
              <Button color="warning" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                form="addMed"
                type="submit"
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddMedication;
