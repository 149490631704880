import React from 'react';
import { Typography } from '@mui/material';
import Contacts from '../contactviews/Contacts';

const StepContacts = () => {
  return (
    <div>
        <Typography variant="h3" sx={{ textAlign: 'center'}}>
            Contacts
        </Typography>
        <br />
        <Contacts />

        
    </div>
  )
}

export default StepContacts