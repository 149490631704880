import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useAddNewLogMutation } from '../../../redux/apiSlice';


const AddBldPrsrFromVisit = ({log, clientId, hasDate, addLogSet, isUser}) => {
    const [openEntry, setOpenEntry] = useState(false);
    const [addVisitEntry] = useAddNewLogMutation();

    const logType = 'health';
    const logId = log._id;

    const handleEntryOpen = () => {
        setOpenEntry(true);
      };
    
      const handleEntryClose = () => {
        setOpenEntry(false);
        reset();
      };

      const [selectedTime, setSelectedTime] = useState(null);

      const onTimeChange = (time) => {
          setSelectedTime(time);
      };

      const combineDateAndTime = (selectedTime) => {
          const date = new Date(hasDate);
          date.setHours(selectedTime.getHours());
          date.setMinutes(selectedTime.getMinutes());
          date.setSeconds(selectedTime.getSeconds());
          return date.toISOString();
      };

      const { control, reset, register, handleSubmit} = useForm();

      const onEntrySubmit = (data) => {
        const isoDate = combineDateAndTime(selectedTime);
        const logData = { ...data, date: isoDate };
        console.log("data: ", data)
        addVisitEntry({data: logData, clientId, logId, logType, isUser });

        const logVisit = {
            fields: data,
            type: log.type,
            time: isoDate,
        }
        addLogSet(logVisit)
        handleEntryClose();
      };
    
    

    return (
        <div>
            <Button startIcon={<AddIcon />} onClick={handleEntryOpen}>Add Blood Pressure Entry</Button>
            <Dialog open={openEntry} onClose={handleEntryClose}>
                <DialogTitle>Add New Entry</DialogTitle>
                <DialogContent>
                <form id="addBldPrsrEntry" 
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return handleSubmit(onEntrySubmit)(e)
                }}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        {...field}
                                        label="Select Time"
                                        value={selectedTime}
                                        onChange={onTimeChange}
                                        renderInput={(params) => <TextField {...params} margin="normal" />}
                                    />
                                    </LocalizationProvider>
                                    
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Typography variant="h6" align="center">Systolic</Typography>
                            <Controller
                                name="systolic"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography variant="h4" align="center">/</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Typography variant="h6" align="center">Diastolic</Typography>
                            <Controller
                                name="diastolic"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="pulse"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Pulse"
                                        variant="outlined"
                                        margin="normal"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="note"
                                {...register('note')}
                            />
                        </Grid>
                    </Grid>
            </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEntryClose} color='warning'>Cancel</Button>
                    <Button type='submit' form="addBldPrsrEntry">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>

    )
}

AddBldPrsrFromVisit.propTypes = {
    log: PropTypes.any,
    clientId: PropTypes.any, 
    hasDate: PropTypes.any,
    isUser: PropTypes.any,
    addLogSet: PropTypes.func
}

export default AddBldPrsrFromVisit;