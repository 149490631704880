import React from 'react';
import { Typography } from '@mui/material';
import PersInfo from '../persinfoviews/PersInfo';

const StepPersInfo = () => {
  return (
    <div>
        <Typography variant="h3" sx={{ textAlign: 'center'}}>
            Personal Information
        </Typography>
        <br />
        <PersInfo />

        
    </div>
  )
}

export default StepPersInfo