import React from 'react';
import PropTypes from 'prop-types';

const ReviewMedicalInfo = ({client}) => {
    
return (
    <>
    
            
                <div className = "row">
                    <label>Primary Ins: {client?.medical?.primaryIns} ID: {client?.medical?.primaryInsId} </label><br />
                    <label>Secondary Ins: {client?.medical?.secondaryIns} ID: {client?.medical?.secondaryInsId}</label>
                        
                </div>
                <div className="row">
                    <label>Has Medi-Cal? {client?.medical?.hasMediCal}</label><br />
                    <label>ID: {client?.medical?.mediCalId} Issue Date: {client?.medical?.mediCalEnroll}</label>
                </div>
                <div className="row">
                    <label>Has Medicare? {client?.medical?.hasMedicare}</label><br />
                    <label>ID: {client?.medical?.medicareId} Issue Date: {client?.medical?.medicareEnroll}</label>
                </div>
      
    </>  
);

};

ReviewMedicalInfo.propTypes = {
    client: PropTypes.any
}

export default ReviewMedicalInfo;