import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import HealthLogMenu from './HealthLogMenu';
import PropTypes from 'prop-types';
import { useAddHealthLogTypeMutation } from '../../../redux/apiSlice';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../utilities/useAuth';
import HealthLogView from './HealthLogView';
import { toast } from 'react-toastify';

const HealthTabs = ({ list }) => {
    console.log("tabs list: ", list)
  const [healthData, setHealthData] = useState(list);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if(list) {
        setHealthData(list);
        console.log("healthData length: ", healthData.length)
        setSelectedTab(healthData.length -1);
    }
  }, [list])

  const {clientId} = useParams()
  const {id} = useAuth();
  const isUser= id

  const [addLogType] = useAddHealthLogTypeMutation()


  const handleAddNewLogType = async (newLogType) => {
    const newHealthLog = {
      type: newLogType.logType,
      fields: newLogType.fields,
      target: newLogType.target,
      upperAlert: newLogType.upperAlert,
      lowerAlert: newLogType.lowerAlert,
      goal: newLogType.goal
    };
    const data = newHealthLog

    
    try{
        await addLogType({clientId, isUser, data })
        // setHealthData([...healthData, newHealthLog]);
        
    } catch (err) {
        console.log('Failed to add log type', err)
        toast.error("Error adding log type", {
            position: toast.POSITION.TOP_CENTER
        });
    }

    toast.success("Log Type Added", {
        position: toast.POSITION.TOP_RIGHT
    });
  };

  const existingTypes = healthData?.map(x => {return x.type})

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <HealthLogMenu exists={existingTypes} onAdd={handleAddNewLogType} />
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {healthData.map((log, index) => (
            <Tab key={index} label={log.type} />
          ))}
        </Tabs>
      </Box>
      {healthData.map((log, index) => (
        <TabPanel key={index} value={selectedTab} index={index}>
            <HealthLogView log={log} clientId={clientId} isUser={isUser} />
          {/* {log.entries.map((entry, entryIndex) => (
            <Typography key={entryIndex}>
              {JSON.stringify(entry)}
            </Typography>
          ))} */}
        </TabPanel>
      ))}
    </Box>
  );
};

HealthTabs.propTypes = {
    list: PropTypes.any
}

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
    children: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any
}

export default HealthTabs;
