import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Divider, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useForm, Controller } from 'react-hook-form';
import { useAddNewLogMutation } from '../../../redux/apiSlice';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../utilities/useAuth';
import { toast } from 'react-toastify';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PhoneFormat } from '../../../utilities/PhoneFormat';

const AddPhoneEntry = () => {
    const {id} = useAuth()
    const isUser = id

    const [phone, setPhone] = useState();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        reset();
        setOpen(false);
      };

      const {clientId} = useParams();

      const [addLog] = useAddNewLogMutation()

      const {register, control, reset, watch, handleSubmit} = useForm({
        defaultValues: {
            value: '',
            changeDate: '',
            isDeleted: false
        }
      });

      const onSubmit = async (data) => {

        const logType = 'phone'

        try {
            await addLog({logType, clientId, isUser, data})
        } catch (err) {
            console.log('Failed to add log', err)
            toast.error("Error adding log", {
                position: toast.POSITION.TOP_CENTER
            });
        }

        toast.success("Log Entry Added", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();

      }

      const watchPhone = watch('value')

      useEffect(() => {
        if(watchPhone) {
            let format = PhoneFormat(watchPhone)
            setPhone(format)
        }
      }, [watchPhone])

    return (
        <div>
            <Button onClick={handleClickOpen} startIcon={<AddIcon />}>Add Phone</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <form id='addAddress'
                         onSubmit={(e) =>
                            handleSubmit(onSubmit)(e).catch((e) => {
                                console.log("e", e);
                        })}>
                            
                            <TextField
                                id='value'
                                name='value'
                                label='Phone Number'
                                type='tel'
                                value={phone}
                                {...register('value')}
                            />
                            <Divider />
                            <Controller
                                control={control}
                                name='changeDate'
                                render={({field}) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date Number Changed"
                                            value={field.value}
                                            onChange={(e) => field.onChange(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </form>
                </DialogContent>
                <DialogActions>
                    <Button color='warning' onClick={handleClose}>Cancel</Button>
                    <Button form='addAddress' type='submit'>Submit</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default AddPhoneEntry;