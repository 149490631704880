import React from 'react';
import { Typography } from '@mui/material';
import MedicalInfo from '../persinfoviews/MedicalInfo';
import AllergiesList from '../persinfoviews/AllergiesList';
import MedsList from '../persinfoviews/MedsList';

const StepMedical = () => {
  return (
    <div>
        <Typography variant="h3" sx={{ textAlign: 'center'}}>
            Medical Information
        </Typography>
        <br />
        <MedicalInfo />
        <br />
        <AllergiesList />
        <br />
        <MedsList />
        
    </div>
  )
}

export default StepMedical