import React, { useState, useRef, useEffect } from 'react';
import {
  CircularProgress,
  Box,
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Collapse,
  Typography,
  Stack,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DirectEditVisit from "../visitviews/DirectEditVisit";
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import ErrorIcon from '@mui/icons-material/Error';

function Row({data, key, expanded, setHasExpanded}) {
  console.log("exp in row: ", expanded)

  const visitId = data.id;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (expanded) {
      setOpen(true)
    }

    if(!expanded) {
      setOpen(false)
    }
  }, [expanded])

  useEffect(() => {
    if (expanded && open) {
      setHasExpanded(true)
    }
  }, [expanded, open])

  const formatDate = (visitStart) => {
    const date = new Date(visitStart);
    let options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <React.Fragment key={key}>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} style={{ backgroundColor: data.incomplete === 'Incomplete' ? '#F55C5A' : 'inherit' }}
            >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {data?.user?.fullName} 
        </TableCell>
        <TableCell align="left">{formatDate(data?.visitStart)}</TableCell>
        <TableCell align="left" display='inline'>
            <span style={{alignItems:'center'}}>
          <DirectEditVisit visitId={visitId} visit={data} />
          </span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Progress Notes
              </Typography>
              <Table size="small" aria-label="notes">
                <TableHead>
                  <TableRow>
                    <TableCell>Goal</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.goals?.map((goal) => (
                    <TableRow key={goal._id}>
                      <TableCell component="th" scope="row">
                        {goal.title}
                      </TableCell>
                      <TableCell>{goal.note}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes ={
  data: PropTypes.any,
  key: PropTypes.any,
  expanded: PropTypes.any,
  setHasExpanded: PropTypes.any
}

const ProgNotesTable = ({data, isLoading, isSuccess, printInfo}) => {
  const hasIncomplete = data?.reduce((acc, cur) => cur.incomplete === 'Incomplete' ? ++acc : acc, 0).toString();


  const [expanded, setExpanded] = useState(false)
  const [hasDates, setHasDates] = useState()
  const [hasExpanded, setHasExpanded] = useState(false)

  useEffect(() => {
    if(printInfo.start && !printInfo.end) {
      setHasDates(`from ${new Date(printInfo.start).toLocaleDateString()}`)
    } else if (!printInfo.start && printInfo.end) {
      setHasDates(`to ${new Date(printInfo.end).toLocaleDateString()}`)
    } else if (printInfo.start && printInfo.end) {
      setHasDates(`between ${new Date(printInfo.start).toLocaleDateString()} and ${new Date(printInfo.end).toLocaleDateString()}`)
    } else {
      setHasDates('')
    }
  }, [printInfo])

  const client = printInfo.name

  const printRef = useRef();
  const onGetContentResolve = useRef();

  const handleGetContent = () => {
    return new Promise((resolve) => { // `react-to-print` will wait for this Promise to resolve before continuing
      setExpanded(true)
      onGetContentResolve.current = resolve;
      // setDataLoaded(true); // When data is done loading
    });
  };

  useEffect(() => {
    if (hasExpanded) {
      onGetContentResolve.current();
    }
  }, [hasExpanded, onGetContentResolve])



  const handlePrint = useReactToPrint({
    documentTitle: `Progress Notes for ${client} ${hasDates}`,
    content: () => printRef.current,
    onBeforeGetContent: handleGetContent,
    onAfterPrint: () => setExpanded(false)
  })
  
  // const printButton = <><span style={{float: 'right'}}><ReactToPrint trigger={() => }  /></span></>

  let content;

  if (isLoading) {
    content = <CircularProgress />;
  } else if (isSuccess) {
    content = (
      <div id='print' ref={printRef}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: 2 }}
          >
        <Stack direction="row" spacing={2}>
          {hasIncomplete &&  <ErrorIcon sx={{ color: '#F55C5A' }} />}
          <Typography>{hasIncomplete} Incomplete Visits</Typography>
        </Stack>
        <Button variant='contained' color='primary' sx={{float: 'right'}} onClick={handlePrint}>Print</Button>
        </Stack>
        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Progress Notes">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Staff</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Edit/Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.map((i) => ( 
                  <Row key={i._id} data={i} expanded={expanded} setHasExpanded={setHasExpanded} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <div>{content}</div>
    </div>
  );
};

ProgNotesTable.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.any,
  isSuccess: PropTypes.any,
  printInfo: PropTypes.any
}

export default ProgNotesTable;
