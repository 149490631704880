import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { useGetUserName } from '../../utilities/useGetUserName';
import { useAuth } from '../../utilities/useAuth';
// import { useFetchUserName } from '../../utilities/useFetchUserName';
import SuspendUser from './SuspendUser';
import Reinstate from './Reinstate';
import PropTypes from 'prop-types';

const ManageStatus = ({info, userId}) => {

    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState()
    const [isActive, setIsActive] = useState(true)
    const [current, setCurrent] = useState()

    const { id } = useAuth()
    const adminName = useGetUserName(id)

    const active = info?.status?.active

    const suspendHistory = info?.status?.suspension
    // console.log("susHist: ", suspendHistory)

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
       setOpen(false);
    };

    
    useEffect(() => {
        if (active === true) {
            setStatus("Active")
            setIsActive(true)
        } else if (info) {
            setStatus("Inactive")
            let array = info?.status?.suspension
            // console.log("array: ", array)
            let latest = array[array.length -1]
            // console.log("latest: ", latest)
            setCurrent(latest)
            setIsActive(false)
        }
    }, [active, info])

  return (
    <div>
        {isActive ? (
            <Button color='success' variant='contained' onClick={handleClickOpen}><CheckBox />Active</Button>
        ) : (
            <Button color='warning' variant='contained' onClick={handleClickOpen}><CheckBoxOutlineBlank />Inactive</Button>
        )}
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Manage User Status</DialogTitle>
            <DialogContent>
                <Typography>Status: {status}</Typography>
                <Typography>Start Date: {new Date(info?.status.startDate).toLocaleDateString()}</Typography><br />
                <Typography>Suspension History:</Typography>
                {suspendHistory && suspendHistory.map((i, idx) => {
                    return(
                        <React.Fragment key={idx}>
                            <ol>
                                <li>Suspended on: {new Date(i.susOn).toLocaleDateString()}</li>
                                <li>Suspended By: {i.susBy} </li>
                                <li>For: {i.note}</li>
                                {i.reinBy ? (
                                    <>
                                        <li>Reinstated on: {new Date(i.reinOn).toLocaleDateString()}</li>
                                        <li>Reinstated by: {i.reinBy}</li>
                                    </>
                                ): (
                                    <></>
                                )} <br />
                            </ol>
                        </React.Fragment>
                    )
                })}
                <br />
                {isActive ? (
                    <SuspendUser info={info} userId={userId} adminName={adminName} />
                ): (
                    <Reinstate info={info} current={current} userId={userId} adminName={adminName} />
                )}
            </DialogContent>
        </Dialog>

    </div>
  )
}

ManageStatus.propTypes = {
    info: PropTypes.any,
    userId: PropTypes.any
}

export default ManageStatus