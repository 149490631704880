import React, { useState, useEffect } from 'react';
import './assets/App.css';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from './views/loginviews/Login';
import RegisterUser from './views/loginviews/RegisterUser';
import ForgotPassword from './views/loginviews/ForgotPassword';
import ResetPassword from './views/loginviews/ResetPassword';
import MiniDrawer from './MiniDrawer.js';
import Clients from './views/Clients';
import Dashboard from './views/Dashboard';
// import Schedule from './views/scheduleviews/Schedule';
import ScheduleTabs from './views/scheduleviews/ScheduleTabs';
import TimeTabs from './views/timecardviews/TimeTabs';
import Messages from './views/Messages';
import Reports from './views/reportviews/Reports';
import UsersTab from './views/UsersTab';
import Teams from './views/Teams';
import Settings from './views/Settings';
import AuditLog from './views/auditlogviews/Auditlog';
import ClientChart from './views/clientviews/chartviews/ClientChart';
import UserProfile from './views/userviews/UserProfile';
import SupervisorUsers from './views/userviews/SupervisorUsers';
import { Route, Routes, Navigate } from 'react-router-dom'; 
import PersistLogin from './views/loginviews/PersistLogin';
import { useAuth } from './utilities/useAuth';
import { useDispatch } from 'react-redux';
import { setClockInStatus } from './redux/slices/userSlice';
import { setAgencyDetails } from './redux/slices/settingsSlice';
import { useGetUserClockInStatusQuery, useGetAgencySettingsQuery } from './redux/apiSlice';
import { LicenseInfo } from '@mui/x-license-pro';
import { ThemeProvider, CssBaseline } from '@mui/material';
import appTheme from './MuiTheme';
import PropTypes from 'prop-types';
// import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// import { useSelector } from 'react-redux';

LicenseInfo.setLicenseKey('b1248e75430be5a21deece6b8700acd2Tz04NzkzMCxFPTE3NDM5NzI3OTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const PrivateRoute = ({ hasAuth, children }) => {
  // console.log("hasAuth: ", hasAuth)
  return hasAuth ? children : <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  hasAuth: PropTypes.any,
  children: PropTypes.any
};

// let appTheme = (width) => createTheme({
//   palette: {
//     mode: 'light',
//     primary: {
//       main: '#086EE8',
//     },
//     secondary: {
//       main: '#4ab379',
//     },
//     tertiary: {
//       main: '#7E57C2'
//     },
//     error: {
//       main: '#e82525',
//     },
//     info: {
//       main: '#83c9ef',
//     },
//     white: {
//       main: '#FFFAFA'
//     }
//   },
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: {
//         a: {
//           color: 'blue',
//           textDecoration: 'none',
//           '&:visited': {
//             color: 'blue',
//           },
//         },
//       },
//     },

//     MuiGrid: {
//       styleOverrides: {
//         container: {
//           width: `${width}px`
//         }
//       }
//     },
   
//     MuiButton: {
//         defaultProps: {
//             variant: 'contained',
//             color: 'primary'
//         }
//     },
// }
// });

// appTheme = responsiveFontSizes(appTheme);


function App() {

  const dispatch = useDispatch();  
  const [hasAuth, setHasAuth] = useState(false)
  const [today, setToday] = useState()
  const [skip, setSkip] = useState(true)
  // console.log("hasAuth on app?: ", hasAuth)


  const { id } = useAuth()
  const userId = id
  // const isAuth = id

  useEffect(() => {
    if (id?.length) {
      setHasAuth(true)
    }
  }, [id])
  

  const {data: settings, isSuccess: settingsSuccess} = useGetAgencySettingsQuery({skip});
	// console.log("settings loaded, data?: ", settings)
  if(settingsSuccess){
    dispatch(setAgencyDetails(settings))
  }
  
  
  useEffect(() => {
    if(hasAuth){
    setToday(new Date().toISOString())
    setSkip(false)
    }
  }, [hasAuth])
  // console.log("today: ", today)

  const { data, isSuccess} = useGetUserClockInStatusQuery({userId, today}, {skip})
  // console.log("clock in success: ", isSuccess)
  // console.log(data)
     useEffect(() => {
      if (data?.message?.length) {
        // console.log(data.message)
      } else if (data && isSuccess) {
        // console.log("hit")
        // console.log("data2: ", data)
          dispatch(setClockInStatus(data))
          if (data.response === 'no bracket' || data.response === 'empty bracket' || data.response === 'entries full'){
            // toast.success('Clocked Out', {
            //   position: toast.POSITION.TOP_RIGHT
            // })
          } else if (data.response === 'clocked in')
          toast.success('clocked in', {
            position: toast.POSITION.TOP_RIGHT
          });
      }
    
  }, [isSuccess, data]);


  const [width, setWidth] = useState(window.innerWidth);
  const [theme, setTheme] = useState(appTheme(width));
  // const width = useSelector(state => state.user.screenWidth)

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      // dispatch(setScreenWidth(newWidth))
      setWidth(newWidth);
      setTheme(appTheme(newWidth));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
  {/* <BrowserRouter> */}
  <ToastContainer 
    position='top-right'
    autoClose={5000}
    closeOnClick
    draggable
    />
    
  <div className="App">
  <Routes>
    <Route path="/login" element={<Login />} />  
    <Route exact path="/register" element={<RegisterUser />} />
    <Route exact path="/forgotpassword" element={<ForgotPassword />} />
    <Route exact path="/resetpassword/:resetToken" element={<ResetPassword />} />
  </Routes>
  </div> 
    
  {/* style={{ marginTop: '100px', marginLeft: '100px' }} */}
  {/* //style={{disply: 'flex'}} */}
    <MiniDrawer />
        <main style={{ marginTop: '100px', marginLeft: '100px' }}>  

        <Routes>
        <Route element={<PersistLogin />}>
            <Route index element={<Dashboard />} /> 
              
            <Route path='/dashboard' index element={<PrivateRoute hasAuth={hasAuth}><Dashboard /></PrivateRoute>} /> 
            <Route exact path="/clients" element={<PrivateRoute hasAuth={hasAuth}><Clients /></PrivateRoute>} />
            <Route exact path="/ClientChart/:clientId" element={<PrivateRoute hasAuth={hasAuth}><ClientChart /></PrivateRoute>}/>
            
            <Route exact path="/Schedule" element={<PrivateRoute hasAuth={hasAuth}><ScheduleTabs /></PrivateRoute>} />
            
            <Route exact path="/timecard" element={<PrivateRoute hasAuth={hasAuth}><TimeTabs /></PrivateRoute>} />
            <Route exact path="/messages" element= {<PrivateRoute hasAuth={hasAuth}><Messages /></PrivateRoute>} />
            <Route exact path="/reports" element={<PrivateRoute hasAuth={hasAuth}><Reports /></PrivateRoute>} />
            <Route exact path="/UsersTab" element={<PrivateRoute hasAuth={hasAuth}><UsersTab /></PrivateRoute>} />
            <Route exact path="/UserProfile/:userId" element={<PrivateRoute hasAuth={hasAuth}><UserProfile /></PrivateRoute>} />
            <Route exact path="/SupervisorUsers" element={<PrivateRoute hasAuth={hasAuth}><SupervisorUsers /></PrivateRoute>} />
            <Route exact path="/teams" element={<PrivateRoute hasAuth={hasAuth}><Teams /></PrivateRoute>} />
            <Route exact path="/settings" element={<PrivateRoute hasAuth={hasAuth}><Settings data={settings} /></PrivateRoute>} />
            <Route exact path="/auditlog" element={<PrivateRoute hasAuth={hasAuth}><AuditLog /></PrivateRoute>} />
        </Route>
      </Routes>
        </main>
    
  <ToastContainer />
  {/* </BrowserRouter> */}
  </ThemeProvider>
  );
}

export default App;
