import React, {useState, useEffect} from 'react';
import {Button, Select, MenuItem, LinearProgress, Table, TableHead, TableBody, TableRow, TableContainer, TableCell, Paper, FormControl, InputLabel, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetVisitsOfClientByDateQuery } from '../../../redux/apiSlice';
import { getDaysInMonth, parseISO } from  'date-fns';
import PropTypes from 'prop-types';


const monthOptions = [
    { label: 'January', value: '0' },
    { label: 'February', value: 1 },
    { label: 'March', value: 2 },
    { label: 'April', value: 3 },
    { label: 'May', value: 4 },
    { label: 'June', value: 5 },
    { label: 'July', value: 6 },
    { label: 'August', value: 7 },
    { label: 'September', value: 8 },
    { label: 'October', value: 9 },
    { label: 'November', value: 10 },
    { label: 'December', value: 11 }
  ]

const ClientAttendance = ({ client }) => {

    const {clientId} = useParams();

    const [yearList, setYearList] = useState([])
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [skip, setSkip] = useState(true)
    const [isStart, setIsStart] = useState()
    const [isEnd, setIsEnd] = useState()
    const [days, setDays] = useState()

    useEffect(() => {
      // Generate an array of years going back to 2017 cause this is the earliest Data in the system
      const currentYear = new Date().getFullYear();

      const yearsArray = [];
      for (let year = currentYear; year >= 2017; year--) {
        yearsArray.push({ label: year.toString(), value: year });
      }

      setYearList(yearsArray);
    }, [])

    const setDates = () => {
        if (month != '' && year != ''){
    
        
            const start = new Date(new Date(new Date(year, month).setDate(1)).setHours(0, 0, 0)).toISOString();
            setIsStart(start);
            // console.log("isStart ", start)
    
            const daysInMonth = getDaysInMonth(parseISO(start))
    
            const end = new Date(new Date(new Date(year, month).setDate(daysInMonth)).setHours(23, 59, 59)).toISOString();
            setIsEnd(end);
            // console.log("isEnd ", end)

            
            let day = []
            for (let i = 1; i <= daysInMonth; i++) {
                day.push(i);
                let result = day.map((i) => (
                    {
                        day: i,
                        hours: 0
                    }
                ));
                setDays(result);
                // console.log("days ", result)
            }
        } else {
            toast.error("Please select a month and year", {
            position: toast.POSITION.TOP_CENTER
            });
        }
        
    }

    const { data: visits, isLoading, isSuccess} = useGetVisitsOfClientByDateQuery({clientId, isStart, isEnd}, {skip});

    useEffect(() => {
    if (isSuccess)  {
        
        // console.log("visits ", visits)
        const oldData = visits.map((visit) => (
            {
                id: visit.id,
                start: new Date(visit.visitStart).getDate(),
                hours: visit.totalHours
            }
        ));

        let collData = oldData.reduce((accumulator, currentValue) => {
            let existing = accumulator.find((n) => n.start === currentValue.start);
            if (existing) {
              existing.hours += currentValue.hours;
            } else {
              accumulator.push(currentValue);
            }
            return accumulator;
          }, []);
        // console.log("newData ", oldData);
        
        
        let temp = [...days];
        days.map((i, idx) => {
            const getHours = collData.filter((item) => item.start === i.day);
            if (getHours.length) {
                const newData = {
                    day: i.day,
                    hours: getHours[0].hours
                };
                temp[idx] = newData;
                setDays(temp)
                // console.log("updateDays ", temp)
            }
        });
        
        
    }
    }, [isSuccess]);
   

  const generateMonthOptions = () => {
    return monthOptions.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  const generateYearOptions = () => {
    return yearList.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  let attendanceReport

  if (!skip) {
        
    if (isLoading) {
        attendanceReport = <LinearProgress />
    } else if (isSuccess) {
        attendanceReport = 
            <div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{new Date(isStart).toLocaleDateString()}</TableCell>
                                {days && days.map((day) => 
                                    <TableCell key={day.day}>{day.day}</TableCell>
                                )}
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                            <TableCell>{client.fullName}</TableCell>
                            {days && days.map((d) => <TableCell align="center" key={d.day}>{d.hours}</TableCell>)}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
    }
    
    
  }


const handleMonthChange = (event) => {
    setMonth(event.target.value);
}

const handleYearChange = (event) => {
    setYear(event.target.value);
}


  return (
    <>
    <div display='flex'>
      <Typography variant='h6'>Provides a table with hours served by day for the month/ year selected</Typography>
      <FormControl sx={{ m: 1, p: 1, minWidth: 120 }}>
        <InputLabel id="month-select">Month</InputLabel>
          <Select
              labelId='month-select'
              label="Month"
              name='month'
              value={month}
              onChange={handleMonthChange}
              autoWidth
          >
            {generateMonthOptions()}
          </Select>
      </FormControl>
      <FormControl sx={{ m: 1, p: 1, minWidth: 120 }}>
        <InputLabel id="year-select">Year</InputLabel>    
          <Select
              labelId='year-select'
              label="Year"
              name='year'
              value={year}
              onChange={handleYearChange}
              autoWidth
          >
            {generateYearOptions()}
          </Select>
      </FormControl>
        <Button color='primary' onClick={() => {setDates(), setSkip(false)} }>Run</Button>
        
    </div>
    {attendanceReport}
    </>
  )

}

ClientAttendance.propTypes = {
    client: PropTypes.any
}

export default ClientAttendance;