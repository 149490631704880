import React, { useState } from 'react';
import { Button, TextField, Paper, Box } from '@mui/material';
import { useForgotPasswordMutation } from '../../redux/apiSlice';
import { Link } from 'react-router-dom';


const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const [sendLink] = useForgotPasswordMutation()


    const forgotPasswordHandler = async (e) => {
        e.preventDefault();

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        if(regex.test(email)){
            console.log("email: ", email)
            setSuccess("If your email is in our system, a reset link has been sent.")
            sendLink({email})
        } else {
            setError("Make sure your email follows the standard pattern: email@example.com")
        }

        // const config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // };

        // try {
        //     const { data } = await axios.post(
        //         "/api/auth/forgotpassword",
        //         { email },
        //         config
        //     );
            
        //     setSuccess(data.data);
        // } catch (error) {
        //     setError(error.response.data.error);
        //     setEmail("");
        //     setTimeout(() => {
        //         setError("");
        //     }, 5000 );
        // }
    };

    return (
        <>
        <Box
            sx={{
                mx: 'auto',
                width: 500,
                pt: 10,
                mt: 10,
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
                
            }}
        >
        <Paper elevation={24}>
        <div>
            <form onSubmit={forgotPasswordHandler}>
                <h3>ForgotPassword</h3>
                {error && <span>{error}</span>}
                {success && <span>{success}<Link to="/login">Return to Login</Link></span>}
                <div>
                    <p>
                        Please enter the email address registered to your account.
                        We will send you a reset password link to this email.
                    </p>
                    <label htmlFor="email">Email:</label>
                    <TextField
                        type="email"
                        requiredid="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <Button type="submit" className="btn btn-primary">Request Password Reset</Button>
            </form>
        </div>
        </Paper>
        </Box>
        </>
    );
};

export default ForgotPassword;