import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material'
import { useGetClientsByUserIdQuery } from '../../redux/apiSlice'
import { useAuth } from '../../utilities/useAuth';



const MyClients = () => {
  const { id } = useAuth()
	const userId = id

const {
  data,
  isLoading,
  isSuccess,
  
} = useGetClientsByUserIdQuery(userId)

const columns = 

   [
  {
    field: '_id', 
    headerName: 'Client Chart', 
    width: 80,
    renderCell: (params) => (
      <Link to={`/ClientChart/${params.value}`}><AccountCircleIcon /></Link>
    )
  },
  {field: 'name', headerName: 'Name', width: 150},
  {field: 'phone', headerName: 'Phone', width: 150},
  {field: 'address.addressLineOne', headerName: 'Address', width: 150},
  {field: 'address.addressLineTwo', headerName: '', width: 150},
  {field: 'sc', headerName: 'Service Coordinator', width: 150},
  {field: 'scPhone', headerName: 'SC Phone', width: 150},
  
   ];


let content

if (isLoading) {
  content = <CircularProgress />
} else if (isSuccess) {
  content = 
  <div style={{ display: 'flex' }}>
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
  { data &&
    <DataGridPro 
      autoHeight
      autoWidth
      getRowId={(row) => row._id}
      rows={data}
      columns={columns}
      //autoPageSize
      sx={{ height: '100%', width: '100%' }}
      rowsPerPageOptions={[20, 50, 100]}
      slots={{
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            No Clients Assigned
          </Stack>
        )
      }}  
  
 />}
 </Box>
  </div>

}


  return (
    <div>
      <h1>My Clients</h1>
      
      <div>
        {content}
      </div>
      
      
    </div>
  )
}

export default MyClients;