import { Card, CardContent, CardHeader } from '@mui/material';
import React, { useContext } from 'react';
import AssignUser from '../../userviews/AssignUser';
import { ClientContext } from '../ClientContext';
import ClientUsers from './ClientUsers';
import EditPersInfo from './EditPersInfo';
import Income from './Income';

const PersInfo = () => {

  const { client } = useContext(ClientContext);
  const clientId = client._id
    
    
return (
    <>
     <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
        <CardHeader
            title="Client Details"
            action={<EditPersInfo client={client}/>}
        />
            <CardContent>
            <div className = "card-body">
                <div className = "row">
                    <label>Birth Date: {new Date(client?.personalInfo?.dateOfBirth).toLocaleDateString()} </label>
                </div>
                
                <div className = "row">
                    <label> Phone: { client?.personalInfo?.phone}  Email: {client?.personalInfo?.email}</label>
                        
                </div>
                <div className="row">
                    <label>Address: {client?.personalInfo?.address?.lineOne}</label>
                </div>
                <label>{client?.personalInfo?.address?.lineTwo}</label> <br />
                <label>{client?.personalInfo?.address?.city}, {client?.personalInfo?.address?.state} {client?.personalInfo?.address?.zip} </label>
                <div className='row'>
                    <label>Living Situation: {client?.personalInfo?.livingSituation}</label>
                    <br />
                    <label>Emergency Contact: {client?.personalInfo?.emergencyContact}</label>
                </div>
                <div className='row'>
                    <label>Regional Center Info:</label><br />
                    <label>{client?.personalInfo?.regionalCenter} UCI#: {client?.personalInfo?.rcId}</label><br />
                    <label>Service Coordinator: {client?.personalInfo?.serviceCoordinator} Phone: {client?.personalInfo?.serviceCoordinatorPhone} Email: {client?.personalInfo?.serviceCoordinatorEmail}</label>
                </div>
            </div>
        </CardContent>
    </Card>
    <div>
    <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
        <Income /><br />
    </Card>
    <Card sx={{m:1, p: 1, display: 'flex', flexDirection: 'column' }}>
        <AssignUser clientId={clientId} /><br />
        <ClientUsers clientId={clientId} />
    </Card>
        
        
    </div>
    
    </>  
);

};

export default PersInfo;