import React, { useState } from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography} from "@mui/material";
import { useReinstateUserMutation } from '../../redux/apiSlice';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// import { useGetUserName } from '../../utilities/useGetUserName';
import PropTypes from 'prop-types';

const Reinstate = ({info, userId, current, adminName}) => {
    
    const [open, setOpen] = useState(false);
    // console.log("info: ", info)
    // console.log("rein current: ", current, current._id)

    const suspendId = current?._id
    // console.log("sus ID: ", suspendId)

    const [reinstate] = useReinstateUserMutation()

    // console.log("rein: ", adminName)

    const today = new Date().toISOString()

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const { handleSubmit} = useForm({
        defaultValues: {
            active: true,
            suspension: {
                reinOn: today,
                reinBy: adminName,
                // note: ''
            }
        }
      });
    
     const onSubmit = async (data) => {
        // console.log("onsubmit: ", data)
        try{
            // const payload = 
            await reinstate({userId, suspendId, data})
            // .unwrap();
            // console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to reinstate user: ', err)
            toast.error("Error Reinstating User", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    
        toast.success("User Reinstated", {
            position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
     }



  return (
    <div>
       <Button color='success' variant='contained' onClick={handleClickOpen}>Reinstate</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Reinstate User</DialogTitle>
            <DialogContent>
                <form
                    id="reinstate"
                    onSubmit={(e) => 
                        handleSubmit(onSubmit)(e).catch((e) => {
                            console.log("error: ", e)
                        })}
                >
                    <Typography>{info.fullName}</Typography><br />
                    <Typography>Click Submit to Reinstate User</Typography>
                    
                </form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' type='submit' form='reinstate'>Submit</Button>
                <Button color='warning' variant='contained' onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

Reinstate.propTypes = {
    info: PropTypes.any,
    current: PropTypes.any,
    userId: PropTypes.any,
    adminName: PropTypes.any
}

export default Reinstate