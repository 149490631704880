import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useDeleteTimecardMutation } from '../../redux/apiSlice';
import { Typography, Box } from '@mui/material';
import DeleteAlert from '../../DeleteAlert';
import ViewTimecard from './ViewTimecard';
import PropTypes from 'prop-types';

const SingleUserTimeHistory = ({data}) => {
    

    const [deleteTimecard] = useDeleteTimecardMutation()

    const columns = 
   [
  {
    field: 'signs',
    headerName: 'Submission Date',
    type: 'date',
    width: 150,
    valueFormatter: (params) => {
      const date = new Date(params.value.submitDate);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    }
    
  },
  {
    field: 'dateStart', 
    headerName: 'End Date ', 
    type: 'date', 
    width: 150,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    }
  },
  {
    field: 'dateEnd', 
    headerName: 'End Date ', 
    type: 'date', 
    width: 150,
    valueFormatter: (params) => {
      const date = new Date(params.value);
      let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      return date.toLocaleString("en-US", options);
    }
  },  
  {field: 'visitTotal', headerName: 'Visit Hours', width: 100},
  {field: 'entryTotal', headerName: 'Entry Hours', width: 100},
  {field: 'mileTotal', headerName: 'Miles', width: 100},
  {
    field: "actions",
    headerName: "",
    width: 120,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const timecardId = params.id
      const user = params.row.submitUser.fullName
      let beenApproved
      
      if(params.row.approveUser){
        beenApproved = true
      } else {
        beenApproved = false
      }

        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <ViewTimecard file={params.row.file} timecardId={timecardId} beenApproved={beenApproved} />
            <DeleteAlert name={`Timecard for ${user}`} id={timecardId} hook={deleteTimecard} softDelete={false} />
          </Box>
        );
    }
  }
   ]

   
       
  
   

  return (
    <div>
         <Typography>Your Timecard History</Typography>
        <DataGrid 
            autoHeight
            autoWidth
            getRowId={(row) => row._id}
            rows={data}
            columns={columns}
            sx={{ height: '100%', width: '100%' }}
            rowsPerPageOptions={[10, 20, 30]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
        />
    </div>
  )
}

SingleUserTimeHistory.propTypes = {
  data: PropTypes.any
}

export default SingleUserTimeHistory