export const PhoneFormat = (value) => {
    // console.log('value: ', value)
if (!value) return value;
const phoneNumber = value.replace(/[^\d]/g, '');
const numberLength = phoneNumber.length;
// console.log("length: ", numberLength)
if (numberLength < 4){ console.log("no adjust"); return phoneNumber;}
if (numberLength < 7) {
    let adjust = `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`;
    // console.log("adjust2: ", adjust)
    return adjust
} else {
let adjust = `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
// console.log("adjust3: ", adjust)
return adjust
}}