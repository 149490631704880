import React from 'react';
import PropTypes from 'prop-types';

const ReviewContacts = ({contact}) => {
  return (
    <>
    <div>
        <label>{contact?.specificType}</label>
        <label>{contact?.firstName} {contact?.lastName}</label><br />
        <label>Phone: {contact?.phone} Email: {contact?.email}</label><br />
        <label>Address:</label><br />
        <label>{contact?.address?.addressLineOne}</label><br />
        {contact?.address?.addressLineTwo ? <><label>{contact.address.addressLineTwo}</label><br /></> : <></>}
        <span>
            <label>{contact?.address?.city}, {contact?.address?.state} {contact?.address?.zip}</label>
        </span>
    </div>
    </>
  )
}

ReviewContacts.propTypes = {
    contact: PropTypes.any
}

export default ReviewContacts